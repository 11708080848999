import { all } from "redux-saga/effects";
import { watchGetSettings } from "./settings";
import { watchGetCountry } from "./country";
import { watchGetProductList } from "./productlist";
import { watchGetProductDetails } from "./productdetails";
import { watchGetSegmentList } from "./segmentlist";
import { watchGetTagList } from "./taglist";
import { watchGetFbLoginData } from "./fblogin";
import { watchGetGoogleLoginData } from "./googlelogin";
import { watchGetCustomerDetail } from "./customerdetail";
import { watchGetForgetPassword } from "./forgetpassword";
import { watchGetChangePassword } from "./changepassword";
import { watchGetUpdateCustomerProfile } from "./updatecustomerprofile";
import { watchGetCustomerLoginData } from "./customerlogin";
import { watchGetCustomerRegistration } from "./customerregistration";
import { watchGetFavourite } from "./favourite";
import { watchGetFavouritelist } from "./favouritelist";
import { watchGetBookingHistory } from "./bookinghistory";
import { watchGetFunThings } from "./funthings";
import { watchGetSearchFlight } from "./searchflight";
import { watchGetSearchFlightReturn } from "./searchflightReturn";
import { watchGetMystiflyRevalidate } from "./mystiflyrevalidate";
import { watchGetMystiflyCheapestRevalidate } from "./mystiflycheapestrevalidate";
import { watchGetMystiflyRevalidateReturn } from "./mystiflyrevalidatereturn";
import { watchGetMystiflyCheapestRevalidateReturn } from "./mystiflycheapestrevalidatereturn";
import { watchGetWishList } from "./wishlist";
import { watchGetGetUploadFiles } from "./uploadfiles";

export default function* () {
  yield all([
    watchGetSettings(),
    watchGetCountry(),
    watchGetProductList(),
    watchGetProductDetails(),
    watchGetSegmentList(),
    watchGetTagList(),
    watchGetFbLoginData(),
    watchGetGoogleLoginData(),
    watchGetCustomerDetail(),
    watchGetForgetPassword(),
    watchGetChangePassword(),
    watchGetUpdateCustomerProfile(),
    watchGetCustomerLoginData(),
    watchGetCustomerRegistration(),
    watchGetFavourite(),
    watchGetFavouritelist(),
    watchGetBookingHistory(),
    watchGetFunThings(),
    watchGetSearchFlight(),
    watchGetSearchFlightReturn(),
    watchGetMystiflyRevalidate(),
    watchGetMystiflyCheapestRevalidate(),
    watchGetMystiflyRevalidateReturn(),
    watchGetMystiflyCheapestRevalidateReturn(),
    watchGetWishList(),
    watchGetGetUploadFiles(),
  ]);
}
