import React, { Component } from "react";
class Refpage extends Component {
  constructor(props) {
    super(props);

    let slugtext =
      typeof this.props.match.params.slugtext !== "undefined"
        ? this.props.match.params.slugtext
        : "";
        console.log(slugtext,"slugtext")
    if (slugtext === "home") {
      this.props.history.push("/");
    } else if (slugtext === "booking") {
      console.log(this.props,"props")
      this.props.history.push({
        pathname: "/booking",
        state: this.props.location.state // Pass savedData as an object
      });
    }else {
      this.props.history.push("/");
    }
  }

  render() {
    return <div></div>;
  }
}

export default Refpage;
