import { combineReducers } from "redux";
import settings from "./settings";
import country from "./country";
import productlist from "./productlist";
import productdetails from "./productdetails";
import segmentlist from "./segmentlist";
import taglist from "./taglist";
import fblogin from "./fblogin";
import googlelogin from "./googlelogin";
import customerdetail from "./customerdetail";
import forgetpassword from "./forgetpassword";
import changepassword from "./changepassword";
import updatecustomerprofile from "./updatecustomerprofile";
import customerlogin from "./customerlogin";
import customerregistration from "./customerregistration";
import favourite from "./favourite";
import favouritelist from "./favouritelist";
import bookinghistory from "./bookinghistory";
import funthings from "./funthings";
import searchflight from "./searchflight";
import searchflightReturn from "./searchflightReturn";
import mystiflyrevalidate from "./mystiflyrevalidate";
import mystiflycheapestrevalidate from "./mystiflycheapestrevalidate";
import mystiflyrevalidateReturn from "./mystiflyrevalidatereturn";
import mystiflycheapestrevalidateReturn from "./mystiflycheapestrevalidatereturn";
import wishlist from "./wishlist";
import uploadfiles from "./uploadfiles";

const rootReducer = combineReducers({
  settings: settings,
  country: country,
  productlist: productlist,
  productdetails: productdetails,
  segmentlist: segmentlist,
  taglist: taglist,
  fblogin: fblogin,
  googlelogin: googlelogin,
  customerdetail: customerdetail,
  forgetpassword: forgetpassword,
  changepassword: changepassword,
  updatecustomerprofile: updatecustomerprofile,
  customerlogin: customerlogin,
  customerregistration: customerregistration,
  favourite: favourite,
  favouritelist: favouritelist,
  bookinghistory: bookinghistory,
  funthings: funthings,
  searchflight: searchflight,
  searchflightReturn: searchflightReturn,
  mystiflyrevalidate: mystiflyrevalidate,
  mystiflycheapestrevalidate: mystiflycheapestrevalidate,
  mystiflyrevalidateReturn: mystiflyrevalidateReturn,
  mystiflycheapestrevalidateReturn: mystiflycheapestrevalidateReturn,
  wishlist: wishlist,
  uploadfiles: uploadfiles,
});

export default rootReducer;
