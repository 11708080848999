/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_PRODUCTDETAILS, SET_PRODUCTDETAILS } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
export const watchGetProductDetails = function* () {
  yield takeEvery(GET_PRODUCTDETAILS, workerGetProductDetails);
};

function* workerGetProductDetails(datas) {
  try {
    var addParams = "";
    if (
      datas.params !== "" &&
      typeof datas.params !== undefined &&
      typeof datas.params !== "undefined"
    ) {
      if (
        datas.params.product_slug !== "" &&
        typeof datas.params.product_slug !== undefined &&
        typeof datas.params.product_slug !== "undefined"
      ) {
        addParams += "product_slug=" + datas.params.product_slug;
      }
      if (
        datas.params.userID !== "" &&
        typeof datas.params.userID !== undefined &&
        typeof datas.params.userID !== "undefined"
      ) {
        addParams += "&customer_id=" + datas.params.userID;
      }
      if (
        datas.params.booking_date !== "" &&
        typeof datas.params.booking_date !== undefined &&
        typeof datas.params.booking_date !== "undefined"
      ) {
        addParams += "&booking_date=" + datas.params.booking_date;
      }
      if (
        datas.params.latitude !== "" &&
        typeof datas.params.latitude !== undefined &&
        typeof datas.params.latitude !== "undefined"
      ) {
        addParams += "&latitude=" + datas.params.latitude;
      }
      if (
        datas.params.longitude !== "" &&
        typeof datas.params.longitude !== undefined &&
        typeof datas.params.longitude !== "undefined"
      ) {
        addParams += "&longitude=" + datas.params.longitude;
      }
    }

    const uri = apiUrl + "products/getProductDetails?" + addParams;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCTDETAILS, value: resultArr });
  } catch (e) {
    console.log(e, "Get Product Details Failed");
  }
}
