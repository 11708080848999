/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_SEGMENTLIST, SET_SEGMENTLIST } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
export const watchGetSegmentList = function* () {
  yield takeEvery(GET_SEGMENTLIST, workerGetSegmentList);
};

function* workerGetSegmentList() {
  try {
    const uri = apiUrl + "products/getSegmentList";
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_SEGMENTLIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Segment list Failed");
  }
}
