/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_FUNTHINGS, SET_FUNTHINGS } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetFunThings = function* () {
  yield takeEvery(GET_FUNTHINGS, workerGetFunThings);
};

function* workerGetFunThings({ params }) {
  try {
    const uri = apiUrl + "products/getFunThings?" + params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FUNTHINGS, value: resultArr });
  } catch {
    console.log("Get Fun Things Failed");
  }
}
