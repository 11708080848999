/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import Select from "react-select";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { GET_BOOKINGHISTORY } from "../../actions";
import { apiUrl, PDFApiUrl } from "../Helpers/Config";
import ContentLoader from "react-content-loader";
import {
  showAlert,
  showLoader,
  hideLoader,
  showPriceValue,
} from "../Helpers/SettingHelper";
import actinfo1 from "../../common/images/act-info1.jpg";
import ticketact from "../../common/images/ticket-activity.svg";
import car from "../../common/images/car-icon.svg";
import hotel from "../../common/images/hotel-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { saveAs } from "file-saver";
import ModalPopup from "../../common/components/ModalPopup/ModalPopup";
import AlertPopup from "../../common/components/AlertPopup/AlertPopup";
var qs = require("qs");
const filterOptions = [
  {
    label: "latest Bookings",
    value: "latestBookings",
  },
  {
    label: "Last Day",
    value: "lastDay",
  },
  {
    label: "Last Week",
    value: "lastWeek",
  },
  {
    label: "Last Month",
    value: "lastMonth",
  },
  {
    label: "Last 3 Months",
    value: "last3Months",
  },
  {
    label: "Last 6 Months",
    value: "last6Months",
  },
  {
    label: "Last Year",
    value: "lastYear",
  },
];
class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log(this.props);
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    var prevPage = "";
    if (
      this.props.location.state !== undefined &&
      this.props.location.state.prevPage !== undefined
    ) {
      prevPage = this.props.location.state.prevPage;
    }
    this.state = {
      UserId: UserId,
      existbookingList: [],
      bookinglist: [],
      filteredBookings: [],
      selectedFilter: "",
      displaybooking: "",
      booking_id: "",
      ticket_id: "",
      ticket_code: "",
      cancelPopupList: [],
      category: ["Things to do", "vacation", "flight"],
      categorylist: [],
      cancelList: [],
      cancelerrorMsg: false,
      cancelled_list: [],
      cancel_reason: "",
      cancel_other_reason: "",
      error_cancel_reason: "",
      error_cancel_other_reason: "",
      page: 1,
      showLoadMore: false,
      isMobile: window.innerWidth <= 480,
      openFilterSheet: false,
      total_records: "",
      loading: true,
      prevPage: prevPage,
      openAlertPopup: false,
      popupTitle: "",
      alertPopupMsg: "",
      openTicketPopup: false,
    };
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "page=" + this.state.page;
      if (UserId !== "") {
        params += "&customer_id=" + UserId + "&type=BOOKED";
      }
      this.props.getBookingHistory(params);
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.state.prevPage === "booking") {
      window.addEventListener("popstate", (event) => {
        this.props.history.push("/mybooking");
      });
    }
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.bookinglist !== this.state.existbookingList) {
      this.setState({ loading: true });
      if (this.state.bookinglist.length > 0) {
        var bookinglist = this.state.bookinglist.concat(PropsData.bookinglist);
      } else {
        var bookinglist = PropsData.bookinglist;
      }
      hideLoader("loadmore", "class");
      var showLoadMore = PropsData.bookinglist.length > 0 ? true : false;
      this.setState(
        {
          bookinglist: bookinglist,
          filterBookings: bookinglist,
          total_records: bookinglist.length,
          showLoadMore: showLoadMore,
          existbookingList: PropsData.bookinglist,
        },
        function () {
          this.displayBookingList();
          this.setState({ loading: false });
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState?.categorylist?.length !== this.state.categorylist?.length) {
      /*   this.handleFilterChange() */
    }
  }
  loadMore() {
    this.setState({ page: parseInt(this.state.page) + 1 }, function () {
      var params = "page=" + this.state.page;
      if (this.state.UserId !== "") {
        params += "&customer_id=" + this.state.UserId + "&type=BOOKED";
      }
      showLoader("loadmore", "class");
      this.props.getBookingHistory(params);
    });
  }

  downloadTicket = async (item) => {
    try {
      const res = await axios.get(
        `${PDFApiUrl}/eticket/download?bookingid=${item.booking_id}`,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
          responseType: "arraybuffer",
        }
      );

      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, `${item.packageName}.pdf`);
    } catch (err) {
      console.error(err);
    }
  };

  displayBookingList() {
    this.setState({ loading: true });
    var displaybooking = "";
    if (this.state.bookinglist.length > 0) {
      displaybooking = this.state.bookinglist.map((item, index) => {
        console.log("item", item);
        return (
          <div className="mybooking-inner-main" key={index}>
            <div className="booked-ticket">
              <div className="booked-ticket-inner">
                <div className="booked-ticket-top">
                  <div className="btt-lhs">
                    <h5>
                      {item.packageName !== ""
                        ? item.packageName
                        : "Own Package"}
                    </h5>
                    <div className="sites-content">
                      <ul>
                        {item.flight === "Yes" && (
                          <li className="sc2">
                            <figure>
                              <img src={flight} alt="flight" />
                            </figure>
                            <figcaption>Round trip</figcaption>
                          </li>
                        )}
                        {item.hotel === "Yes" && (
                          <li className="sc2">
                            <figure>
                              <img src={hotel} alt="hotel" />
                            </figure>
                            <figcaption>hotel</figcaption>
                          </li>
                        )}
                        {item.transfer === "Yes" && (
                          <li className="sc2">
                            <figure>
                              <img src={car} alt="car" />
                            </figure>
                            <figcaption>Round trip</figcaption>
                          </li>
                        )}
                        {item.totalActivity > 0 && (
                          <li className="sc3">
                            <figure>
                              <img src={ticketact} alt="ticket" />
                            </figure>
                            <figcaption>
                              {item.totalActivity} activities
                            </figcaption>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="tickt-content">
                      {item.booking_created_on}
                    </div>
                    <div className="tickt-content">
                      <ul>
                        <li>
                          <span>Booking ref ID </span>
                          <strong>{item.booking_reference_number}</strong>
                        </li>
                        <li>
                          <span>Payment ref ID</span>
                          <strong>
                            {item.booking_payment_reference !== "" &&
                            item.booking_payment_reference !== null
                              ? item.booking_payment_reference
                              : item.booking_reference_number}
                          </strong>
                        </li>
                        <li>
                          <span>No of travelers</span>
                          <strong>
                            {item.adults > 0 && <>{item.adults} x adult</>}
                            {item.child > 0 && <>, {item.child} x child</>}
                            {item.infant > 0 && <>, {item.infant} x infant</>}
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="btt-rhs">
                    <img
                      src={item.thumbnail !== "" ? item.thumbnail : actinfo1}
                      alt=""
                    />
                  </div>
                </div>
                <div className="booked-ticket-btm">
                  <div className="booked-ticket-btm-lhs">
                    <span>Total paid</span>
                    <p>
                      <strong>
                        {showPriceValue(item.booking_total_amount)}
                      </strong>
                    </p>
                  </div>
                  <div className="booked-ticket-btm-rhs">
                    <a
                      href={void 0}
                      onClick={this.downloadTicket.bind(this, item)}
                      className="button"
                    >
                      Download ticket
                    </a>
                    <a
                      href={void 0}
                      className="button ghost-button"
                      onClick={this.cancellTicket.bind(this, item)}
                    >
                      Cancel/Modify Booking
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
    this.setState({ displaybooking: displaybooking, loading: false });
  }

  cancellTicket(booking) {
    this.setState(
      {
        cancelPopupList: booking,
        booking_id: booking.booking_id,
        openTicketPopup: true,
      },
      function () {
        // $.magnificPopup.open({
        //   items: {
        //     src: "#ticket-popup",
        //   },
        //   type: "inline",
        //   showCloseBtn: false,
        //   midClick: true,
        //   closeOnBgClick: false,
        // });
      }
    );
  }
  handleChange = (event) => {
    var cancelList = this.state.cancelList;
    if (event.target.checked === true) {
      cancelList.push(event.target.value);
    } else {
      const index = cancelList.indexOf(event.target.value);
      cancelList.splice(index, 1);
    }

    this.setState({ cancelList: cancelList, cancelerrorMsg: false });
  };
  handlecategoryChange(type, event) {
    if (type === "category") {
      var categorylist = [...this.state.categorylist];
      console.log(categorylist, "categorylist");
      if (event?.target) {
        if (event.target.checked === true) {
          categorylist.push(event.target.value);
        } else {
          var index = categorylist.indexOf(event.target.value);
          if (index > -1) {
            categorylist.splice(index, 1);
          }
        }
      } else {
        var index = categorylist.indexOf(event);
        if (index > -1) {
          categorylist.splice(index, 1);
        } else {
          categorylist.push(event);
        }
      }
      this.setState({ categorylist }, () => {
        this.filterBookings();
        /*    this.displayBookingList() */
      });
    }
  }
  filterBookings = () => {
    const { filterBookings } = this.state;
    let filteredBookings = filterBookings.slice();

    if (this.state.categorylist.length > 0) {
      filteredBookings = filteredBookings.filter((booking) =>
        this.state.categorylist.some((category) =>
          booking.filter.toLowerCase().includes(category.toLowerCase())
        )
      );
    }

    this.setState({ bookinglist: filteredBookings }, () =>
      this.displayBookingList()
    );
  };

  confirmCancel() {
    this.setState({ openTicketPopup: true }, () => {
      var error = 0;
      if (this.state.cancelList.length === 0) {
        this.setState({ cancelerrorMsg: true });
        error++;
      } else {
        this.setState({ cancelerrorMsg: false });
      }
      if (this.state.cancel_reason === "") {
        error++;
        this.setState({ error_cancel_reason: "Please select any one reason" });
      } else {
        this.setState({ error_cancel_reason: "" });
      }
      if (this.state.cancel_reason === "Others") {
        if (this.state.cancel_other_reason === "") {
          error++;
          this.setState({
            error_cancel_other_reason: "Please enter other reason",
          });
        } else {
          this.setState({ error_cancel_other_reason: "" });
        }
      } else {
        this.setState({ error_cancel_other_reason: "" });
      }
      if (error === 0) {
        var postObject = {
          customer_id: this.state.UserId,
          booking_id: this.state.booking_id,
          itinerary: this.state.cancelList.join(","),
          cancel_reason: this.state.cancel_reason,
          cancel_other_reason: this.state.cancel_other_reason,
        };
        showLoader("proceed_cancel", "class");
        axios
          .post(apiUrl + "booking/cancelRequest", qs.stringify(postObject), {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            },
          })
          .then((res) => {
            // $.magnificPopup.close();
            hideLoader("proceed_cancel", "class");
            if (res.data.status === "ok") {
              // showAlert("Success", res.data.message);
              this.setState({
                openAlertPopup: true,
                popupTitle: "Success",
                alertPopupMsg: res.data.message,
              });
            } else if (res.data.status === "error") {
              // showAlert("Error", res.data.message);
              this.setState({
                openAlertPopup: true,
                popupTitle: "Error",
                alertPopupMsg: res.data.message,
              });
            }
            // $.magnificPopup.open({
            //   items: {
            //     src: ".alert_popup",
            //   },
            //   type: "inline",
            // });
          });
      }
    });
  }

  closePopup() {
    this.setState({ cancelList: [], openTicketPopup: false });
    // $.magnificPopup.close();
  }

  handleSelectChange(field, event) {
    this.setState({
      [field]: event.value,
      ["error_" + field]: "",
      error_cancel_other_reason: "",
    });
  }
  handleTextChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      ["error_" + event.target.name]: "",
    });
  }
  handleFilterChange = (event) => {
    this.setState({ loading: true });
    var selectedFilter = event.target.value;
    console.log(selectedFilter, "selected filter");
    this.setState({ loading: true });
    const { filterBookings } = this.state;
    console.log(filterBookings, "bookinglist");
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);
    const lastThreeMonths = new Date(today);
    lastThreeMonths.setMonth(today.getMonth() - 3);
    const lastSixMonths = new Date(today);
    lastSixMonths.setMonth(today.getMonth() - 6);
    const lastYear = new Date(today);
    lastYear.setFullYear(today.getFullYear() - 1);
    let filteredBookings = filterBookings.slice();
    setTimeout(() => {
      if (selectedFilter === "latestBookings") {
        filteredBookings = filterBookings.slice().sort((a, b) => {
          const dateA = new Date(a.booking_created_on);
          const dateB = new Date(b.booking_created_on);
          return dateB - dateA;
        });
      } else if (selectedFilter === "lastDay") {
        filteredBookings = filterBookings.filter((item) => {
          const bookingDate = new Date(item.booking_created_on);
          return bookingDate >= yesterday && bookingDate <= today;
        });
      } else if (selectedFilter === "lastWeek") {
        filteredBookings = filterBookings.filter((item) => {
          const bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastWeek && bookingDate <= today;
        });
      } else if (selectedFilter === "lastMonth") {
        filteredBookings = filterBookings.filter((item) => {
          const bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastMonth && bookingDate <= today;
        });
      } else if (selectedFilter === "last3Months") {
        filteredBookings = filterBookings.filter((item) => {
          const bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastThreeMonths && bookingDate <= today;
        });
      } else if (selectedFilter === "last6Months") {
        filteredBookings = filterBookings.filter((item) => {
          const bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastSixMonths && bookingDate <= today;
        });
      } else if (selectedFilter === "lastYear") {
        filteredBookings = filterBookings.filter((item) => {
          const bookingDate = new Date(item.booking_created_on);
          return bookingDate >= lastYear && bookingDate <= today;
        });
      } else {
        filteredBookings = filterBookings;
      }
      console.log(filteredBookings, "first filtered");
      this.setState(
        {
          selectedFilter: selectedFilter,
          bookinglist: filteredBookings,
        },
        () => {
          console.log(
            this.state.bookinglist,
            selectedFilter,
            "filteredBookings"
          );
          this.displayBookingList();
          this.setState({ loading: false });
        }
      );
    }, 1000);
    this.filterBookings();
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };
  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div className="bread-crumbs-other bread-crumbs">
          <div className="container">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>my bookings</li>
            </ul>
          </div>
        </div>
        <div></div>
        <section className="innersection">
          <div className="container">
            <div className="admin-profile">
              <Sidebar pageName="mybooking" />
              <div className="admin-profile-rhs mybooking-rhs">
                <div className="package-top-filters color-head-merge">
                  <div className="category-filter">
                    {this.state.category?.map((item, index) => (
                      <div
                        className={`category-box ${
                          this.state.categorylist?.includes(item)
                            ? "active"
                            : ""
                        }`}
                        key={index}
                        onClick={() =>
                          this.handlecategoryChange("category", item)
                        }
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="wishlisth-header-filter">
                  <div className="wishlisth-header">
                    <h5>
                      Your <span>Bookings</span>{" "}
                      <strong>
                        ({this.state.bookinglist.length}of{" "}
                        {this.state.total_records})
                      </strong>
                    </h5>
                  </div>
                  {this.state.isMobile ? (
                    <div className="ptf-options">
                      <ul>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.setState({ openFilterSheet: true })
                            }
                          >
                            <span>Filter By</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="filter-ge">
                      <select id="filter" onChange={this.handleFilterChange}>
                        <option value="latestBookings">latest Bookings</option>
                        <option value="lastDay">Last Day</option>
                        <option value="lastWeek">Last Week</option>
                        <option value="lastMonth">Last Month</option>
                        <option value="last3Months">Last 3 Months</option>
                        <option value="last6Months">Last 6 Months</option>
                        <option value="lastYear">Last Year</option>
                      </select>
                    </div>
                  )}
                </div>
                {this.state.loading
                  ? Array(1, 2, 3).map((item) => {
                      return (
                        <div className="mybooking-inner-main" key={item}>
                          <ContentLoader
                            height={140}
                            speed={1}
                            viewBox="0 0 380 70"
                          >
                            <rect
                              x="0"
                              y="0"
                              rx="5"
                              ry="5"
                              width="70"
                              height="70"
                            />
                            <rect
                              x="80"
                              y="17"
                              rx="4"
                              ry="4"
                              width="300"
                              height="13"
                            />
                            <rect
                              x="80"
                              y="40"
                              rx="3"
                              ry="3"
                              width="250"
                              height="10"
                            />
                          </ContentLoader>
                        </div>
                      );
                    })
                  : this.state.displaybooking}
                {this.state.showLoadMore && (
                  <a
                    href={void 0}
                    onClick={this.loadMore.bind(this)}
                    className="button loadmore"
                  >
                    Load More
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>
        <AlertPopup
          open={this.state.openAlertPopup}
          handleClose={() => this.setState({ openAlertPopup: false })}
          title={this.state.popupTitle}
          message={this.state.alertPopupMsg}
        />
        <ModalPopup
          open={this.state.openTicketPopup}
          handleClose={() => this.setState({ openTicketPopup: false })}
          className="common-popup"
        >
          {/* <div id="ticket-popup" className="mfp-hide common-popup"> */}
          <div className="cp-header">
            <h3>
              Choose your <span>Itinerary</span>
            </h3>
            <a
              className="mfp-close"
              href={void 0}
              onClick={this.closePopup.bind(this)}
            ></a>
          </div>
          <div className="cp-body">
            {Object.keys(this.state.cancelPopupList).length && (
              <ul className="cancel-ul">
                {this.state.cancelPopupList.flight === "Yes" && (
                  <li>
                    <div className="custom_checkbox custom_checkbox_content">
                      <input
                        type="checkbox"
                        onChange={this.handleChange}
                        value="Flight"
                      />
                      <div className="sign_reg">
                        <p>Flight</p>
                      </div>
                    </div>
                  </li>
                )}
                {this.state.cancelPopupList.hotel === "Yes" && (
                  <li>
                    <div className="custom_checkbox custom_checkbox_content">
                      <input
                        type="checkbox"
                        onChange={this.handleChange}
                        value="Hotel"
                      />
                      <div className="sign_reg">
                        <p>Hotel</p>
                      </div>
                    </div>
                  </li>
                )}
                {this.state.cancelPopupList.transfer === "Yes" && (
                  <li>
                    <div className="custom_checkbox custom_checkbox_content">
                      <input
                        type="checkbox"
                        onChange={this.handleChange}
                        value="Transfer"
                      />
                      <div className="sign_reg">
                        <p>Transfer</p>
                      </div>
                    </div>
                  </li>
                )}
                {this.state.cancelPopupList.totalActivity > 0 && (
                  <li>
                    <div className="custom_checkbox custom_checkbox_content">
                      <input
                        type="checkbox"
                        onChange={this.handleChange}
                        value="Transfer"
                      />
                      <div className="sign_reg">
                        <p>Activities</p>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            )}

            {this.state.cancelerrorMsg === true && (
              <span className="error">Please Select Atleat One Itinerary</span>
            )}
          </div>
          <div className="form-group pwd-group">
            <label className="control-label">Reason for cancel / Modify</label>
            <div className="change-group">
              <Select
                placeholder="Select Reason"
                onChange={this.handleSelectChange.bind(this, "cancel_reason")}
                options={[
                  {
                    value: "I am not planning to go on the selected day/time.",
                    label: "I am not planning to go on the selected day/time.",
                  },
                  {
                    value: "I have made a wrong booking.",
                    label: "I have made a wrong booking.",
                  },
                  { value: "Medical reason", label: "Medical reason" },
                  {
                    value: "Others",
                    label: "Others (Please specify the reason below)",
                  },
                ]}
              />
              {this.state.error_cancel_reason !== "" && (
                <div className="error">{this.state.error_cancel_reason}</div>
              )}
            </div>
          </div>
          <div className="form-group pwd-group">
            <label className="control-label">Others</label>
            <div className="change-group">
              <textarea
                placeholder="Please enter the reason here"
                name="cancel_other_reason"
                onChange={this.handleTextChange.bind(this)}
              ></textarea>
              {this.state.error_cancel_other_reason !== "" && (
                <div className="error">
                  {this.state.error_cancel_other_reason}
                </div>
              )}
            </div>
          </div>

          <div className="cp-form">
            <div className="booknow-btm">
              <a
                className="button proceed_cancel"
                onClick={this.confirmCancel.bind(this)}
              >
                Confirm
              </a>
            </div>
          </div>
          {/* </div> */}
        </ModalPopup>
        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openFilterSheet: false })}
        >
          <div className="searchContainer__bottomSheetMain__container">
            <div className="searchContainer__bottomSheetMain__container__sourceAir">
              {filterOptions?.map((option, index) => (
                <div
                  key={index}
                  className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                    this.state.selectedFilter === option?.value
                      ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                      : ""
                  }`}
                  onClick={() => {
                    this.handleFilterChange({
                      target: { value: option?.value },
                    });
                    this.setState({ openFilterSheet: false });
                  }}
                >
                  {option?.label}
                </div>
              ))}
            </div>
          </div>
        </BottomSheet>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var bookinglistArr = Array();
  if (Object.keys(state.bookinghistory).length > 0) {
    if (state.bookinghistory[0].status === "ok") {
      bookinglistArr = state.bookinghistory[0].result_set;
    }
  }
  return {
    bookinglist: bookinglistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBookingHistory: (params) => {
      dispatch({ type: GET_BOOKINGHISTORY, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
