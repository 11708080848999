/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Axios from "axios";
import {
  apiUrl,
  headerconfig,
  googleMapsKey,
} from "../Helpers/Config";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import information from "../../common/images/info-black.svg";
import placeholders from "../../common/images/av-placeholder.jpg";
import chain from "../../common/images/link-chain.svg";
import flag from "../../common/images/singapore-flag.png";
import actinfo1 from "../../common/images/act-info1.jpg";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heart-fill.svg";
import car from "../../common/images/car-icon.svg";
import ticketact from "../../common/images/ticket-activity.svg";
import hotel from "../../common/images/hotel-icon.svg";
import flight from "../../common/images/flight-takeoff.svg";
import bcal from "../../common/images/calender-banner.svg";
import bpin from "../../common/images/map-pin.svg";
import bprofile from "../../common/images/banner-profile.svg";
import barrow from "../../common/images/bluearrow.svg";
import avholder from "../../common/images/placeholder.jpg";
import filter from "../../common/images/filter.svg";
import cardbg from "../../common/images/card-image.jpg";
import medal from "../../common/images/medal.svg";
import facebook from "../../common/images/facebook.svg";
import insta from "../../common/images/instagram.svg";
import bb1 from "../../common/images/singapore-banner.jpg";
import bb2 from "../../common/images/map-top.jpg";

import { showPriceValue } from "../Helpers/SettingHelper";
import { Country, State, City } from "country-state-city";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

var base64 = require("base-64");

class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "props");
    var countryName = "";
    if (
      this.props.match.params.countryName !== "" &&
      this.props.match.params.countryName !== undefined &&
      this.props.match.params.countryName !== "undefined"
    ) {
      countryName = this.props.match.params.countryName;
    }
    console.log(countryName, "countryname");
    this.state = {
      countryName: countryName,
      creatordetail: "",
      creatorHandleName: "",
      creatorType: "",
      creatorCountry: "",
      creator_name: "",
      creator_image: "",
      creator_coverimage: "",
      creator_instagram_link: "",
      creator_tiktok_link: "",
      creator_facebook_link: "",
      creator_website_link: "",
      creator_Youtube_link: "",
      creator_packages: [],
      locationData: {},
      flagUrl: "",
      creator_description: "",
    };
    /*  if (cookie.load("CUserId") == "" || cookie.load("CUserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "creator_id=" + cookie.load("CUserId");
      this.props.getCreatorDetail(params);
    } */
  }

  async componentDidMount() {
    try {
      const response = await Axios.get(
        `${apiUrl}creators/creatordetail?creator_id=${base64.encode(
          cookie.load("CUserId")
        )}`,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        }
      );

      if (response.data.status === "ok") {
        const { result_set } = response.data;

        this.setState({
          creatorHandleName: result_set.creator_handle_name,
          creator_name: result_set.creator_name,
          creatorType: result_set.creator_type,
          creatorCountry: result_set.creator_country,
          creator_image: result_set.creator_image,
          creator_coverimage: result_set.creator_coverimage,
          creator_instagram_link: result_set.creator_instagram_link,
          creator_tiktok_link: result_set.creator_tiktok_link,
          creator_facebook_link: result_set.creator_facebook_link,
          creator_website_link: result_set.creator_website_link,
          creator_Youtube_link: result_set.creator_Youtube_link,
          creator_packages: result_set.air_creator_package_records,
          creator_description: result_set.creator_description,
        });

        const countryName = result_set.creator_country;
        this.fetchFlag(countryName);
      } else {
        console.log("creator details failed in mount");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    this.fetchLocationData();
  }
  fetchLocationData() {
    Axios.get(
      apiUrl + "package/getImages?city_name=" + this.state.countryName,
      {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      }
    )
      .then((res) => {
        if (res.data.status === "ok") {
          console.log(res.data.result_set, "res");
          this.setState({
            locationData: res.data.result_set,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  async fetchFlag(countryName) {
    try {
      const response = await Axios(
        `https://restcountries.com/v3.1/name/${countryName}`
      );
      const data = response.data;
      const country = data[0];

      if (country && country.flags) {
        const flagUrl = country.flags.svg;
        this.setState({ flagUrl });

        console.log("Flag URL:", flagUrl);
      } else {
        console.error("Country not found or no flag data available.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.creatordetail !== nextProps.creatordetail) {
      var creatorDetails = nextProps.creatordetail;
      this.setState({
        creatordetail: creatorDetails,
      });
    }
  }
  /* var trendoption = {
    items: 2,
    loop: true,
    dots: false,
    nav: false,
    margin: 40,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      580: {
        items: 2,
        margin: 30,
      },
      980: {
        margin: 40,
      },
    },
  }; */
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };
  render() {
    var creator_packages = this.state.creator_packages;
    const iframeSrc = `https://www.google.com/maps/embed/v1/place?key=${googleMapsKey}&q=${encodeURIComponent(
      this.state.countryName
    )}`;
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />

        <section className="map-banner-parent">
          <div className="banner-map">
            <div className="bm-lhs">
              <img src={this.state.locationData.large_image} alt="" />
              <div className="pl-name">
                <h2>{this.state.locationData.city_name}</h2>
                <p>{this.state.locationData.description}</p>
              </div>
            </div>
            <div className="bm-rhs">
              <div className="bmr-top">
                <img src={this.state.locationData.small_image} alt="" />
              </div>
              <div className="bmr-btm">
                <iframe
                  src={iframeSrc}
                  width="100%"
                  height="100%"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="innersection pro-spacer">
          <div className="container">
            <div className="traveler-main-package">
              <h4 className="color-header">
                {this.state.creatorHandleName}'s <span>Packages</span>
              </h4>
              {creator_packages.length > 0 ? (
                <div className="traveler-main-package-list">
                  <ul>
                    {creator_packages.map((item, index) => {
                      return (
                        <li key={index}>
                          <div
                            className="product-list-parent"
                            // onClick={
                            //   this.state.isMobile && this.viewPackage.bind(this, item)
                            // }
                            //  onClick={this.viewPackage.bind(this, item)}
                          >
                            <div className="product-img">
                              <a href={void 0} className="like">
                                <img src={heart} alt="heart" />{" "}
                                <img src={heartfill} alt="heartfill" />
                              </a>
                              <a
                                className="pt-img"
                                href={void 0}
                                //  onClick={this.viewPackage.bind(this, item)}
                              >
                                <img
                                  src={
                                    creator_packages[index]
                                      .creator_package_thumbnail !== "" &&
                                    creator_packages[index]
                                      .creator_package_thumbnail !== undefined
                                      ? creator_packages[index]
                                          .creator_package_thumbnail
                                      : actinfo1
                                  }
                                  alt=""
                                />
                              </a>
                              <div className="ptd-day">
                                {" "}
                                {
                                  creator_packages[index]
                                    .creator_package_total_days
                                }{" "}
                                Days
                              </div>
                              <div className="new-tags">
                                <div className="c-tags">Couple</div>
                                <div className="e-tags">Entertainment</div>
                              </div>
                            </div>
                            <div className="product-description">
                              <div className="product-description-title">
                                <h5>
                                  <a
                                    href={void 0}
                                    //  onClick={this.viewPackage.bind(this, item)}
                                  >
                                    {
                                      creator_packages[index]
                                        .creator_package_name
                                    }
                                  </a>{" "}
                                </h5>
                              </div>
                              <div className="product-created-content">
                                <div className="created-av">
                                  <img
                                    src={
                                      this.state.creator_image !== "" &&
                                      this.state.creator_image !== null
                                        ? this.state.creator_image
                                        : avholder
                                    }
                                    alt={item.creator_image}
                                  />
                                </div>
                                <div className="created-av-title">
                                  <h4>
                                    <span>Created by</span>
                                    {this.state.creator_name}{" "}
                                  </h4>
                                  <div className="creator-badge">
                                    <span>
                                      <img src={medal} alt="Medal" />
                                    </span>{" "}
                                    <strong>
                                      Level <em>01</em>
                                    </strong>
                                  </div>
                                </div>
                              </div>
                              <div className="sites-content">
                                <ul>
                                  <li className="sc1">
                                    <figure>
                                      <img src={flight} alt="flight" />
                                    </figure>
                                    <figcaption>Flight (add on)</figcaption>
                                  </li>
                                  <li className="sc4">
                                    <figure>
                                      {" "}
                                      <img src={car} alt="car" />{" "}
                                    </figure>
                                    <figcaption>Transfer (add on)</figcaption>
                                  </li>
                                  {/* {item.stay.length > 0 && ( */}
                                  <li className="sc2">
                                    <figure>
                                      <img src={hotel} alt="hotel" />
                                    </figure>
                                    <figcaption> hotel options</figcaption>
                                  </li>
                                  {/* )} */}

                                  {/* {item.total_activities > 0 && ( */}
                                  <li className="sc3">
                                    <figure>
                                      <img src={ticketact} alt="ticket" />
                                    </figure>
                                    <figcaption>Things to do</figcaption>
                                  </li>
                                  {/* )} */}
                                </ul>
                              </div>
                              <div className="product-description-review">
                                <div className="pdr-lhs">
                                  {creator_packages[index]
                                    .creator_package_rating !== "" &&
                                    creator_packages[index]
                                      .creator_package_rating !== null && (
                                      <span>
                                        <strong>
                                          {
                                            creator_packages[index]
                                              .creator_package_rating
                                          }
                                        </strong>{" "}
                                        (
                                        {
                                          creator_packages[index]
                                            .creator_package_total_rating
                                        }
                                        ) Reviews
                                      </span>
                                    )}
                                  <div className="star-rating">
                                    {/* {this.loadRatingstar(item.creator_package_rating)} */}
                                  </div>
                                </div>
                                <div className="pdr-rhs">
                                  <em>Starts from </em>
                                  <strong>
                                    {showPriceValue(item.creator_package_price)}
                                    <span> Per Adult</span>
                                  </strong>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div>
                  <p>No packages found </p>
                </div>
              )}
            </div>
          </div>
        </section>

        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.creatordetail).length > 0) {
    if (state.creatordetail[0].status === "ok") {
      custdetailArr = state.creatordetail[0].result_set;
      common = state.creatordetail[0].creatordetail;
    }
  }
  return {
    creatordetail: custdetailArr,
    common: common,
  };
};

export default connect(mapStateTopProps)(Listpromo);
