import React, { useState } from "react";
import "./RoomSelection.scss";
import OwlCarousel from "react-owl-carousel2";
import "../../../../common/css/owl.carousel.css";
import LeftIcon from "../../../../common/images/chevron-left.svg";
import UserIcon from "../../../../common/images/user.svg";
import information from "../../../../common/images/info-circle.svg";
import { showPriceValue } from "../../../Helpers/SettingHelper";
import CloseIcon from "../../../../common/images/close-icon-side-sheet.svg";
import { Button } from "@mui/material";

const staygallery = {
  items: 1,
  loop: true,
  dots: false,
  nav: true,
};

const RoomSelection = (rProps) => {
  const {
    state,
    checkRoomSelection,
    loadHotelImages,
    selectRoomPrice,
    handleViewMoreClick,
    disableRadio,
    cancellationPolicy,
    showRoomPrice,
    selectRoom,
    disableRoom,
    setShowChooseRoom,
    continueRoom,
    selectMainRoom,
  } = rProps;

  const selectedRoomPrice = {};

  const [selectedPrice, setSelectedPrice] = useState({});

  const updateSelectedRoomPrice = (roomCode, index, priceId) => {
    selectedRoomPrice[roomCode] = { index, priceId };
  };

  return (
    <div className="room-selection-main">
      <div className="room-selection-main__header">
        <div className="room-selection-main__header__sub">
          <div className="room-selection-main__header__sub__col">
            <img src={LeftIcon} onClick={() => setShowChooseRoom(false)} />
          </div>
          <div className="room-selection-main__header__sub__col1">
            <div className="room-selection-main__header__sub__col1__title">
              More Stays
            </div>
            <div className="room-selection-main__header__sub__col1__sub">
              Select the stay from available options{" "}
            </div>
          </div>
          {/* <div className="room-selection-main__header__sub__col2">
            <div>
              <img
                src={CloseIcon}
                alt="back-icon"
                className="room-selection-main__header__sub__col2__close"
                // onClick={handleCloseSheet}
              />
            </div>
          </div> */}
        </div>
        <div className="room-selection-main__header__tabs">
          {state.hotelResult.room_info.map((item, index) => (
            <div
              key={index}
              id="preferred-room"
              className={`room-selection-main__header__tabs__tab ${
                state.currentRoom === index
                  ? "room-selection-main__header__tabs__active"
                  : ""
              }`}
              onClick={() => selectMainRoom(index)}
            >
              Room 0{parseInt(index) + 1}
            </div>
          ))}
        </div>
        <div></div>
      </div>
      <div className="room-selection-main__content">
        {state?.hotelResult?.room_info.length > 0 && (
          <>
            {state.availableRooms === true ? (
              <>
                {/* <div className="preferred-room" id="preferred-room">
                  <ul>
                    {state.hotelResult.room_info.map((item, index) => {
                      return (
                        <li
                          className={
                            state.currentRoom === index ? "active" : ""
                          }
                          key={index}
                        >
                          {state.roomCount > 1 && (
                            <a
                              href={void 0}
                              onClick={this.selectMainRoom.bind(this, index)}
                            >
                              Room {parseInt(index) + 1} options
                            </a>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
                {state.hotelResult.room_info.map((roomItem, roomIndex) => {
                  return (
                    <ul
                      key={roomIndex}
                      style={{
                        display: state.currentRoom !== roomIndex ? "none" : "",
                      }}
                    >
                      {roomItem.map((item, index) => {
                        var checkRoomSelectionVal = checkRoomSelection(
                          index,
                          roomIndex
                        );
                        return (
                          <li
                            key={index}
                            id={"room_" + roomIndex + "_" + item.room_type_code}
                            className={
                              checkRoomSelectionVal > 0
                                ? "active room-selection-main__content__card"
                                : "room-selection-main__content__card"
                            }
                          >
                            <div className="room-selection-main__content__card__row">
                              <div className="room-selection-main__content__card__row__lhs">
                                <div className="room-parent">
                                  <div className="room-choice-img">
                                    <OwlCarousel options={staygallery}>
                                      {loadHotelImages(
                                        state.hotelResult.images
                                      )}
                                    </OwlCarousel>
                                  </div>
                                </div>
                              </div>
                              <div className="room-selection-main__content__card__row__rhs">
                                <div className="room-selection-main__content__card__row__rhs__title">
                                  {item.room_type_name}
                                </div>
                                <div className="room-selection-main__content__card__row__rhs__rmCapacity">
                                  <img src={UserIcon} />{" "}
                                  <span className="room-selection-main__content__card__row__rhs__rmCapacity__label">
                                    Room Capacity{" "}
                                  </span>
                                  {parseInt(item.max_adult) > 0 && (
                                    <span className="room-selection-main__content__card__row__rhs__rmCapacity__pCount">
                                      {parseInt(item.max_adult)} Adult
                                      {parseInt(item.max_adult) > 1 ? "s" : ""}
                                    </span>
                                  )}
                                  {parseInt(item.max_children) > 0 && (
                                    <span className="room-selection-main__content__card__row__rhs__rmCapacity__pCount">
                                      {parseInt(item.max_children)} Child
                                      {parseInt(item.max_children) > 1
                                        ? "s"
                                        : ""}
                                    </span>
                                  )}
                                </div>

                                <div className="details-policy">
                                  <a
                                    href={void 0}
                                    onClick={() =>
                                      cancellationPolicy(item, selectedPrice)
                                    }
                                  >
                                    <img src={information} alt="info" />
                                    <span>
                                      More details on cancellation policy
                                    </span>{" "}
                                  </a>
                                </div>

                                <div className="room-selection-main__content__card__row__rhs__amenities">
                                  {item.room_amenities !== "" &&
                                    item.room_amenities !== null &&
                                    item.room_amenities
                                      ?.split("#@#")
                                      ?.slice(0, state?.isMobile ? 1 : 2)
                                      ?.map((amenity) => (
                                        <div className="room-selection-main__content__card__row__rhs__amenities__val">
                                          {amenity.charAt(0).toUpperCase() +
                                            amenity.slice(1)}
                                        </div>
                                      ))}

                                  {state.hotel_amenities.length > 0 && (
                                    <div
                                      className="room-selection-main__content__card__row__rhs__amenities__val room-selection-main__content__card__row__rhs__amenities__more"
                                      onClick={() =>
                                        handleViewMoreClick(
                                          "hotel_info_popup_" +
                                            roomIndex +
                                            "_" +
                                            item.room_type_code
                                        )
                                      }
                                    >
                                      +{" "}
                                      {item.room_amenities?.split("#@#")
                                        ?.length -
                                        (state?.isMobile ? 1 : 2)}{" "}
                                      more
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="room-selection-main__content__card__roomCharges">
                              {item.room_charges.length > 0 && (
                                <>
                                  <div className="room-selection-main__content__card__roomCharges__title">
                                    <img src={information} alt="info" />
                                    Select Extra Options{" "}
                                  </div>
                                  <div className="room-selection-main__content__card__roomCharges__content">
                                    <ul className="room-selection-main__content__card__roomCharges__content__chargeList">
                                      {item.room_charges.map(
                                        (chargeItem, chargeIndex) => {
                                          if (chargeIndex === 0) {
                                            updateSelectedRoomPrice(
                                              item.room_type_code,
                                              chargeIndex,
                                              chargeItem.rate_id
                                            );
                                          }

                                          return (
                                            <li key={chargeIndex}>
                                              <div>
                                                <input
                                                  type="radio"
                                                  className="room_charge"
                                                  name={
                                                    "room_charge_" +
                                                    roomIndex +
                                                    "_" +
                                                    item.room_type_code
                                                  }
                                                  data-itemindex={chargeIndex}
                                                  value={
                                                    chargeItem.rate_id +
                                                    "_" +
                                                    chargeIndex
                                                  }
                                                  defaultChecked={
                                                    chargeIndex === 0 &&
                                                    state.selectedRoom
                                                      ?.length === 0
                                                      ? true
                                                      : state.selectedRoom
                                                          ?.length
                                                      ? state.selectedRoom?.some(
                                                          (room) =>
                                                            room.priceIndex ==
                                                              chargeIndex &&
                                                            index ===
                                                              room?.roomIndex
                                                        ) || chargeIndex === 0
                                                      : false
                                                  }
                                                  onChange={() => {
                                                    setSelectedPrice({
                                                      [item.room_type_code]: {
                                                        index: chargeIndex,
                                                        priceId:
                                                          chargeItem.rate_id,
                                                      },
                                                    });
                                                    selectRoomPrice(
                                                      item.room_type_code,
                                                      chargeItem.room_total_amount,
                                                      item,
                                                      chargeIndex,
                                                      roomIndex,
                                                      chargeItem.rate_id
                                                    );
                                                  }}
                                                  disabled={
                                                    disableRadio(
                                                      index,
                                                      roomIndex
                                                    ) ||
                                                    checkRoomSelectionVal > 0
                                                  }
                                                />
                                                <span>
                                                  {chargeItem.rate_description}
                                                </span>
                                              </div>
                                              <strong>
                                                {showPriceValue(
                                                  chargeItem.room_total_amount,
                                                  state.currentCurrency,
                                                  state.currencyRate
                                                )}
                                              </strong>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>

                                  <div className="room-choice-four">
                                    <div className="rcf-lhs">
                                      <p>
                                        <strong className="selected_room_price">
                                          {showRoomPrice(item, 0)}
                                        </strong>{" "}
                                      </p>
                                      <span>Total Excludes Taxes and Fees</span>
                                    </div>
                                    <div className="rcf-rhs">
                                      <button
                                        className={
                                          checkRoomSelectionVal > 0
                                            ? "button ghost-button rmv-button"
                                            : "button ghost-button"
                                        }
                                        onClick={() =>
                                          selectRoom(
                                            item.room_type_code,
                                            index,
                                            item,
                                            roomIndex,
                                            checkRoomSelectionVal,
                                            state.hotelResult.hotel_name,
                                            selectedRoomPrice,
                                            checkRoomSelectionVal > 0
                                          )
                                        }
                                        // disabled={disableRoom(index, roomIndex)}
                                      >
                                        {checkRoomSelectionVal > 0
                                          ? "Remove"
                                          : "Select"}
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  );
                })}
              </>
            ) : (
              state.availablemsg
            )}
          </>
        )}
      </div>
      <div className="room-selection-main__footer">
        <div className="room-selection-main__footer__col">
          <div className="room-selection-main__footer__col__price">
            Total{" "}
            {showPriceValue(
              state.grand_total !== "" ? state.grand_total : 0,
              state.currentCurrency,
              state.currencyRate
            )}
          </div>
          <div className="room-selection-main__footer__col__breakDown">
            <span>
              {state.selectedRoom.length ? (
                <>
                  {state.selectedRoom.map((item, index) => (
                    <>
                      {state.selectedRoom.length > 1 ? (
                        <span>Room {parseInt(index) + 1}</span>
                      ) : (
                        <span>Room Price</span>
                      )}{" "}
                      {showPriceValue(
                        item.room_total_amount,
                        state.currentCurrency,
                        state.currencyRate
                      )}
                    </>
                  ))}{" "}
                </>
              ) : (
                showPriceValue(0, state.currentCurrency, state.currencyRate)
              )}
            </span>
          </div>
        </div>
        <div className="room-selection-main__footer__col">
          <Button
            className={`room-selection-main__footer__col__continueBtn ${
              state.selectedRoom.length > 0
                ? "check_room_avail"
                : "disabled-link"
            }`}
            variant="contained"
            disabled={state.selectedRoom.length === 0}
            onClick={() => continueRoom()}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RoomSelection;
