/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_MYSTIFLYREVALIDATE_RETURN, SET_MYSTIFLYREVALIDATE_RETURN } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetMystiflyRevalidateReturn = function* () {
  yield takeEvery(
    GET_MYSTIFLYREVALIDATE_RETURN,
    workerGetMystiflyRevalidateReturn
  );
};

function* workerGetMystiflyRevalidateReturn({ formPayload }) {
  try {
    if (formPayload?.resetData === "Yes") {
      var resultArr = [];
      resultArr.push();
    } else {
      const result = yield call(getMystiflyRevalidateReturn, formPayload);
      var resultArr = [];
      resultArr.push(result.data);
    }
    yield put({ type: SET_MYSTIFLYREVALIDATE_RETURN, value: resultArr });
  } catch {
    /* showAlert("Error", "Something went wrong, please try again later");
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    }); */

    console.log("login failed");
  }
}

function getMystiflyRevalidateReturn(formPayload) {
  return Axios.post(
    apiUrl + "package/revalidatePrice",
    formPayload,
    {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      },
    }
  );
}
