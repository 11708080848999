/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
// import MailchimpSubscribe from "react-mailchimp-subscribe";
import { projectTitle } from "../Helpers/Config";
import { showLoader, hideLoader } from "../Helpers/SettingHelper";
import { apiUrl } from "../Helpers/Config";
import logo from "../../common/images/logo.svg";
import facebook from "../../common/images/facebook.svg";
import insta from "../../common/images/instagram.svg";
import mastercard from "../../common/images/master-card.svg";
import LogoWhite from "../../common/images/white-logo.svg";
import jcbcard from "../../common/images/jcb-card.svg";
import visacard from "../../common/images/visa-card.svg";
import amexcard from "../../common/images/amex-card.svg";
import unipaycard from "../../common/images/unipay-card.svg";
import dinersclubcard from "../../common/images/diners-club-card.svg";
import nkids from "../../common/images/newsletter-kids.jpg";
import YoutubeImg from "../../common/images/Youtube.svg";
import IgImg from "../../common/images/Ig.svg";
import FbImg from "../../common/images/Fb.svg";
import LnImg from "../../common/images/Ln.svg";
import TwImg from "../../common/images/Tw.svg";

import "./Footer/Footer.scss";
import getCityList from "../Home/getCityList.json";

const url =
  "https://fizota.us5.list-manage.com/subscribe/post?u=041df7d4d012616a6c7786ae9&amp;id=3d95a637fb";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city_List_carousel: {},
    };
  }
  componentDidMount() {
    this.loadInititalFN();
  }

  loadInititalFN() {
    var acccesstoken = cookie.load("acccesstoken") || "";
    if (acccesstoken !== "") {
      this.loadCity();
    } else {
      setTimeout(() => {
        this.loadInititalFN();
      }, 1000);
    }
  }

  openPopup(popupID) {
    $("#close_mobile_menu").trigger("click");
    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
      midClick: true,
    });
  }
  loadCity = async () => {
    if (getCityList.status === "ok") {
      this.setState({
        city_List_carousel: getCityList.result_set,
      });
    }
  };
  selectCountryPackages(countryName, cityName) {
    var selected_City = "";
    Object.values(this.state.city_List_carousel).forEach((country) => {
      var countryDetails = Object.values(country);
      if (countryDetails[0] === countryName) {
        countryDetails[1].map((city) => {
          if (city.city_name === cityName) {
            selected_City = city;
          }
        });
      }
    });
    console.log(selected_City, "selected_City");
    var searchdata = {
      start_date: new Date(),
      end_date: new Date(),
      selectedMonth: "",
      search_days: "2-5",
      adults: 1,
      child: "",
      infant: "",
      room_count: 1,
      source_airport_code: "BLR",
      location_source:
        "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka 560300, India",
      source_airport_id: "2800",
      destinationWebbedsCity: selected_City?.webbeds_city_code,
      creator_package_total_days: 3,
      source_airport_address: "BLR",
      source_airport_lat: "12.994112",
      source_airport_lon: "80.1708668",
      destination_airport_lat: selected_City?.airport_latitude,
      destination_airport_lon: selected_City?.airport_longitude,
      destination_airport_address: selected_City?.airport_address,
      destinationCity: selected_City?.city_name,
      destinationCountry: selected_City?.country_name,
      destination_airport_code: selected_City?.airport_code,
      destination_airport_id: selected_City?.airport_id,
      location_destination: selected_City?.airport_address,
      thumbnail: "",
      images: "",
      childAgeList: [],
      trip_type: "Return",
      transfer_trip_type: "Return",
      sourceCountry: "India",
      sourceCity: "",
      checkinTime: "",
      activityList: "",
      total_activities: "",
      /*   homePackFlow: homePackFlow, */
      packageType: "uop",
    };
    this.props.history.push({
      pathname: `/packages/${countryName}/${cityName}`,
      state: searchdata,
    });
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <>
        <footer className="footer-main footer-container">
          <div className="destination">
            <div className="title">Popular destinations</div>
            <ul>
              <li>Singapore</li>
              <li>Bali</li>
              <li>Tokyo</li>
              <li>Cambodia</li>
            </ul>
            <div className="title">Popular destinations for family</div>
            <ul>
              <li>Singapore</li>
              <li>Bali</li>
              <li>Tokyo</li>
              <li>Cambodia</li>
            </ul>
            <div className="title">Popular destinations for couples</div>
            <ul>
              <li>Singapore</li>
              <li>Bali</li>
              <li>Tokyo</li>
              <li>Cambodia</li>
            </ul>
          </div>
          <div className="divider"></div>
          <div className="row">
            <div className="col1">
              <div className="container">
                <div className="footer-top">
                  {/* <div className="footer-logo">
                    <Link to="/" title="ChopChop" className="float-logo">
                      {" "}
                      <img src={logo} alt="ChopChop" />{" "}
                    </Link>
                    <p>
                      We inspire and helps to craft an exicting experience for
                      you.
                    </p>
                    <div className="social-media-parent">
                      <h6>Follow us</h6>
                      <div className="social-media">
                        <a
                          href="https://www.facebook.com/fizotasg"
                          target="_blank"
                        >
                          {" "}
                          <img
                            src={facebook}
                            alt="Fackbook"
                            loading="lazy"
                          />{" "}
                        </a>
                        <a
                          href="https://www.instagram.com/hello_fizota/"
                          target="_blank"
                        >
                          {" "}
                          <img
                            src={insta}
                            alt="Instagram"
                            loading="lazy"
                          />{" "}
                        </a>
                      </div>
                      <p>STB TA License: TA03466</p>{" "}
                    </div>
                  </div> */}
                  <div className="footer-nav">
                    <div className="footer-nav-inner">
                      <h5>The company</h5>{" "}
                      <ul>
                        <li>
                          <a href="/aboutus" target="_blank">
                            About Fizota
                          </a>
                        </li>
                        <li>
                          <Link to={"/terms-and-conditions"} target="_blank">
                            Terms & Conditions{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/privacy-policy"} target="_blank">
                            Privacy Policy{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to={"/cancellation-and-refund"} target="_blank">
                            Cancellation & Refund Policy{" "}
                          </Link>
                        </li>

                        {/* <li>
                      <Link to="/help" target="_blank">
                        FAQs
                      </Link>
                    </li> */}
                      </ul>
                    </div>
                    <div className="footer-nav-inner">
                      <h5>Products</h5>{" "}
                      <ul>
                        <li>
                          <a href="/aboutus" target="_blank">
                            Vacations
                          </a>
                        </li>
                        <li>
                          <Link to={"/terms-and-conditions"} target="_blank">
                            Things to do
                          </Link>
                        </li>
                        <li>
                          <Link to={"/privacy-policy"} target="_blank">
                            Flights
                          </Link>
                        </li>
                        <li>
                          <Link to={"/cancellation-and-refund"} target="_blank">
                            Creator
                          </Link>
                        </li>

                        {/* <li>
                      <Link to="/help" target="_blank">
                        FAQs
                      </Link>
                    </li> */}
                      </ul>
                    </div>
                    <div className="footer-nav-inner">
                      <h5>Explore</h5>
                      <ul>
                        {/* <li>
                      <a
                        href="https://www.fizota.com/travel-planner/"
                        target="_blank"
                      >
                        Travel planner
                      </a>
                    </li> */}
                        <li>
                          <a
                            href="https://www.fizota.com/blog/"
                            target="_blank"
                          >
                            Blogs
                          </a>
                        </li>
                        <li>
                          <Link to="/myaccount/helpcenter" target="_blank">
                            {" "}
                            <span>Help Center</span>{" "}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="footer-nav-inner">
                      <h5>Destination</h5>
                      <ul>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages(
                                "Singapore",
                                "Singapore"
                              )
                            }
                          >
                            Singapore
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages("Indonesia", "Bali")
                            }
                          >
                            Bali
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages("Japan", "Tokyo")
                            }
                          >
                            Tokyo
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages("Thailand", "Bangkok")
                            }
                          >
                            Bangkok
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages("Japan", "Osaka")
                            }
                          >
                            Osaka
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages("Japan", "Kyoto")
                            }
                          >
                            Kyoto
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages("Thailand", "Phuket")
                            }
                          >
                            Phuket
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages("Vietnam", "Hanoi")
                            }
                          >
                            Hanoi
                          </a>
                        </li>
                        <li>
                          <a
                            href={void 0}
                            onClick={() =>
                              this.selectCountryPackages("Vietnam", "Hoi An")
                            }
                          >
                            Hoi An
                          </a>
                        </li>
                      </ul>
                    </div> */}
                    <div className="footer-nav-inner payment-footer">
                      <h5>Accepted Payment</h5>
                      <ul>
                        <li>
                          <img
                            src={mastercard}
                            alt="Master Card"
                            loading="lazy"
                          />
                        </li>
                        <li>
                          <img src={jcbcard} alt="Master Card" loading="lazy" />
                        </li>
                        <li>
                          <img
                            src={visacard}
                            alt="Master Card"
                            loading="lazy"
                          />
                        </li>
                        <li>
                          <img src={amexcard} alt="Amex Card" loading="lazy" />
                        </li>
                        <li>
                          <img
                            src={unipaycard}
                            alt="Amex Card"
                            loading="lazy"
                          />
                        </li>
                        <li>
                          <img
                            src={dinersclubcard}
                            alt="Amex Card"
                            loading="lazy"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col2"></div>
          </div>
          <div className="divider"></div>
          <div className="copyright-section">
            <div>
              <img src={LogoWhite} />
            </div>
            <div className="container">
              <p>
                Copyright {yearSp} {projectTitle}.
              </p>
              <p>
                Made with <i className="fa fa-heart" aria-hidden="true"></i> by
                Fizota team
                <br />© 2023 Airvays Technologies Pte.Ltd All Rights Reserved
              </p>
            </div>
            <div className="social-section">
              <img src={YoutubeImg} />
              <img src={FbImg} />
              <img src={TwImg} />
              <img src={IgImg} />
              <img src={LnImg} />
            </div>
          </div>

          <div className="scrolltop" id="scrollbutton">
            <a href={void 0} className="disbl_href_action">
              <i className="fa fa-angle-up" aria-hidden="true"></i>
            </a>
          </div>

          <div className="overlay" id="overlay">
            <div className="pattern">
              <div className="over-close">
                {" "}
                <img
                  src="assets/images/close.svg"
                  className="close-icon"
                />{" "}
              </div>
              <div className="nav-logo textcenter">
                <a href="#">
                  {" "}
                  <img src="assets/images/logo.svg" alt="Logo" />{" "}
                </a>
              </div>
              <nav id="mob_menu">
                <ul className="device_nav">
                  <li>
                    <Link to={"/"}>
                      <span>Explore</span>
                    </Link>
                    <ul>
                      <li>
                        <Link to="/activities/singapore">Singapore</Link>
                      </li>
                      <li>
                        <Link to={"/activities/indonesia"}>Bali</Link>
                      </li>
                      <li>
                        <Link to={"/activities/hong-kong"}>Honk Kong</Link>
                      </li>
                      <li>
                        <Link to={"/activities/malaysia"}>Malaysia</Link>
                      </li>
                      <li>
                        <Link to={"/activities/thailand"}>Thailand</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    <a href={void 0}>
                      <span>SGD</span>
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link to="/myaccount/helpcenter">
                      {" "}
                      <span>Help</span>
                    </Link>
                  </li>
                  {!cookie.load("UserId") && (
                    <>
                      <li>
                        {" "}
                        <a
                          href={void 0}
                          onClick={this.openPopup.bind(this, "#signup-popup")}
                        >
                          <span>Sign Up</span>
                        </a>{" "}
                      </li>
                      <li>
                        {" "}
                        <a
                          href={void 0}
                          onClick={this.openPopup.bind(this, "#login-popup")}
                        >
                          <span>Sign Up</span>
                        </a>{" "}
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });
  if (status === "sending") {
    showLoader("submit_subscripe");
  } else {
    hideLoader("submit_subscripe");
  }
  return (
    <div className="newsletter-form">
      <input
        type="email"
        ref={(node) => (email = node)}
        id="email"
        name="EMAIL"
        className="subscripe_email"
        placeholder="Enter your email"
        required
      />
      <button
        type="submit"
        className="button"
        id="submit_subscripe"
        onClick={submit}
      >
        Subscribe
      </button>
      {status === "error" && (
        <div className="error" dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === "success" && (
        <div
          className="success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};
