/* Live */
import cookie from "react-cookies";
export const projectTitle = "Fizota";
export const apiUrl = "https://dev-api-mp.fizota.com/api/";
export const mediaUrl = "https://dev-api-mp.fizota.com/media/";
export const mediaUrlDev = "https://dev-api-media.fizota.com/";
export const userapiUrl = "https://dev-api-mp.fizota.com/api/";
export const landingPageapiUrl = "https://dev-api-landing.fizota.com/landing/";
export const apiglobaltixUrl = "https://sg-api.globaltix.com/api/";
export const paymentURL = "https://api-payment.fizota.com/web/v1/";
export const hitpayURL =
  "http://securecheckout.hit-pay.com/payment-request/@test-1/";
export const hitpaydomain = "hit-pay.com";
export const hitpayapiDomain = "hit-pay.com";
export const hitpayJSURL = "https://hit-pay.com/hitpay.js";

export const apiglobaltixMediaUrl =
  "https://sg-api.globaltix.com/api/image?name=";

export const siteUrl = "https://dev.fizota.com/";

export const CountryTxt = "Singapore";
export const stripeKey =
  "pk_live_51LA3onLlnzjgyZHdi8WTbBbMqPdxj7q23Zs4pPedtDXU37Qw5CHUGSZgAudHyIUiz2vahmopXoPhdQBy1FHyMMmn00gVzdncTd";
export const stripeEmail = "praba9717@gmail.com";
export const stripeReference = "airvays";
export const stripeCurrency = "SGD";
export const stripe_envir = "dev";
export const currencySymbol = "$";
export const loginuser = "reseller@globaltix.com";
export const loginpwd = "12345";
export const taxpercentage = 7;
export const fbAppId = "1209725599851466";
export const googleAppId =
  "136454752410-up14hfguvbea93vsmpk7bd74ben0d8vl.apps.googleusercontent.com";
export const googleAppIduser =
  "136454752410-8lvm7dgb5n2fsc4mk4j5i1p5lrnp5v47.apps.googleusercontent.com";

export const googleMapsKey = "AIzaSyBqfCCQ1_7XlYf1tURpG7ZCGqAtbOiQEwo";

export const omiseKey = "pkey_test_5uhvog4kbqr7l0rzjh2";
export const omise_envir = "test";

export const headerconfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const fizoheader = {
  headers: {
    Authorization: cookie.load("acccesstoken"),
  },
};

export const fizoheader_formData = {
  headers: {
    Authorization: cookie.load("acccesstoken"),
    "Content-Type": "multipart/form-data",
  },
};

export const fizoheader_urlencoded = {
  headers: {
    Authorization: cookie.load("acccesstoken"),
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const cookieConfig = {
  path: "/",
  secure: true,
  httpOnly: false,
};

/* subscription API URL */
export const subApiurl = "https://api-cp.fizota.com/web/v1/";
export const authUser = "fizota";
export const authPassword = "Dfa6D@t6i9TJgV";

// FLIGHT API
export const fligUrl = "https://api-flight.airvays.com/web/v1/";

export const UopUrl = "https://dev-api-uop.fizota.com/uop";

/* HELP CENTER */
export const HelpApiUrl = "https://dev-api-cp.fizota.com/web/v1/post/";

/* PROD URL */
export const PDFApiUrl = "https://dev-api-eticket.fizota.com";

export const companyName = "Fizota";

export const creatorUi = "https://dev-creator.fizota.com/";
