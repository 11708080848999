import React, { useEffect, useState } from "react";
import "./TransferSelection.scss";
import TransferCard from "../TransferCard/TransferCard";
import { showPriceValue } from "../../../Helpers/SettingHelper";
import CloseIcon from "../../../../common/images/close-icon-side-sheet.svg";
import ContentLoader from "react-content-loader";
import SortIcon from "../../../../common/images/sort.svg";
import ChevronIcon from "../../../../common/images/chevron-down.svg";

export const TransferSelection = (tProps) => {
  const {
    transferlist,
    state,
    setState,
    addTransfer,
    loadRatingstar,
    handleCloseSheet,
    getCountByRating,
    filterByRating,
    handleFilterChange,
    clearAllFilters
  } = tProps;

  const [selectedFilter, setSelectedFilter] = useState(null);
  const [openSortBy, setOpenSortBy] = useState(false);

  const handleSelectedFilter = (val) => {
    setSelectedFilter((prev) => (prev === val ? null : val));
  };

  const handleSortBy = (val) => {
    setState({ sortBy: val });
    setOpenSortBy(false);
  };

  return (
    <div className="transfer-selection-main">
      <div className="transfer-selection-main__header">
        <div className="transfer-selection-main__header__col1">
          <div className="transfer-selection-main__header__col1__title">
            More Transfer options
          </div>
          <div className="transfer-selection-main__header__col1__sub">
            Select from available transfer options{" "}
          </div>
        </div>
        <div className="transfer-selection-main__header__col2">
          <div>
            <div className="transfer-selection-main__header__col2__price">
              Transfer{" "}
              <span>
                {showPriceValue(
                  state.totalPrice,
                  state.currentCurrency,
                  state.currencyRate
                )}
              </span>
            </div>
            <div className="transfer-selection-main__header__col2__breakDown">
              Total price breakdown{" "}
              {showPriceValue(
                state.totalPrice,
                state.currentCurrency,
                state.currencyRate
              )}
            </div>
          </div>
          <div>
            <img
              src={CloseIcon}
              alt="back-icon"
              className="transfer-selection-main__header__col2__close"
              onClick={handleCloseSheet}
            />
          </div>
        </div>
      </div>
      <div className="transfer-selection-main__filterRow">
        <div className="transfer-selection-main__filterRow__col">
          <div
            className={`transfer-selection-main__filterRow__col__filter ${
              selectedFilter === "types" &&
              "transfer-selection-main__filterRow__col__active"
            }`}
            onClick={() => handleSelectedFilter("types")}
          >
            Car Types
          </div>
          <div
            className={`transfer-selection-main__filterRow__col__filter ${
              selectedFilter === "supplier" &&
              "transfer-selection-main__filterRow__col__active"
            }`}
            onClick={() => handleSelectedFilter("supplier")}
          >
            Supplier
          </div>
          <div
            className={`transfer-selection-main__filterRow__col__filter ${
              selectedFilter === "rating" &&
              "transfer-selection-main__filterRow__col__active"
            }`}
            onClick={() => handleSelectedFilter("rating")}
          >
            Rating
          </div>
        </div>
        <div className="transfer-selection-main__filterRow__col1">
          <div
            className="transfer-selection-main__filterRow__col1__sort-by"
            onClick={() => setOpenSortBy(!openSortBy)}
          >
            {state?.isMobile ? (
              <img src={SortIcon} alt="Sort icon" />
            ) : (
              <>
                <p>
                  {state?.sortBy
                    ? state?.sortBy === "LH"
                      ? "Price low to high"
                      : "Price high to low"
                    : "Sort by"}
                </p>
                <img
                  className={openSortBy ? "open-sevron" : ""}
                  src={ChevronIcon}
                  alt="Chevron icon"
                />
              </>
            )}
          </div>
          {openSortBy && (
            <ul className="transfer-selection-main__filterRow__col1__menu-item">
              <li onClick={() => handleSortBy("LH")}>Price low to high</li>
              <li onClick={() => handleSortBy("HL")}>Price high to low</li>
            </ul>
          )}
        </div>
      </div>
      <div
        className={`${
          selectedFilter !== null && "transfer-selection-main__filterList"
        }`}
      >
        {selectedFilter === "types" &&
          state.transferFilter.map((item, index) => (
            <div
              key={index}
              className={`transfer-selection-main__filterList__option ${
                state.carTypeList?.includes(item) &&
                "transfer-selection-main__filterList__active"
              }`}
              onClick={() => handleFilterChange("carTypes", item)}
            >
              {item}
            </div>
          ))}
        {selectedFilter === "supplier" &&
          state.Suppliers.map((item, index) => (
            <div
              key={index}
              className={`transfer-selection-main__filterList__option ${
                state.suppliersList?.includes(item) &&
                "transfer-selection-main__filterList__active"
              }`}
              onClick={() => handleFilterChange("suppliers", item)}
            >
              {item}
            </div>
          ))}
        {selectedFilter === "rating" && (
          <>
            <div
              className={`transfer-selection-main__filterList__option ${
                state.selectedrating === "5" &&
                "transfer-selection-main__filterList__active"
              }`}
              onClick={() => filterByRating("5")}
            >
              5 Excellent ({getCountByRating("5")})
            </div>
            <div
              className={`transfer-selection-main__filterList__option ${
                state.selectedrating === "4" &&
                "transfer-selection-main__filterList__active"
              }`}
              onClick={() => filterByRating("4")}
            >
              4 Good ({getCountByRating("4")})
            </div>
            <div
              className={`transfer-selection-main__filterList__option ${
                state.selectedrating === "3" &&
                "transfer-selection-main__filterList__active"
              }`}
              onClick={() => filterByRating("3")}
            >
              3 Good ({getCountByRating("3")})
            </div>
            <div
              className={`transfer-selection-main__filterList__option ${
                state.selectedrating === "2" &&
                "transfer-selection-main__filterList__active"
              }`}
              onClick={() => filterByRating("2")}
            >
              2 Poor ({getCountByRating("2")})
            </div>
            <div
              className={`transfer-selection-main__filterList__option ${
                state.selectedrating === "1" &&
                "transfer-selection-main__filterList__active"
              }`}
              onClick={() => filterByRating("1")}
            >
              1 Terrible ({getCountByRating("1")})
            </div>
            <div
              className={`transfer-selection-main__filterList__option ${
                state.selectedrating === "0" &&
                "transfer-selection-main__filterList__active"
              }`}
              onClick={() => filterByRating("0")}
            >
              {" "}
              0 Rating ({getCountByRating("0")})
            </div>
          </>
        )}
      </div>
      <div className="transfer-selection-main__searchReslt">
        {state.suppliersList?.length ||
        state.carTypeList?.length ||
        state.selectedrating?.length ? (
          <div className="transfer-selection-main__searchReslt__txt">
            Search results for{" "}
            <span>
              {state.suppliersList?.join(", ")}
              {state.carTypeList?.join(", ")}
              {state.selectedrating ? `${state.selectedrating} Rating` : ""}
            </span>
            <span className="clearall" onClick={() => clearAllFilters()}>
              Clear all
            </span>
          </div>
        ) : null}
        {state.loadingTransfer === false && (
          <h4>
            {transferlist?.length} of {state.transferList.length} transport
          </h4>
        )}
      </div>

      {state.loadingTransfer === true && (
        <div className="hotel-search-result">
          <ul>
            {Array(1, 2, 3).map((item) => {
              return (
                <li key={item}>
                  <ContentLoader
                    height={state.isMobile ? 70 : 140}
                    speed={1}
                    viewBox="0 0 380 70"
                  >
                    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                    <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                  </ContentLoader>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {transferlist?.length && state.loadingTransfer === false ? (
        <ul className="flag-stay-transfer">
          {transferlist?.map((item, index) => (
            <TransferCard
              key={index}
              index={index}
              item={item}
              state={state}
              addTransfer={addTransfer}
              loadRatingstar={loadRatingstar}
            />
          ))}
        </ul>
      ) : state.loadingTransfer === false ? (
        <div className="transfer-selection-main__noData">No results found</div>
      ) : null}
    </div>
  );
};
