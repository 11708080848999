/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_MYSTIFLYCHEAPESTREVALIDATE_RETURN, SET_MYSTIFLYCHEAPESTREVALIDATE_RETURN } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetMystiflyCheapestRevalidateReturn = function* () {
  yield takeEvery(
    GET_MYSTIFLYCHEAPESTREVALIDATE_RETURN,
    workerGetMystiflyCheapestRevalidateReturn
  );
};

function* workerGetMystiflyCheapestRevalidateReturn({ formPayload }) {
  try {
    if (formPayload?.resetData === "Yes") {
      var resultArr = [];
      resultArr.push();
    } else {
      const result = yield call(
        getMystiflyCheapestRevalidateReturn,
        formPayload
      );
      var resultArr = [];
      resultArr.push(result.data);
    }
    yield put({
      type: SET_MYSTIFLYCHEAPESTREVALIDATE_RETURN,
      value: resultArr,
    });
  } catch {
    console.log("login failed");
  }
}

function getMystiflyCheapestRevalidateReturn(formPayload) {
  return Axios.post(
    apiUrl + "package/revalidatePrice",
    formPayload,
    {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      },
    }
  );
}
