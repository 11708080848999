import { Box, IconButton, Modal, Slide, Fade } from "@mui/material";
import React from "react";
import "./ModalPopup.scss";
import CloseIcon from "@mui/icons-material/Close";
// import { useHistory } from "react-router-dom";
import "./ModalPopup.scss";
// import { Height } from "@mui/icons-material";
var isMobile = window.innerWidth <= 480;
const Transition = React.forwardRef(function Transition(props, ref) {
  return isMobile ? (
    <Slide direction="up" ref={ref} {...props} />
  ) : (
    <Fade ref={ref} {...props} />
  );
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "100%",
  overflowY: "scroll",
};

const ModalPopup = (props) => {
  const { open, handleClose, children, classNames } = props;

  // const history = useHistory();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Transition in={open} timeout={300}>
        <Box sx={style} className={`modal-popup-main`}>
          <div className={`${classNames}`}>
            <IconButton onClick={handleClose} className="close-btn">
              <CloseIcon htmlColor="#4258BF" />
            </IconButton>
            {children}
          </div>
        </Box>
      </Transition>
    </Modal>
  );
};

export default ModalPopup;
