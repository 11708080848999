/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_WISHLIST, SET_WISHLIST } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
export const watchGetWishList = function* () {
  yield takeEvery(GET_WISHLIST, workerGetWishList);
};

function* workerGetWishList(datas) {
  try {
    const uri = apiUrl + "/package/getallpackages?" + datas.params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_WISHLIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get wish list Failed");
  }
}
