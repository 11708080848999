/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_FAVOURITE, SET_FAVOURITE } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetFavourite = function* () {
  yield takeEvery(GET_FAVOURITE, workerGetFavourite);
};

function* workerGetFavourite({ formPayload }) {
  try {
    const result = yield call(getFavourite, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FAVOURITE, value: resultArr });
  } catch {
    console.log("Get Favourite Add Failed");
  }
}

function getFavourite(formPayload) {
  return Axios.post(
    apiUrl + "products/addTofavouriteProducts",
    formPayload,
    {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      },
    }
  );
}
