import React, { useState } from "react";
import Customerattractiondetails from "../../../Customerattractions/Productdetail";
import { showPriceValue } from "../../../Helpers/SettingHelper";
import "./ActivityDetail.scss";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

const ActivityDetail = (aProps) => {
  const { props, state, passengerSelection, product_Details } = aProps;
  const [collapsedItems, setCollapsedItems] = useState([]);

  const maxVisibleSlots = { state };

  const handleCollapseAction = (index) => {
    if (collapsedItems?.includes(index)) {
      setCollapsedItems((prev) => prev.filter((ind) => ind !== index));
    } else {
      setCollapsedItems((prev) => [...prev, index]);
    }
  };

  return (
    <div className="activity-detail-main">
      <div className="activity-detail-main__pkMain">
        {state.productdetail?.product_package?.length > 0 && (
          <>
            {state.productdetail.product_package.map(
              (activityItem, activityIndex) => {
                var totalPrice = 0;
                var startingPrice_ = [];
                if (activityItem.package_price.length > 0) {
                  activityItem.package_price.map((ticketItem) => {
                    if (
                      ticketItem.quantity !== "" &&
                      typeof ticketItem.quantity !== undefined &&
                      typeof ticketItem.quantity !== "undefined"
                    ) {
                      totalPrice +=
                        parseFloat(ticketItem.package_pricenettPrice) *
                        parseInt(ticketItem.quantity);
                    }
                    if (parseFloat(ticketItem.package_pricenettPrice) > 0) {
                      startingPrice_.push(
                        parseFloat(ticketItem.package_pricenettPrice)
                      );
                    }
                    return "";
                  });
                }
                var startingPrice =
                  startingPrice_.length > 0
                    ? Math.min(startingPrice_)
                    : state.productdetail.statring_price;
                return (
                  <div style={{ position: "relative" }}>
                    <div
                      key={activityIndex}
                      className={
                        state.packActivityList.includes(
                          activityItem.package_name
                        ) ||
                        activityItem?.dateAvailability?.availability === "No"
                          ? "disabled-link activity-detail-main__pkMain__card"
                          : "things-to-do-error activity-detail-main__pkMain__card"
                      }
                    >
                      {collapsedItems.includes(activityIndex) ? (
                        <KeyboardArrowDownIcon
                          className="activity-detail-main__pkMain__card__icon"
                          onClick={() => handleCollapseAction(activityIndex)}
                        />
                      ) : (
                        <ExpandLessIcon
                          className="activity-detail-main__pkMain__card__icon"
                          onClick={() => handleCollapseAction(activityIndex)}
                        />
                      )}
                      <div className="ttd-p-lhs">
                        <h4>{activityItem.package_name}</h4>

                        <div className="vist-info">
                          <ul>
                            {state.isDateSelected &&
                              state.selectedPackageId ===
                                activityItem.package_id && (
                                <li className="vist-date">
                                  Visit: {state.selectedDate}
                                </li>
                              )}
                            {activityItem.package_confirmation === "1" && (
                              <li className="aftr-req">
                                After confirmation required
                              </li>
                            )}
                            <li className="vw-more">
                              <a
                                href={void 0}
                                onClick={() =>
                                  product_Details(
                                    state.productdetail,
                                    activityItem
                                  )
                                }
                              >
                                <span>View more info..</span>
                              </a>
                            </li>
                          </ul>
                        </div>

                        {
                          <div className="tit-info">
                            {!state.ttdOnly && (
                              <>
                                {activityItem.dateAvailability.slotList.length >
                                  0 && (
                                  <>
                                    <span>Select time slot</span>
                                    <div className="vi-cover">
                                      {activityItem.dateAvailability.slotList
                                        .slice(0, maxVisibleSlots)
                                        .map((slotItem, slotIndex) => {
                                          var selectedSlot = false;
                                          if (
                                            activityItem.selected_slot !== "" &&
                                            typeof activityItem.selected_slot !==
                                              undefined &&
                                            typeof activityItem.selected_slot !==
                                              "undefined"
                                          ) {
                                            selectedSlot =
                                              activityItem.selected_slot[0]
                                                .slot_globaltix_id ===
                                              slotItem.slot_globaltix_id
                                                ? true
                                                : false;
                                          }
                                          return (
                                            <a
                                              key={slotIndex}
                                              href={void 0}
                                              className={
                                                selectedSlot === true
                                                  ? "active"
                                                  : ""
                                              }
                                              //   onClick={this.selectTimeSlot.bind(
                                              //     this,
                                              //     this.state.productdetail,
                                              //     activityIndex,
                                              //     slotItem
                                              //   )}
                                            >
                                              {slotItem.slot_time_format}
                                            </a>
                                          );
                                        })}
                                    </div>
                                    {activityItem.dateAvailability.slotList
                                      .length > 5 && (
                                      <a
                                        href={void 0}
                                        // onClick={this.toggleMaxVisibleSlots}
                                      >
                                        {maxVisibleSlots === 5
                                          ? "View More"
                                          : "View Less"}
                                      </a>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        }
                      </div>
                      {passengerSelection(
                        activityItem,
                        activityIndex,
                        !state.ttdOnly,
                        true,
                        !collapsedItems?.includes(activityIndex)
                      )}
                      {!collapsedItems?.includes(activityIndex) && (
                        <div className="ttd-total-pack">
                          {totalPrice <= 0 &&
                          state.selectedPackageId !==
                            activityItem.package_id ? (
                            <>
                              <strong>
                                Starting Price{" "}
                                <span>
                                  {showPriceValue(
                                    startingPrice,
                                    state.currentCurrency,
                                    state.currencyRate
                                  )}
                                </span>
                              </strong>{" "}
                            </>
                          ) : (
                            <strong>
                              Total{" "}
                              <span>
                                {showPriceValue(
                                  totalPrice,
                                  state.currentCurrency,
                                  state.currencyRate
                                )}
                              </span>
                            </strong>
                          )}
                          <p>
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                            ></i>
                            Please read the package information before booking.
                          </p>
                        </div>
                      )}
                    </div>
                    {activityItem?.dateAvailability?.availability === "No" && (
                      <div className="activity-detail-main__pkMain__card__notAvailable">
                        Ohh not Available
                      </div>
                    )}
                    {state.packActivityList.includes(
                      activityItem.package_name
                    ) && (
                      <div className="activity-detail-main__pkMain__card__selected">
                        Already Selected
                      </div>
                    )}
                  </div>
                );
              }
            )}
            {state.productdetail?.product_package?.length === 0 && (
              <div className="activity-detail-main__pkMain__noData">
                No packages available
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ActivityDetail;
