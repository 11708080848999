import React, { useEffect, useState } from "react";
import "./ActivitySelection.scss";
import { showPriceValue } from "../../../Helpers/SettingHelper";
import CloseIcon from "../../../../common/images/close-icon-side-sheet.svg";
import { apiglobaltixMediaUrl, apiUrl } from "../../../Helpers/Config";
import { pplaceholder } from "../../../Helpers/Images";
import locationIcon from "../../../../common/images/location.svg";
import { Button, Slider } from "@mui/material";
import Customerattractiondetails from "../../../Customerattractions/Productdetail";
import LeftIcon from "../../../../common/images/chevron-left.svg";
import SortIcon from "../../../../common/images/sort.svg";
import ChevronIcon from "../../../../common/images/chevron-down.svg";
import useDebounce from "../../../../common/components/useDebounce";
import Axios from "axios";
import cookie from "react-cookies";
import InfiniteScroll from "react-infinite-scroll-component";

function valuetext(value) {
  return `SGD ${value}`;
}

const ActivitySelection = (tProps) => {
  const {
    state,
    props,
    handleCloseSheet,
    filterByCategory,
    applyPriceSort,
    handlePriceChange,
    handleTextChange,
    selectProduct,
    setState,
    displaySearch,
    loadMoreProduct,
    clearAllFilters,
  } = tProps;
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [openSortBy, setOpenSortBy] = useState(false);
  const [searchQry, setSearchQry] = useState("");
  const debounceSearchInput = useDebounce(searchQry, 300);

  useEffect(() => {
    if (state?.viewActivity) setSearchQry("");
  }, [state?.viewActivity]);

  useEffect(() => {
    const fetchData = () => {
      if (debounceSearchInput?.trim() === "") {
        return;
      } else {
        Axios.get(
          apiUrl +
            "products/search?keywords=" +
            debounceSearchInput +
            "&country=" +
            state.country,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            },
          }
        ).then((res) => {
          if (res.data.status === "ok") {
            if (
              res?.data?.result_set?.country.length > 0 ||
              res?.data?.result_set?.product_list.length > 0
            ) {
              displaySearch(res?.data?.result_set, debounceSearchInput);
            } else {
              setState({
                displaySearchCountry: "",
                displaySearchProducts: "",
                showsearchList: false,
                search_keyword: debounceSearchInput,
              });
            }
          }
        });
      }
    };
    fetchData();
  }, [debounceSearchInput]);

  const handleClearSearch = () => {
    setSearchQry("");
    handleTextChange({ target: { value: "" } });
  };

  const handleSelectedFilter = (val) => {
    setSelectedFilter((prev) => (prev === val ? null : val));
  };

  const handleSortBy = (val) => {
    applyPriceSort(val);
    setOpenSortBy(false);
  };

  const handleChange = (event, newValue) => {
    handlePriceChange(newValue[0], newValue[1]);
  };

  const fetchData = () => {
    if (state.page === props.totalPages) return;
    loadMoreProduct(state.page + 1);
  };

  return (
    <div className="activity-selection-main" id={"scrollableDiv"}>
      <div className="activity-selection-main__header">
        <div className="activity-selection-main__header__row">
          {state?.viewActivity && (
            <div className="activity-selection-main__header__row__col">
              <img
                src={LeftIcon}
                onClick={() => setState({ viewActivity: false })}
              />
            </div>
          )}
          <div className="activity-selection-main__header__row__col1">
            <div className="activity-selection-main__header__row__col1__title">
              Add Things to do{" "}
            </div>
            <div className="activity-selection-main__header__row__col1__sub">
              Select the stay from available options{" "}
            </div>
          </div>
        </div>
        <div className="activity-selection-main__header__col2">
          <div>
            <div className="activity-selection-main__header__col2__price">
              TTD{" "}
              <span>
                {showPriceValue(
                  state.totalActvitiesPrice,
                  state.currentCurrency,
                  state.currencyRate
                )}
              </span>
            </div>
            <div className="activity-selection-main__header__col2__breakDown">
              {parseInt(state.adultsQty) > 0 && (
                <>
                  Adult{" "}
                  {showPriceValue(
                    state.adultPrice,
                    state.currentCurrency,
                    state.currencyRate
                  )}{" "}
                  x {state.adultsQty}
                </>
              )}
              {parseInt(state.childQty) > 0 && (
                <>
                  Child{" "}
                  {showPriceValue(
                    state.childPrice,
                    state.currentCurrency,
                    state.currencyRate
                  )}{" "}
                  x {state.childQty}
                </>
              )}
            </div>
          </div>
          <div>
            <img
              src={CloseIcon}
              alt="back-icon"
              className="activity-selection-main__header__col2__close"
              onClick={handleCloseSheet}
            />
          </div>
        </div>
      </div>
      {!state?.viewActivity ? (
        <InfiniteScroll
          dataLength={state.productlist.length ? state.productlist.length : 0}
          next={() => fetchData()}
          scrollableTarget="scrollableDiv"
          hasMore={state?.productlist?.length !== props.totalRecord?.length}
          loader=""
        >
          <div className="activity-selection-main__search">
            <input
              className="activity-selection-main__search__box"
              placeholder="Search for ‘Singapore Universal studio’"
              value={searchQry}
              onChange={(ev) => setSearchQry(ev.target.value)}
            />
            {searchQry !== "" && (
              <img
                src={CloseIcon}
                alt="back-icon"
                className="activity-selection-main__search__close"
                onClick={() => handleClearSearch()}
              />
            )}
            {!state.isMobile &&
              state.showsearchList &&
              state.displaySearchProducts.length > 0 && (
                <div
                  // ref={this.dropdownRef}
                  className={
                    state.displaySearchProducts.length > 0
                      ? "search-place active"
                      : "search-place"
                  }
                >
                  <ul>{state.displaySearchProducts}</ul>
                </div>
              )}
          </div>
          <div className="activity-selection-main__filterRow">
            <div className="activity-selection-main__filterRow__col">
              <div
                className={`activity-selection-main__filterRow__col__filter ${
                  selectedFilter === "category" &&
                  "activity-selection-main__filterRow__col__active"
                }`}
                onClick={() => handleSelectedFilter("category")}
              >
                Category
              </div>
              <div
                className={`activity-selection-main__filterRow__col__filter ${
                  selectedFilter === "supplier" &&
                  "activity-selection-main__filterRow__col__active"
                }`}
                onClick={() => handleSelectedFilter("price")}
              >
                Price range
              </div>
            </div>
            <div className="activity-selection-main__filterRow__col1">
              <div
                className="activity-selection-main__filterRow__col1__sort-by"
                onClick={() => setOpenSortBy(!openSortBy)}
              >
                {state?.isMobile ? (
                  <img src={SortIcon} alt="Sort icon" />
                ) : (
                  <>
                    <p>
                      {state?.pricesort
                        ? state?.pricesort === "LH"
                          ? "Price low to high"
                          : "Price high to low"
                        : "Sort by"}
                    </p>
                    <img
                      className={openSortBy ? "open-sevron" : ""}
                      src={ChevronIcon}
                      alt="Chevron icon"
                    />
                  </>
                )}
              </div>
              {openSortBy && (
                <ul className="activity-selection-main__filterRow__col1__menu-item">
                  <li onClick={() => handleSortBy("LH")}>Price low to high</li>
                  <li onClick={() => handleSortBy("HL")}>Price high to low</li>
                </ul>
              )}
            </div>
          </div>
          <div
            className={`${
              selectedFilter !== null && "activity-selection-main__filterList"
            }`}
          >
            {selectedFilter === "category" &&
              state.taglist.map((item, index) => (
                <div
                  key={index}
                  className={`activity-selection-main__filterList__option ${
                    [...state.filterTag, ...state.selectedTags]?.includes(
                      item
                    ) && "activity-selection-main__filterList__active"
                  }`}
                  onClick={() => filterByCategory(item)}
                >
                  {item}
                </div>
              ))}
            {selectedFilter === "price" && (
              <div className="activity-selection-main__filterList__priceRange">
                <div className="activity-selection-main__filterList__priceRange__col">
                  {showPriceValue(
                    Math.ceil(state.startPrice || 0),
                    state.currentCurrency,
                    state.currencyRate
                  )}{" "}
                </div>
                <div className="activity-selection-main__filterList__priceRange__col1">
                  <Slider
                    value={[state.startPrice || 0, state.endPrice || 1000]}
                    onChange={handleChange}
                    min={0}
                    max={1000}
                    style={{ color: "#4258bf" }}
                  />
                </div>
                <div className="activity-selection-main__filterList__priceRange__col2">
                  {showPriceValue(
                    Math.ceil(state.endPrice || 1000),
                    state.currentCurrency,
                    state.currencyRate
                  )}
                  +
                </div>
              </div>
            )}
          </div>
          <div className="activity-selection-main__searchReslt">
            {[...state.filterTag, ...state.selectedTags]?.length ? (
              <div className="activity-selection-main__searchReslt__txt">
                Search results for{" "}
                <span>
                  {[...state.filterTag, ...state.selectedTags]?.join(", ")}
                </span>
                <span className="clearall" onClick={() => clearAllFilters()}>
                  Clear all
                </span>
              </div>
            ) : null}
            <h4>{props.totalRecord} things to do</h4>
          </div>
          <div className="activity-selection-main__content">
            {state.productlist.length > 0 &&
              state.productlist.map((item, index) => {
                return (
                  <div
                    className="activity-selection-main__content__card"
                    onClick={() => {
                      selectProduct(item);
                    }}
                  >
                    <div className="activity-selection-main__content__card__lhs">
                      <img
                        src={
                          item.product_thumbnail
                            ? item.product_thumbnail.includes("https")
                              ? item.product_thumbnail
                              : apiglobaltixMediaUrl + item.product_thumbnail
                            : pplaceholder
                        }
                        alt={item.products_name}
                      />
                    </div>
                    <div className="activity-selection-main__content__card__rhs">
                      {item.product_category_name !== "" &&
                        item.product_category_name !== null && (
                          <div className="activity-selection-main__content__card__rhs__tag">
                            <span>{item.product_category_name}</span>
                          </div>
                        )}
                      <div className="activity-selection-main__content__card__rhs__title">
                        {item.products_name}
                      </div>
                      {item.distance !== "" && (
                        <div className="activity-selection-main__content__card__rhs__distance">
                          <img src={locationIcon} />
                          Distance from stay <div>{item.distance}</div>
                        </div>
                      )}
                      <div className="activity-selection-main__content__card__rhs__footer">
                        <div className="activity-selection-main__content__card__rhs__footer__priceMain">
                          <em>Starts from</em>
                          <div className="activity-selection-main__content__card__rhs__footer__priceMain__price">
                            {showPriceValue(
                              item.product_payable_amount,
                              state.currentCurrency,
                              state.currencyRate
                            )}
                          </div>
                        </div>
                        <Button
                          variant="contained"
                          onClick={() => {
                            selectProduct(item);
                          }}
                        >
                          Select
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            {state.productlist?.length === 0 && (
              <div className="activity-selection-main__content__noData">
                No packages available
              </div>
            )}
          </div>
        </InfiniteScroll>
      ) : (
        <Customerattractiondetails
          {...props}
          drawerOpened={true}
          match={{
            params: {
              packageSlug: props.match.params.packageSlug,
              productslug: state?.selectedProd?.product_slug,
              country: state.searchdata?.destinationCountry?.toLowerCase(),
            },
          }}
          handleCloseSheet={handleCloseSheet}
        />
      )}
    </div>
  );
};

export default ActivitySelection;
