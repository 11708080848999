/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_SEARCHFLIGHT_RETURN, SET_SEARCHFLIGHT_RETURN } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import { showAlert } from "../components/Helpers/SettingHelper";
export const watchGetSearchFlightReturn = function* () {
  yield takeEvery(GET_SEARCHFLIGHT_RETURN, workerGetSearchFlightReturn);
};

function* workerGetSearchFlightReturn({ formPayload }) {
  try {
    if (formPayload?.resetData === "Yes") {
      var resultArr = [];
      resultArr.push();
    } else {
      const result = yield call(getSearchFlightReturn, formPayload);
      var resultArr = [];
      resultArr.push(result.data);
    }
    yield put({ type: SET_SEARCHFLIGHT_RETURN, value: resultArr });
  } catch {
    showAlert("Error", "Something went wrong, please try again later");
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
    console.log("login failed");
  }
}

function getSearchFlightReturn(formPayload) {
  return Axios.post(apiUrl + "package/searchflight", formPayload, {
    headers: {
      Authorization: cookie.load("acccesstoken"),
    },
  });
}
