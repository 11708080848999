import React from "react";
import "./TransferListSheet.scss";
import { Drawer } from "@mui/material";
import UsertranferSelection from "../../UsertranferSelection";
import CloseIcon from "../../../../common/images/close-icon-side-sheet.svg";
import { showPriceValue } from "../../../Helpers/SettingHelper";

const TransferListSheet = (tProps) => {
  const { open, handleClose, state, bookingDetails, props, refreshDetails } =
    tProps;

  const handleCloseSheet = (isClose) => {
    !isClose && refreshDetails();
    handleClose();
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        handleCloseSheet(true);
      }}
      PaperProps={{
        sx: {
          width: state.isMobile ? "100%" : "50%",
        },
      }}
    >
      <UsertranferSelection
        {...props}
        drawerOpened={true}
        match={{
          params: { packageSlug: state.packageSlug },
        }}
        handleCloseSheet={handleCloseSheet}
        // location={{ state: bookingDetails }}
      />
    </Drawer>
  );
};

export default TransferListSheet;
