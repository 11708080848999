/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { apiUrl, apiglobaltixUrl } from "../Helpers/Config";
import { jsUcfirstFun } from "../Helpers/SettingHelper";
import { pplaceholder, successtick, star } from "../Helpers/Images";
import { GET_BOOKINGHISTORY } from "../../actions";
var settingsBanner = {
  items: 4,
  loop: true,
  dots: false,
  nav: false,
  margin: 30,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    900: {
      items: 3,
    },
    1300: {
      items: 4,
    },
  },
};
var qs = require("qs");
class Thankyou extends Component {
  constructor(props) {
    super(props);
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    var booking_reference = "";
    if (
      this.props.match.params.bookingID !== "" &&
      typeof this.props.match.params.bookingID !== undefined &&
      typeof this.props.match.params.bookingID !== "undefined"
    ) {
      booking_reference = this.props.match.params.bookingID;
    }

    this.state = {
      UserId: UserId,
      booking_reference: booking_reference,
      displaybooking: "",
      setgoogleTag: false,
    };
    if (UserId == "" || UserId == undefined) {
      props.history.push("/");
    } else {
      var params = "page=1";
      if (UserId !== "") {
        params +=
          "&customer_id=" +
          UserId +
          "&type=PUBLISHED&booking_reference=" +
          booking_reference;
      }
      this.props.getBookingHistory(params);
      this.setBookingEmail(booking_reference);
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(PropsData) {
    if (PropsData.bookinglist !== this.state.bookinglist) {
      this.setState({ bookinglist: PropsData.bookinglist }, function () {
        this.displayBookingList();
        var currentThis = this;
        if (this.state.setgoogleTag === false) {
          this.setState({ setgoogleTag: true }, function () {
            window.addEventListener("load", function () {
              if (window.location.href.indexOf("/thankyou") != -1) {
                console.log("success google");
                gtag("event", "conversion", {
                  send_to: "AW-337213767/tN6OCKTQyNgDEMfy5aAB",
                  value: currentThis.state.bookinglist[0].booking_total_amount,
                  currency: "SGD",
                  transaction_id: currentThis.state.booking_reference,
                });
              }
            });
          });
        }
      });
    }
  }
  setBookingEmail(booking_reference) {
    axios.get(
      apiUrl +
        "booking/sendbookingmail?customer_id=" +
        this.state.UserId +
        "&booking_reference=" +
        booking_reference,
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        }
    );

    var postObject = {};
    postObject = {
      customer_id: cookie.load("UserId"),
    };
    axios
      .post(apiUrl + "booking/removeCart", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {});
  }
  displayBookingList() {
    cookie.remove("booking_data", { path: "/" });
    cookie.remove("promodata", { path: "/" });
    localStorage.removeItem("booking_data");
    var displaybooking = "";
    if (this.state.bookinglist.length > 0) {
      displaybooking = this.state.bookinglist.map((item, index) => {
        return (
          <div className="thankyou-box" key={index}>
            <h5>
              Booking <span>Summary</span>
            </h5>
            <div className="thankyou-box-inner">
              <div className="thankyou-box-inner-lhs">
                <div className="tbi-lhs-header">
                  <h6>{item.products_name}</h6>
                  <p>Package : {item.package_name}</p>
                </div>
                <ul>
                  <li>
                    <span>Booking ref ID </span>
                    <strong>{item.booking_reference_number}</strong>
                  </li>
                  <li>
                    <span>Date</span>
                    <strong>{item.redeem_end}</strong>
                  </li>
                  <li>
                    <span>No of tickets </span>
                    <strong>
                      {item.tickets.length > 0 &&
                        item.tickets.map((item1, index) => {
                          return (
                            <>
                              {item1.quantity} X {jsUcfirstFun(item1.name)}
                              {index != item.tickets.length - 1 ? "," : ""}{" "}
                            </>
                          );
                        })}
                    </strong>
                  </li>
                  {/* <li>
                    <span>Payment ref ID </span>
                    <strong>MDF7656458</strong>
                  </li> */}
                </ul>
              </div>
              <div className="thankyou-box-inner-rhs">
                <figure>
                  <img
                    src={
                      item.product_thumbnail !== "" &&
                      item.product_thumbnail !== null
                        ? apiglobaltixUrl +
                          "image?name=" +
                          item.product_thumbnail
                        : pplaceholder
                    }
                    alt="airvayslogo"
                  />
                </figure>
              </div>
            </div>
            <div className="thankyou-box-btm-btn">
              <div className="thankyou-box-btm-lhs">
                <span>Total paid </span>
                <strong>SGD ${item.booking_total_amount}</strong>
              </div>
              <div className="thankyou-box-btm-rhs">
                <a
                  href={
                    item.eTicketUrl !== "" && item.eTicketUrl !== null
                      ? item.eTicketUrl
                      : ""
                  }
                  target={
                    item.eTicketUrl !== "" && item.eTicketUrl !== null
                      ? "_blank"
                      : ""
                  }
                  className="button"
                >
                  Download ticket
                </a>
                <Link to={"/mybooking"} className="button ghost-button">
                  Manage booking
                </Link>
              </div>
            </div>
          </div>
        );
      });
    }
    this.setState({ displaybooking: displaybooking });
  }
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };

  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <section className="innersection">
          <div className="container">
            <div className="thankyou-top">
              <div className="thankyou-header">
                <img
                  src={successtick}
                  alt="successtick"
                  className="successtick"
                />
                <h3>
                  Your booking is <span>confirmed !</span>{" "}
                </h3>
                <p>Please check your email for the ticket.</p>
              </div>
              {this.state.displaybooking}

              <div className="thankyou-boxbtm">
                You can download the ticket from your given email. Present the
                QR code/bar code /booking reference number at the time of
                entering the attraction/activities premises. For more
                information download the ticket and check terms and conditions.
              </div>
            </div>

            <div className="expolre-thank">
              <h4 className="textcenter">
                Explore <span>more</span>
              </h4>
              <OwlCarousel options={settingsBanner}>
                <div>
                  <div className="product-img">
                    <a href="#">
                      <img src={pplaceholder} alt="placeholder" />
                    </a>
                  </div>
                  <div className="product-description">
                    <div className="product-description-title">
                      <h5>
                        <a href="#">Gardens by the bay tickets</a>{" "}
                      </h5>
                    </div>
                    <div className="product-description-tag">
                      <span>Families</span>
                      <span>Attractions</span>
                    </div>
                    <div className="product-description-review">
                      <div className="pdr-lhs">
                        <span>
                          <strong>4.5</strong> (1282) Reviews
                        </span>
                        <img src={star} alt="star" />
                      </div>
                      <div className="pdr-rhs">
                        <span>From</span>
                        <strong>SGD $10</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="product-img">
                    <a href="#">
                      <img src={pplaceholder} alt="placeholder" />
                    </a>
                  </div>
                  <div className="product-description">
                    <div className="product-description-title">
                      <h5>
                        <a href="#">Gardens by the bay tickets</a>{" "}
                      </h5>
                    </div>
                    <div className="product-description-tag">
                      <span>Families</span>
                      <span>Attractions</span>
                    </div>
                    <div className="product-description-review">
                      <div className="pdr-lhs">
                        <span>
                          <strong>4.5</strong> (1282) Reviews
                        </span>
                        <img src={star} alt="star" />
                      </div>
                      <div className="pdr-rhs">
                        <span>From</span>
                        <strong>SGD $10</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="product-img">
                    <a href="#">
                      <img src={pplaceholder} alt="airvayslogo" />
                    </a>
                  </div>
                  <div className="product-description">
                    <div className="product-description-title">
                      <h5>
                        <a href="#">Gardens by the bay tickets</a>{" "}
                      </h5>
                    </div>
                    <div className="product-description-tag">
                      <span>Families</span>
                      <span>Attractions</span>
                    </div>
                    <div className="product-description-review">
                      <div className="pdr-lhs">
                        <span>
                          <strong>4.5</strong> (1282) Reviews
                        </span>
                        <img src={star} alt="star" />
                      </div>
                      <div className="pdr-rhs">
                        <span>From</span>
                        <strong>SGD $10</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="product-img">
                    <a href="#">
                      <img src={pplaceholder} alt="airvayslogo" />
                    </a>
                  </div>
                  <div className="product-description">
                    <div className="product-description-title">
                      <h5>
                        <a href="#">Gardens by the bay tickets</a>{" "}
                      </h5>
                    </div>
                    <div className="product-description-tag">
                      <span>Families</span>
                      <span>Attractions</span>
                    </div>
                    <div className="product-description-review">
                      <div className="pdr-lhs">
                        <span>
                          <strong>4.5</strong> (1282) Reviews
                        </span>
                        <img src={star} alt="star" />
                      </div>
                      <div className="pdr-rhs">
                        <span>From</span>
                        <strong>SGD $10</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="product-img">
                    <a href="#">
                      <img src={pplaceholder} alt="airvayslogo" />
                    </a>
                  </div>
                  <div className="product-description">
                    <div className="product-description-title">
                      <h5>
                        <a href="#">Gardens by the bay tickets</a>{" "}
                      </h5>
                    </div>
                    <div className="product-description-tag">
                      <span>Families</span>
                      <span>Attractions</span>
                    </div>
                    <div className="product-description-review">
                      <div className="pdr-lhs">
                        <span>
                          <strong>4.5</strong> (1282) Reviews
                        </span>
                        <img src={star} alt="star" />
                      </div>
                      <div className="pdr-rhs">
                        <span>From</span>
                        <strong>SGD $10</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="product-img">
                    <a href="#">
                      <img src={pplaceholder} alt="airvayslogo" />
                    </a>
                  </div>
                  <div className="product-description">
                    <div className="product-description-title">
                      <h5>
                        <a href="#">Gardens by the bay tickets</a>{" "}
                      </h5>
                    </div>
                    <div className="product-description-tag">
                      <span>Families</span>
                      <span>Attractions</span>
                    </div>
                    <div className="product-description-review">
                      <div className="pdr-lhs">
                        <span>
                          <strong>4.5</strong> (1282) Reviews
                        </span>
                        <img src={star} alt="star" />
                      </div>
                      <div className="pdr-rhs">
                        <span>From</span>
                        <strong>SGD $10</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="product-img">
                    <a href="#">
                      <img src={pplaceholder} alt="airvayslogo" />
                    </a>
                  </div>
                  <div className="product-description">
                    <div className="product-description-title">
                      <h5>
                        <a href="#">Gardens by the bay tickets</a>{" "}
                      </h5>
                    </div>
                    <div className="product-description-tag">
                      <span>Families</span>
                      <span>Attractions</span>
                    </div>
                    <div className="product-description-review">
                      <div className="pdr-lhs">
                        <span>
                          <strong>4.5</strong> (1282) Reviews
                        </span>
                        <img src={star} alt="star" />
                      </div>
                      <div className="pdr-rhs">
                        <span>From</span>
                        <strong>SGD $10</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var bookinglistArr = Array();
  if (Object.keys(state.bookinghistory).length > 0) {
    if (state.bookinghistory[0].status === "ok") {
      bookinglistArr = state.bookinghistory[0].result_set;
    }
  }
  return {
    bookinglist: bookinglistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBookingHistory: (params) => {
      dispatch({ type: GET_BOOKINGHISTORY, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Thankyou);
