import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import "./AlertPopup.scss";

const AlertPopup = (props) => {
  const { open, title, message, handleClose } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"xs"}
      className="alert-popup-main"
    >
      <div className="alert-popup-main__title">{title}</div>
      <p>{message}</p>
      <div className="alert-popup-main__action">
        <Button variant="contained" onClick={handleClose} autoFocus>
          Ok
        </Button>
      </div>
    </Dialog>
  );
};

export default AlertPopup;
