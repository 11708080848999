/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_COUNTRY, SET_COUNTRY } from "../actions";
import { apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCountry = function* () {
  yield takeEvery(GET_COUNTRY, workerGetCountry);
};

function* workerGetCountry({ params }) {
  try {
    const uri = apiUrl + "settings/getCountry?" + params;
    const result = yield call(Axios.get, uri, {
      headers: {
        Authorization: cookie.load("acccesstoken"),
      },
    });
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_COUNTRY, value: resultArr });
  } catch (e) {
    console.log(e, "Get Country List Failed");
  }
}
