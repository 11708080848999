/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { GET_CUSTOMER_DETAIL } from "../../actions";
import { Button, Divider } from "@mui/material";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import moment from "moment";
import { showPriceValue } from "../Helpers/SettingHelper";
import { flightright } from "../Helpers/Images";

class Listpromo extends Component {
  constructor(props) {
    super(props);
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") != "undefined"
        ? cookie.load("UserId")
        : "";
    var packageSlug = "";
    if (
      this.props.match.params.packageSlug !== "" &&
      typeof this.props.match.params.packageSlug !== undefined &&
      typeof this.props.match.params.packageSlug !== "undefined"
    ) {
      packageSlug = this.props.match.params.packageSlug;
    } else {
      this.props.history.push("/");
    }

    if (this.props.location.state === undefined) {
      this.props.history.push("/");
    }

    this.state = {
      userID: userID,
      searchdata:
        this.props.location.state.searchdata !== "" &&
        typeof this.props.location.state.searchdata !== undefined &&
        typeof this.props.location.state.searchdata !== "undefined"
          ? this.props.location.state.searchdata
          : [],
      packagedetails:
        this.props.location.state.packagedetails !== "" &&
        typeof this.props.location.state.packagedetails !== undefined &&
        typeof this.props.location.state.packagedetails !== "undefined"
          ? this.props.location.state.packagedetails
          : "",
      flightPrice:
        this.props.location.state.flightPrice !== "" &&
        typeof this.props.location.state.flightPrice !== undefined &&
        typeof this.props.location.state.flightPrice !== "undefined"
          ? this.props.location.state.flightPrice
          : "",
      flightcheapestPrice:
        this.props.location.state.flightcheapestPrice !== "" &&
        typeof this.props.location.state.flightcheapestPrice !== undefined &&
        typeof this.props.location.state.flightcheapestPrice !== "undefined"
          ? this.props.location.state?.flightcheapestPrice
          : "",
      flightPriceType:
        this.props.location.state.flightPriceType !== "" &&
        typeof this.props.location.state.flightPriceType !== undefined &&
        typeof this.props.location.state.flightPriceType !== "undefined"
          ? this.props.location.state.flightPriceType
          : [],
      flightList:
        this.props.location.state.flightList !== "" &&
        typeof this.props.location.state.flightList !== undefined &&
        typeof this.props.location.state.flightList !== "undefined"
          ? this.props.location.state.flightList
          : [],
      selectedflight:
        this.props.location.state.selectedflight !== "" &&
        typeof this.props.location.state.selectedflight !== undefined &&
        typeof this.props.location.state.selectedflight !== "undefined"
          ? this.props.location.state.selectedflight
          : "",
      flightOnly:
        this.props.location.state.flightOnly !== "" &&
        typeof this.props.location.state.flightOnly !== undefined &&
        typeof this.props.location.state.flightOnly !== "undefined"
          ? this.props.location.state.flightOnly
          : false,
      packageSlug: packageSlug,
      adultPrice: 0,
      childPrice: 0,
      infantPrice: 0,
      adults: 0,
      childPrice: 0,
      infantPrice: 0,
      travelType: 0,
      isMobile: window.innerWidth <= 480,
      showFareBreakdown: false,
      isLoading: true,
      triggerLogin: false,
      continuestep: false,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
      onlyGuest: false,
      adulttax: 0,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.calculatePrice();
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  componentDidUpdate() {
    if (this.state.isMobile) {
      $("#freshworks-container").hide();
    }
  }

  componentWillUnmount() {
    if (this.state.isMobile) {
      $("#freshworks-container").show();
    }
  }

  sateValChange = (field, value) => {
    if (field === "proceedtocontinue" && value === "Yes") {
      var newuserID =
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") != "undefined"
          ? cookie.load("UserId")
          : "";
      this.setState({ userID: newuserID }, function () {
        $.magnificPopup.close();
        this.continueFlight();
      });
    } else if (field === "guest" && value === true) {
      $.magnificPopup.close();
      this.continueFlight(field);
    }
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };
  calculatePrice() {
    var adultPrice = 0;
    var childPrice = 0;
    var infantPrice = 0;
    var adulttax = 0;
    var childtax = 0;
    var infanttax = 0;

    var adults =
      this.state.searchdata.adults !== "" &&
      typeof this.state.searchdata.adults !== undefined &&
      typeof this.state.searchdata.adults !== "undefined"
        ? this.state.searchdata.adults
        : "";
    var child =
      this.state.searchdata.child !== "" &&
      typeof this.state.searchdata.child !== undefined &&
      typeof this.state.searchdata.child !== "undefined"
        ? this.state.searchdata.child
        : 0;

    var infant =
      this.state.searchdata.infant !== "" &&
      typeof this.state.searchdata.infant !== undefined &&
      typeof this.state.searchdata.infant !== "undefined"
        ? this.state.searchdata.infant
        : 0;

    var flightPriceList = this.state.flightPrice;
    if (this.state.flightPriceType !== "") {
      if (this.state.flightPriceType.selectedType === "cheapest") {
        flightPriceList = this.state.flightcheapestPrice;
      }
    }
    if (
      flightPriceList !== "" &&
      typeof flightPriceList !== undefined &&
      typeof flightPriceList !== "undefined"
    ) {
      if (flightPriceList.pricedetails.length > 0) {
        flightPriceList.pricedetails.map((item) => {
          if (item.passengerCode === "ADT") {
            adultPrice =
              parseFloat(adultPrice) +
              (parseFloat(item.passengerprice));
            adulttax =
              parseFloat(adulttax) +
             (parseFloat(adults * item.passengertax));
          } else if (item.passengerCode === "CHD") {
            childPrice =
              parseFloat(childPrice) +
              (parseFloat(item.passengerprice));
            childtax =
              parseFloat(childtax) +
              (parseFloat(child * item.passengertax));
          } else if (item.passengerCode === "INF") {
            console.log("infant:", item.passengertax);

            infantPrice =
              parseFloat(infantPrice) +
              (parseFloat(item.passengerprice));
            infanttax =
              parseFloat(infanttax) +
              (parseFloat(infant * item.passengertax));
          }
        });
      }
    }
    this.setState({
      adultPrice: adultPrice.toFixed(2),
      childPrice: childPrice.toFixed(2),
      infantPrice: infantPrice.toFixed(2),
      adults: adults,
      child: child,
      infant: infant,
      adulttax,
      childtax,
      infanttax,
    });
  }

  backToPackage() {
    var bookingdetails = this.props.location.state;
    var slug;
    if (this.state.flightOnly === true) {
      slug = "create-own-package/flight";
    } else {
      slug = this.props.match.params.packageSlug;
    }
    this.props.history.push({
      pathname: "/search-package/" + slug,
      state: bookingdetails,
    });
  }

  viewFlight() {
    var bookingdetails = this.props.location.state;
    this.props.history.push({
      pathname:
        "/search-package/" + this.props.match.params.packageSlug + "/flight",
      state: bookingdetails,
    });
  }
  checkCancelInfo(travelType) {
    this.setState({ travelType: travelType });
  }

  continueFlight(field) {
    try {
      if (field === "guest" && this.state.userID === "") {
        var bookingdetails = {
          addFlight: this.props?.location?.state?.addFlight,
          addnewflight: this.props?.location?.state?.addnewflight,
          emptyFlight: this.props?.location?.state?.emptyFlight,
          flightList: this.props?.location?.state?.flightList,
          flightOnly: this.props?.location?.state?.flightOnly,
          flightPrice: this.props?.location?.state?.flightPrice,
          flightcheapestPrice: this.props?.location?.state?.flightPriceType,
          flightPriceType: this.props?.location?.state?.flightPriceType,
          packagedetails: "",
          removeFlight: this.props?.location?.state?.removeFlight,
          searchdata: this.props?.location?.state?.searchdata,
          selectedflight: this.props?.location?.state?.selectedflight,
          onlyGuest: field === "guest" ? "guest" : "",
        };

        this.props.history.push({
          pathname: "/booking",
          state: bookingdetails,
        });
      } else if (this.state.userID === "") {
        this.setState({
          triggerLogin: true,
          continuestep: true,
          flightOnly: true,
          onlyGuest: true,
        });
      } else {
        var bookingdetails = {
          addFlight: this.props?.location?.state?.addFlight,
          addnewflight: this.props?.location?.state?.addnewflight,
          emptyFlight: this.props?.location?.state?.emptyFlight,
          flightList: this.props?.location?.state?.flightList,
          flightOnly: this.props?.location?.state?.flightOnly,
          flightPrice: this.props?.location?.state?.flightPrice,
          flightcheapestPrice: this.props?.location?.state?.flightPriceType,
          flightPriceType: this.props?.location?.state?.flightPriceType,
          packagedetails: "",
          removeFlight: this.props?.location?.state?.removeFlight,
          searchdata: this.props?.location?.state?.searchdata,
          selectedflight: this.props?.location?.state?.selectedflight,
        };

        this.props.history.push({
          pathname: "/booking",
          state: bookingdetails,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  totalPrice() {
    let total = Math.ceil(
      this.state.adultPrice * this.state.adults +
        this.state.childPrice * this.state.child +
        this.state.infantPrice * this.state.infant +
        this.state.adulttax +
        this.state.childtax +
        this.state.infanttax
    );
    return showPriceValue(
      total,
      this.state.currentCurrency,
      this.state.currencyRate
    );
  }

  render() {
    var flightPrice = this.state.flightPrice;
    if (this.state.flightPriceType !== "") {
      if (this.state.flightPriceType.selectedType === "cheapest") {
        flightPrice = this.state.flightcheapestPrice;
      }
    }
    return (
      <>
        <Header
          {...this.props}
          pagestate={this.state}
          sateValChange={this.sateValChange}
        />
        <section className="det-banner"></section>

        {this.state.isLoading === false ? (
          <>
            {!this.state.isMobile && (
              <div className="bread-crumbs">
                <div className="container">
                  <ul>
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    {this.state.packagedetails !== "" && (
                      <li>
                        <a
                          href={void 0}
                          onClick={this.backToPackage.bind(this)}
                        >
                          {this.state.packagedetails.creator_package_name}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    stroke-width="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        {flightPrice !== "" && (
          <section className="innersection">
            <div className="container">
              <div className="detail-top-header">
                <h1 className="color-header">
                  Your flight for the <span>trip</span>
                </h1>
              </div>
              <div className="package-details">
                <div className="package-details-lhs">
                  {this.state.isMobile ? (
                    <div className="user-flight-select">
                      <div className="ufs-inner-top">
                        {flightPrice.onwayInfo.length > 0 && (
                          <div className="ufs-inner">
                            <div className="ufs-inner-btm-title">
                              <h4>Onward Info</h4>
                            </div>
                            {flightPrice.onwayInfo.map((item, index) => {
                              return (
                                <div key={index}>
                                  <h5 className="color-header">
                                    {item.DepartureAirportCountry} to{" "}
                                    <span>{item.ArrivalAirportCountry}</span>
                                  </h5>
                                  <div className="ufs-box ufs-main">
                                    <div className="f-row">
                                      <div className="col-1">
                                        <div className="fsl-logo ufs-box-rhs">
                                          <div
                                            className={
                                              "Airline-Image airline-" +
                                              flightPrice.returnInfo[0]
                                                ?.OperatingCarrierCode
                                            }
                                          ></div>
                                          <span>
                                            {
                                              flightPrice.returnInfo[0]
                                                ?.OperatingCarrieName
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-2">
                                        <div>
                                          Direct,{" "}
                                          <span className="fsl-time">
                                            {item.JourneyDuration}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-1">
                                        <div className="fsl-from">
                                          <p>
                                            <strong>
                                              {item.DepartureAirportLocation} (
                                              {
                                                item.DepartureAirportLocationCode
                                              }
                                              )
                                            </strong>
                                          </p>
                                          <p>
                                            <span>
                                              {moment(
                                                item.DepartureDate
                                              ).format("DD MMM")}
                                              ,
                                            </span>{" "}
                                            <span>{item.DepartureTime}</span>{" "}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-2">
                                        {" "}
                                        <div className="fsl-img">
                                          <img
                                            src={flightright}
                                            alt="flightright"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="fsl-to">
                                          <p>
                                            <strong>
                                              {item.ArrivalAirportLocation} (
                                              {item.ArrivalAirportLocationCode})
                                            </strong>
                                          </p>
                                          <p>
                                            <span>
                                              {moment(item.ArrivalDate).format(
                                                "DD MMM"
                                              )}
                                              ,
                                            </span>{" "}
                                            <span>{item.ArrivalTime}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="ufs-box">
                              {this.state.flightList.length > 0 &&
                                this.state.flightList[
                                  this.state.selectedflight
                                ] !== "" &&
                                typeof this.state.flightList[
                                  this.state.selectedflight
                                ] !== undefined &&
                                typeof this.state.flightList[
                                  this.state.selectedflight
                                ] !== "undefined" && (
                                  <div className="ufs-box-lhs">
                                    <div className="ufs-info">
                                      {flightPrice.pricedetails[0].PenaltiesInfo.map(
                                        (price, priceindex) => {
                                          if (price.PenaltyType === "Cancel") {
                                            return (
                                              <span
                                                key={priceindex}
                                                onClick={this.checkCancelInfo.bind(
                                                  this,
                                                  0
                                                )}
                                                className={
                                                  price.Allowed
                                                    ? "cancel"
                                                    : "non-cancel"
                                                }
                                              >
                                                {price.Allowed === false &&
                                                  "Non-"}
                                                cancellable
                                              </span>
                                            );
                                          } else if (
                                            price.PenaltyType === "Exchange"
                                          ) {
                                            return (
                                              <span
                                                key={priceindex}
                                                onClick={this.checkCancelInfo.bind(
                                                  this,
                                                  0
                                                )}
                                                className={
                                                  price.Allowed
                                                    ? "cancel"
                                                    : "non-cancel"
                                                }
                                              >
                                                {price.Allowed === false &&
                                                  "Non-"}
                                                changeable
                                              </span>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                    <div className="ufs-info-btm">
                                      <h6>
                                        {
                                          flightPrice.onwayInfo[0]
                                            .CabinClassType
                                        }{" "}
                                        class
                                      </h6>
                                      <p>
                                        {this.state.flightList[
                                          this.state.selectedflight
                                        ]?.CheckinBaggage !== "" && (
                                          <span>
                                            <strong>Check in :</strong>{" "}
                                            {this.state.flightList[
                                              this.state.selectedflight
                                            ]?.CheckinBaggage.map((checkin) => {
                                              var pasanger = "";
                                              if (checkin.Type === "ADT") {
                                                pasanger = "adult";
                                              } else if (
                                                checkin.Type === "CHD"
                                              ) {
                                                pasanger = "child";
                                              } else if (
                                                checkin.Type === "INF"
                                              ) {
                                                pasanger = "infant";
                                              }

                                              return (
                                                <>
                                                  {checkin.Value} per {pasanger}{" "}
                                                </>
                                              );
                                            })}{" "}
                                          </span>
                                        )}
                                        {this.state.flightList[
                                          this.state.selectedflight
                                        ]?.CabinBaggage !== "" && (
                                          <span>
                                            <strong>Cabin :</strong>{" "}
                                            <span>
                                              {/* <strong>Check in :</strong>{" "} */}
                                              {this.state.flightList[
                                                this.state.selectedflight
                                              ]?.CabinBaggage.map((checkin) => {
                                                var pasanger = "";
                                                if (checkin.Type === "ADT") {
                                                  pasanger = "adult";
                                                } else if (
                                                  checkin.Type === "CHD"
                                                ) {
                                                  pasanger = "child";
                                                } else if (
                                                  checkin.Type === "INF"
                                                ) {
                                                  pasanger = "infant";
                                                }

                                                return (
                                                  <>
                                                    {checkin.Value} per{" "}
                                                    {pasanger}{" "}
                                                  </>
                                                );
                                              })}{" "}
                                            </span>
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              {/* <div className="fsl-logo ufs-box-rhs">
                                <div
                                  className={
                                    "Airline-Image airline-" +
                                    flightPrice.returnInfo[0]
                                      .OperatingCarrierCode
                                  }
                                ></div>
                                <span>
                                  {
                                   flightPrice.returnInfo[0]
                                      .OperatingCarrieName
                                  }
                                </span>
                              </div> */}
                            </div>
                          </div>
                        )}
                        {flightPrice.returnInfo.length > 0 && (
                          <div className="ufs-inner">
                            <div className="ufs-inner-btm-title">
                              <h4>Return Info</h4>
                            </div>
                            {flightPrice.returnInfo.map((item, index) => {
                              return (
                                <div key={index}>
                                  <h5 className="color-header">
                                    {item.DepartureAirportCountry} to{" "}
                                    <span>{item.ArrivalAirportCountry}</span>
                                  </h5>
                                  <div className="ufs-box ufs-main">
                                    <div className="f-row">
                                      <div className="col-1">
                                        <div className="fsl-logo ufs-box-rhs">
                                          <div
                                            className={
                                              "Airline-Image airline-" +
                                              flightPrice.returnInfo[0]
                                                ?.OperatingCarrierCode
                                            }
                                          ></div>
                                          <span>
                                            {
                                              flightPrice.returnInfo[0]
                                                ?.OperatingCarrieName
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-2">
                                        <div>
                                          Direct,{" "}
                                          <span className="fsl-time">
                                            {item.JourneyDuration}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-1">
                                        <div className="fsl-from">
                                          <p>
                                            <strong>
                                              {item.DepartureAirportLocation} (
                                              {
                                                item.DepartureAirportLocationCode
                                              }
                                              )
                                            </strong>
                                          </p>
                                          <p>
                                            <span>
                                              {moment(
                                                item.DepartureDate
                                              ).format("DD MMM")}
                                              ,
                                            </span>{" "}
                                            <span>{item.DepartureTime}</span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-2">
                                        {" "}
                                        <div className="fsl-img">
                                          <img
                                            src={flightright}
                                            alt="flightright"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="fsl-to">
                                          <p>
                                            <strong>
                                              {item.ArrivalAirportLocation} (
                                              {item.ArrivalAirportLocationCode})
                                            </strong>
                                          </p>
                                          <p>
                                            <span>
                                              {moment(item.ArrivalDate).format(
                                                "DD MMM"
                                              )}
                                              ,
                                            </span>{" "}
                                            <span>{item.ArrivalTime}</span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="ufs-box">
                              <div className="ufs-box-lhs">
                                <div className="ufs-info">
                                  {flightPrice.pricedetails[0].PenaltiesInfo.map(
                                    (price, priceindex) => {
                                      if (price.PenaltyType === "Cancel") {
                                        return (
                                          <span
                                            key={priceindex}
                                            onClick={this.checkCancelInfo.bind(
                                              this,
                                              1
                                            )}
                                            className={
                                              price.Allowed
                                                ? "cancel"
                                                : "non-cancel"
                                            }
                                          >
                                            {price.Allowed === false && "Non-"}
                                            cancellable
                                          </span>
                                        );
                                      } else if (
                                        price.PenaltyType === "Exchange"
                                      ) {
                                        return (
                                          <span
                                            key={priceindex}
                                            onClick={this.checkCancelInfo.bind(
                                              this,
                                              1
                                            )}
                                            className={
                                              price.Allowed
                                                ? "cancel"
                                                : "non-cancel"
                                            }
                                          >
                                            {price.Allowed === false && "Non-"}
                                            changeable
                                          </span>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                                <div className="ufs-info-btm">
                                  <h6>
                                    {" "}
                                    {
                                      flightPrice.returnInfo[0].CabinClassType
                                    }{" "}
                                    class
                                  </h6>
                                  <p>
                                    {this.state.flightList[
                                      this.state.selectedflight
                                    ]?.CheckinBaggage !== "" && (
                                      <span>
                                        <strong>Check in :</strong>{" "}
                                        {this.state.flightList[
                                          this.state.selectedflight
                                        ]?.CheckinBaggage.map((checkin) => {
                                          var pasanger = "";
                                          if (checkin.Type === "ADT") {
                                            pasanger = "adult";
                                          } else if (checkin.Type === "CHD") {
                                            pasanger = "child";
                                          } else if (checkin.Type === "INF") {
                                            pasanger = "infant";
                                          }

                                          return (
                                            <>
                                              {checkin.Value} per {pasanger}{" "}
                                            </>
                                          );
                                        })}{" "}
                                      </span>
                                    )}
                                    <span>
                                      <strong>Cabin :</strong>{" "}
                                      {this.state.flightList[
                                        this.state.selectedflight
                                      ]?.CabinBaggage !== "" && (
                                        <span>
                                          {/* <strong>Check in :</strong>{" "} */}
                                          {this.state.flightList[
                                            this.state.selectedflight
                                          ]?.CabinBaggage.map((checkin) => {
                                            var pasanger = "";
                                            if (checkin.Type === "ADT") {
                                              pasanger = "adult";
                                            } else if (checkin.Type === "CHD") {
                                              pasanger = "child";
                                            } else if (checkin.Type === "INF") {
                                              pasanger = "infant";
                                            }

                                            return (
                                              <>
                                                {checkin.Value} per {pasanger}{" "}
                                              </>
                                            );
                                          })}{" "}
                                        </span>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="ufs-inner-btm">
                        <div className="ufs-inner-btm-title">
                          <h4>Cancellation policy</h4>
                        </div>
                        <div className="ufs-inner-btm-lhs">
                          <h5>Fizota policy</h5>
                          <ul>
                            <li>
                              Flights with "Non-Refundable" tag in the final
                              travel vouchers issued by Fizota do not meet the
                              criteria for refund eligibility.{" "}
                            </li>
                            <li>
                              Refunds for flights with "Refundable" tag in the
                              final travel vouchers issued by Fizota will be
                              processed in accordance with the guidelines
                              mentioned in the "{" "}
                              <Link
                                to={"/cancellation-and-refund"}
                                target="_blank"
                              >
                                Cancellation Policy{" "}
                              </Link>{" "}
                              " section.
                            </li>
                            <li>
                              {" "}
                              The final refund amount for the eligible flight is
                              subject to international exchange rates, supplier
                              admin fee, payment gateway charges. For more
                              information read our{" "}
                              <Link
                                to={"/cancellation-and-refund"}
                                target="_blank"
                              >
                                Cancellation and Refund policy.{" "}
                              </Link>{" "}
                            </li>
                          </ul>
                        </div>
                        {flightPrice.pricedetails.length > 0 && (
                          <div className="ufs-inner-btm-rhs">
                            <h5>Airline policy</h5>
                            {flightPrice.pricedetails[this.state.travelType]
                              .PenaltiesInfo !== "" &&
                              typeof flightPrice.pricedetails[
                                this.state.travelType
                              ].PenaltiesInfo !== undefined &&
                              typeof flightPrice.pricedetails[
                                this.state.travelType
                              ].PenaltiesInfo !== "undefined" && (
                                <ul>
                                  {flightPrice.pricedetails[
                                    this.state.travelType
                                  ].PenaltiesInfo.map((item, index) => {
                                    if (item.PenaltyType === "Cancel") {
                                      return (
                                        <li key={index}>
                                          Cancellation -{" "}
                                          {item.Allowed === false &&
                                            "This flight is not cancellable. Please visit airlines website for more information."}
                                          {item.Allowed === true &&
                                            "This flight is cancellable. And the cancellation charge of " +
                                              item.CurrencyCode +
                                              item.Amount}
                                        </li>
                                      );
                                    } else if (
                                      item.PenaltyType === "Exchange"
                                    ) {
                                      return (
                                        <li key={index}>
                                          Changeable -{" "}
                                          {item.Allowed === false &&
                                            "This flight is not changeable. Please visit airlines website for more information."}
                                          {item.Allowed === true &&
                                            "This flight is changeable. And exchange charge is " +
                                              item.CurrencyCode +
                                              item.Amount}
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="user-flight-select">
                      <div className="ufs-inner-top">
                        {flightPrice.onwayInfo.length > 0 && (
                          <div className="ufs-inner">
                            <div className="ufs-inner-btm-title">
                              <h4>Onward Info</h4>
                            </div>
                            {flightPrice.onwayInfo.map((item, index) => {
                              return (
                                <div key={index}>
                                  <h5 className="color-header">
                                    {item.DepartureAirportCountry} to{" "}
                                    <span>{item.ArrivalAirportCountry}</span>
                                  </h5>
                                  <div className="ufs-box">
                                    <div className="fsl-path">
                                      <div className="fsl-img">
                                        <img
                                          src={flightright}
                                          alt="flightright"
                                        />
                                      </div>
                                      <div className="fsl-direct">Direct</div>
                                      <div className="fsl-time">
                                        {item.JourneyDuration}
                                      </div>
                                      <div className="fsl-from">
                                        <p>
                                          {item.DepartureTime}{" "}
                                          <span>{item.DepartureDate}</span>
                                          <strong>
                                            {item.DepartureAirportLocation} (
                                            {item.DepartureAirportLocationCode})
                                          </strong>
                                        </p>
                                      </div>
                                      <div className="fsl-to">
                                        <p>
                                          {item.ArrivalTime}{" "}
                                          <span>{item.ArrivalDate}</span>
                                          <strong>
                                            {item.ArrivalAirportLocation} (
                                            {item.ArrivalAirportLocationCode})
                                          </strong>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="ufs-box">
                              {this.state.flightList.length > 0 &&
                                this.state.flightList[
                                  this.state.selectedflight
                                ] !== "" &&
                                typeof this.state.flightList[
                                  this.state.selectedflight
                                ] !== undefined &&
                                typeof this.state.flightList[
                                  this.state.selectedflight
                                ] !== "undefined" && (
                                  <div className="ufs-box-lhs">
                                    <div className="ufs-info">
                                      {flightPrice.pricedetails[0].PenaltiesInfo.map(
                                        (price, priceindex) => {
                                          if (price.PenaltyType === "Cancel") {
                                            return (
                                              <span
                                                key={priceindex}
                                                onClick={this.checkCancelInfo.bind(
                                                  this,
                                                  0
                                                )}
                                                className={
                                                  price.Allowed
                                                    ? "cancel"
                                                    : "non-cancel"
                                                }
                                              >
                                                {price.Allowed === false &&
                                                  "Non-"}
                                                cancellable
                                              </span>
                                            );
                                          } else if (
                                            price.PenaltyType === "Exchange"
                                          ) {
                                            return (
                                              <span
                                                key={priceindex}
                                                onClick={this.checkCancelInfo.bind(
                                                  this,
                                                  0
                                                )}
                                                className={
                                                  price.Allowed
                                                    ? "cancel"
                                                    : "non-cancel"
                                                }
                                              >
                                                {price.Allowed === false &&
                                                  "Non-"}
                                                changeable
                                              </span>
                                            );
                                          }
                                        }
                                      )}
                                    </div>
                                    <div className="ufs-info-btm">
                                      <h6>
                                        {
                                          flightPrice.onwayInfo[0]
                                            .CabinClassType
                                        }{" "}
                                        class
                                      </h6>
                                      <p>
                                        {this.state.flightList[
                                          this.state.selectedflight
                                        ]?.CheckinBaggage !== "" && (
                                          <span>
                                            <strong>Check in :</strong>{" "}
                                            {this.state.flightList[
                                              this.state.selectedflight
                                            ]?.CheckinBaggage.map((checkin) => {
                                              var pasanger = "";
                                              if (checkin.Type === "ADT") {
                                                pasanger = "adult";
                                              } else if (
                                                checkin.Type === "CHD"
                                              ) {
                                                pasanger = "child";
                                              } else if (
                                                checkin.Type === "INF"
                                              ) {
                                                pasanger = "infant";
                                              }

                                              return (
                                                <>
                                                  {checkin.Value} per {pasanger}{" "}
                                                </>
                                              );
                                            })}{" "}
                                          </span>
                                        )}
                                        {this.state.flightList[
                                          this.state.selectedflight
                                        ]?.CabinBaggage !== "" && (
                                          <span>
                                            <strong>Cabin :</strong>{" "}
                                            <span>
                                              {/* <strong>Check in :</strong>{" "} */}
                                              {this.state.flightList[
                                                this.state.selectedflight
                                              ]?.CabinBaggage.map((checkin) => {
                                                var pasanger = "";
                                                if (checkin.Type === "ADT") {
                                                  pasanger = "adult";
                                                } else if (
                                                  checkin.Type === "CHD"
                                                ) {
                                                  pasanger = "child";
                                                } else if (
                                                  checkin.Type === "INF"
                                                ) {
                                                  pasanger = "infant";
                                                }

                                                return (
                                                  <>
                                                    {checkin.Value} per{" "}
                                                    {pasanger}{" "}
                                                  </>
                                                );
                                              })}{" "}
                                            </span>
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              <div className="fsl-logo ufs-box-rhs">
                                <div
                                  className={
                                    "Airline-Image airline-" +
                                    flightPrice.returnInfo[0]
                                      ?.OperatingCarrierCode
                                  }
                                ></div>
                                <span>
                                  {
                                    flightPrice.returnInfo[0]
                                      ?.OperatingCarrieName
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {flightPrice.returnInfo.length > 0 && (
                          <div className="ufs-inner">
                            <div className="ufs-inner-btm-title">
                              <h4>Return Info</h4>
                            </div>
                            {flightPrice.returnInfo.map((item, index) => {
                              return (
                                <div key={index}>
                                  <h5 className="color-header">
                                    {item.DepartureAirportCountry} to{" "}
                                    <span>{item.ArrivalAirportCountry}</span>
                                  </h5>
                                  <div className="ufs-box">
                                    <div className="fsl-path">
                                      <div className="fsl-img">
                                        <img
                                          src={flightright}
                                          alt="flightright"
                                        />
                                      </div>
                                      <div className="fsl-direct">Direct</div>
                                      <div className="fsl-time">
                                        {item.JourneyDuration}
                                      </div>
                                      <div className="fsl-from">
                                        <p>
                                          {item.DepartureTime}{" "}
                                          <span>{item.DepartureDate}</span>
                                          <strong>
                                            {item.DepartureAirportLocation} (
                                            {item.DepartureAirportLocationCode})
                                          </strong>
                                        </p>
                                      </div>
                                      <div className="fsl-to">
                                        <p>
                                          {item.ArrivalTime}{" "}
                                          <span>{item.ArrivalDate}</span>
                                          <strong>
                                            {item.ArrivalAirportLocation} (
                                            {item.ArrivalAirportLocationCode})
                                          </strong>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <div className="ufs-box">
                              <div className="ufs-box-lhs">
                                <div className="ufs-info">
                                  {flightPrice.pricedetails[0].PenaltiesInfo.map(
                                    (price, priceindex) => {
                                      if (price.PenaltyType === "Cancel") {
                                        return (
                                          <span
                                            key={priceindex}
                                            onClick={this.checkCancelInfo.bind(
                                              this,
                                              1
                                            )}
                                            className={
                                              price.Allowed
                                                ? "cancel"
                                                : "non-cancel"
                                            }
                                          >
                                            {price.Allowed === false && "Non-"}
                                            cancellable
                                          </span>
                                        );
                                      } else if (
                                        price.PenaltyType === "Exchange"
                                      ) {
                                        return (
                                          <span
                                            key={priceindex}
                                            onClick={this.checkCancelInfo.bind(
                                              this,
                                              1
                                            )}
                                            className={
                                              price.Allowed
                                                ? "cancel"
                                                : "non-cancel"
                                            }
                                          >
                                            {price.Allowed === false && "Non-"}
                                            changeable
                                          </span>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                                <div className="ufs-info-btm">
                                  <h6>
                                    {" "}
                                    {
                                      flightPrice.returnInfo[0].CabinClassType
                                    }{" "}
                                    class
                                  </h6>
                                  <p>
                                    {this.state.flightList[
                                      this.state.selectedflight
                                    ]?.CheckinBaggage !== "" && (
                                      <span>
                                        <strong>Check in :</strong>{" "}
                                        {this.state.flightList[
                                          this.state.selectedflight
                                        ]?.CheckinBaggage.map((checkin) => {
                                          var pasanger = "";
                                          if (checkin.Type === "ADT") {
                                            pasanger = "adult";
                                          } else if (checkin.Type === "CHD") {
                                            pasanger = "child";
                                          } else if (checkin.Type === "INF") {
                                            pasanger = "infant";
                                          }

                                          return (
                                            <>
                                              {checkin.Value} per {pasanger}{" "}
                                            </>
                                          );
                                        })}{" "}
                                      </span>
                                    )}
                                    <span>
                                      <strong>Cabin :</strong>{" "}
                                      {this.state.flightList[
                                        this.state.selectedflight
                                      ]?.CabinBaggage !== "" && (
                                        <span>
                                          {/* <strong>Check in :</strong>{" "} */}
                                          {this.state.flightList[
                                            this.state.selectedflight
                                          ]?.CabinBaggage.map((checkin) => {
                                            var pasanger = "";
                                            if (checkin.Type === "ADT") {
                                              pasanger = "adult";
                                            } else if (checkin.Type === "CHD") {
                                              pasanger = "child";
                                            } else if (checkin.Type === "INF") {
                                              pasanger = "infant";
                                            }

                                            return (
                                              <>
                                                {checkin.Value} per {pasanger}{" "}
                                              </>
                                            );
                                          })}{" "}
                                        </span>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="fsl-logo ufs-box-rhs">
                                <div
                                  className={
                                    "Airline-Image airline-" +
                                    flightPrice.returnInfo[0]
                                      ?.OperatingCarrierCode
                                  }
                                ></div>
                                <span>
                                  {
                                    flightPrice.returnInfo[0]
                                      ?.OperatingCarrieName
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="ufs-inner-btm">
                        <div className="ufs-inner-btm-title">
                          <h4>Cancellation policy</h4>
                        </div>
                        <div className="ufs-inner-btm-lhs">
                          <h5>Fizota policy</h5>
                          <ul>
                            <li>
                              Flights with "Non-Refundable" tag in the final
                              travel vouchers issued by Fizota do not meet the
                              criteria for refund eligibility.{" "}
                            </li>
                            <li>
                              Refunds for flights with "Refundable" tag in the
                              final travel vouchers issued by Fizota will be
                              processed in accordance with the guidelines
                              mentioned in the "{" "}
                              <Link
                                to={"/cancellation-and-refund"}
                                target="_blank"
                              >
                                Cancellation Policy{" "}
                              </Link>{" "}
                              " section.
                            </li>
                            <li>
                              {" "}
                              The final refund amount for the eligible flight is
                              subject to international exchange rates, supplier
                              admin fee, payment gateway charges. For more
                              information read our{" "}
                              <Link
                                to={"/cancellation-and-refund"}
                                target="_blank"
                              >
                                Cancellation and Refund policy.{" "}
                              </Link>{" "}
                            </li>
                          </ul>
                        </div>
                        {flightPrice.pricedetails.length > 0 && (
                          <div className="ufs-inner-btm-rhs">
                            <h5>Airline policy</h5>
                            {flightPrice.pricedetails[this.state.travelType]
                              .PenaltiesInfo !== "" &&
                              typeof flightPrice.pricedetails[
                                this.state.travelType
                              ].PenaltiesInfo !== undefined &&
                              typeof flightPrice.pricedetails[
                                this.state.travelType
                              ].PenaltiesInfo !== "undefined" && (
                                <ul>
                                  {flightPrice.pricedetails[
                                    this.state.travelType
                                  ].PenaltiesInfo.map((item, index) => {
                                    if (item.PenaltyType === "Cancel") {
                                      return (
                                        <li key={index}>
                                          Cancellation -{" "}
                                          {item.Allowed === false &&
                                            "This flight is not cancellable. Please visit airlines website for more information."}
                                          {item.Allowed === true &&
                                            "This flight is cancellable. And the cancellation charge of " +
                                              item.CurrencyCode +
                                              item.Amount}
                                        </li>
                                      );
                                    } else if (
                                      item.PenaltyType === "Exchange"
                                    ) {
                                      return (
                                        <li key={index}>
                                          Changeable -{" "}
                                          {item.Allowed === false &&
                                            "This flight is not changeable. Please visit airlines website for more information."}
                                          {item.Allowed === true &&
                                            "This flight is changeable. And exchange charge is " +
                                              item.CurrencyCode +
                                              item.Amount}
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {!this.state.isMobile && (
                  <div className="package-details-rhs">
                    <h5 className="color-header mb35 vb-hidden">
                      Stay at <span> singapore</span>
                    </h5>
                    <div className="detail-bottom-rhs-price rhs-white-box">
                      <h5>Price details</h5>
                      <ul>
                        <li>
                          {parseFloat(this.state.adultPrice) > 0 && (
                            <>
                              <span className="service-content">
                                {this.state.adults} <strong>Adult</strong>
                              </span>
                              <span className="service-amount">
                                <strong>
                                  {showPriceValue(
                                    (
                                      this.state.adultPrice * this.state.adults
                                    ),
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </strong>
                              </span>
                            </>
                          )}
                          {parseFloat(this.state.childPrice) > 0 && (
                            <>
                              <span className="service-content">
                                {this.state.child} <strong>Child</strong>{" "}
                              </span>
                              <span className="service-amount">
                                <strong>
                                  {showPriceValue(
                                    (
                                      this.state.childPrice * this.state.child
                                    ),
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}
                                </strong>
                              </span>
                            </>
                          )}
                          {parseFloat(this.state.infant) > 0 && (
                            <>
                              <span className="service-content">
                                {this.state.infant} <strong>Infant</strong>{" "}
                              </span>
                              <span className="service-amount">
                                <strong>
                                  {showPriceValue(
                                    (
                                      this.state.infantPrice * this.state.infant
                                    ),
                                    this.state.currentCurrency,
                                    this.state.currencyRate
                                  )}{" "}
                                </strong>
                              </span>
                            </>
                          )}
                                <span className="service-content">
                                  <strong>Total Tax</strong>
                                </span>
                                <span className="service-amount">
                                  <strong>
                                    {showPriceValue(
                                      (
                                        this.state.adulttax +
                                          this.state.childtax +
                                          this.state.infanttax
                                      ),
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}
                                  </strong>
                                </span>
                        </li>

                        <li>
                          <span className="service-content">Total</span>
                          <span className="service-amount">
                          {this.totalPrice()}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="booknow-btm two-btn-merge">
                      <a
                        href={void 0}
                        onClick={this.backToPackage.bind(this)}
                        className="button ghost-button"
                      >
                        Back
                      </a>
                      <a
                        className="button check_room_avail"
                        href={void 0}
                        onClick={
                          this.state.flightOnly !== true
                            ? this.viewFlight.bind(this)
                            : this.continueFlight.bind(this)
                        }
                      >
                        {this.state.flightOnly === true ? "Continue" : "Change"}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}

        <Footer {...this.props} />
        {this.state.isMobile && (
          <div className="priceDetail-bottomSheetMain">
            <div className="fareDetails">
              <div className="line"></div>
              <div className="row">
                <div className="col-1">
                  <div className="title">
                    Total <span>fare</span>
                  </div>
                  <div className="price">
                   {this.totalPrice()}
                  </div>

                  <div
                    className="breakdown"
                    onClick={() => this.setState({ showFareBreakdown: true })}
                  >
                    View fare breakdown
                  </div>
                </div>
                <div className="col-2">
                  <a
                    className="button check_room_avail"
                    href={void 0}
                    onClick={
                      this.state.flightOnly !== true
                        ? this.viewFlight.bind(this)
                        : this.continueFlight.bind(this)
                    }
                  >
                    {this.state.flightOnly === true ? "Continue" : "Change"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <BottomSheet
          open={this.state.showFareBreakdown}
          onDismiss={() => this.setState({ showFareBreakdown: false })}
          className="priceBreakdown__bottomSheetMain"
        >
          <div className="container detail-bottom-rhs-price">
            <ul>
              <li>
                {parseFloat(this.state.adultPrice) > 0 && (
                  <>
                    <span className="service-content">
                      <strong>{this.state.adults} Adult</strong>{" "}
                    </span>
                    <span className="service-amount">
                      <strong>
                        {showPriceValue(
                          (this.state.adultPrice * this.state.adults),
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </strong>
                    </span>
                  </>
                )}
                {parseFloat(this.state.childPrice) > 0 && (
                  <>
                    <span className="service-content">
                      <strong>{this.state.child} Child</strong>
                    </span>
                    <span className="service-amount">
                      <strong>
                        {showPriceValue(
                         (this.state.childPrice * this.state.child),
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </strong>
                    </span>
                  </>
                )}
                {parseFloat(this.state.infantPrice) > 0 && (
                  <>
                    <span className="service-content">
                      <strong>{this.state.infant} Infant</strong>
                    </span>
                    <span className="service-amount">
                      <strong>
                        {showPriceValue(
                          (this.state.infantPrice * this.state.infant),
                          this.state.currentCurrency,
                          this.state.currencyRate
                        )}
                      </strong>
                    </span>
                  </>
                )}
                    <>
                {" "}
                <span className="service-content">
                  <strong>Total Tax</strong>
                </span>
                <span className="service-amount">
                  <strong>
                    {showPriceValue(
                      this.state.adulttax +
                        this.state.childtax +
                        this.state.infanttax,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </strong>
                </span>
              </>
              </li>
            </ul>
            <Divider />
            <div className="details">
              <div className="title">
                Total <span>fare</span>
              </div>
              <div className="col-2">
                <div className="price">
                {this.totalPrice()}
                </div>
              </div>
            </div>
            <div className="booknow-btm two-btn-merge">
              <a
                href={void 0}
                onClick={this.backToPackage.bind(this)}
                className="button ghost-button"
              >
                Back
              </a>
              <a
                className="button check_room_avail"
                href={void 0}
                onClick={
                  this.state.flightOnly !== true
                    ? this.viewFlight.bind(this)
                    : this.continueFlight.bind(this)
                }
              >
                {this.state.flightOnly === true ? "Continue" : "Change"}
              </a>
            </div>
          </div>
        </BottomSheet>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);