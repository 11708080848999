/* eslint-disable */
import React, { Component } from "react";
import NewSignup from "../Myaccount/NewSignup";
import GoogleLogin from "react-google-login";
import { fbAppId, googleAppId, siteUrl } from "../Helpers/Config";
import FacebookLogin from "react-facebook-login";
import { Link } from "react-router-dom";
import { showLoader } from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import {
  GET_CREATOR_FBLOGINDATA,
  GET_CREATOR_LOGINDATA,
  GET_CREATOR_REGISTRATION,
  GET_CUSTOMER_LOGINDATA,
  GET_CUSTOMER_REGISTRATION,
  GET_FBLOGINDATA,
  GET_FORGET_PASSWORD,
  GET_GLOBAL_SETTINGS,
  GET_GOOGLECREATORLOGINDATA,
  GET_GOOGLELOGINDATA,
} from "../../actions";
var base64 = require("base-64");
var qs = require("qs");

class SignupComponent extends Component {
  handleFormSubmit = (formData) => {
    // this.setState({ customerregstatus: "loading" });
    var qs = require("qs");
    var mobileNo = "";
    var mobile_code = "";
    // if (formData.phone !== "" && formData.phone !== null) {
    //   var mobile = formData.phone.split(" ");
    //   var newMobile = "";
    //   if (mobile.length > 0) {
    //     mobile.map((item, index) => {
    //       if (index !== 0) {
    //         newMobile += item;
    //       }
    //     });
    //   }
    //   mobileNo = newMobile.replace("-", "");
    //   mobile_code = mobile[0];
    // }

    var postObject = {
      customer_name: formData.name,
      customer_email: formData.email,
      customer_password: base64.encode(formData.confirmPassword),
      // customer_phone: mobileNo,
      // customer_phone_code: mobile_code,
      customer_countryCode: $("#countryCode").val(),
      site_url: siteUrl,
    };
    showLoader("signup_submits", "class");
    this.props.getCustomerRegistration(qs.stringify(postObject));
  };

  loginGoogle(response) {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      var vSplitName = response.name.split(" ");
      var firstname = vSplitName[0];
      var lastname = vSplitName[1];

      var qs = require("qs");
      var photo = response.picture;
      /* update gender field */

      var postObject = {
        login_firstname: response.name,
        login_lastname: lastname,
        login_username: response.email,
        customer_google_id: response.id,
        /* customer_photo: photo, */
      };
      //   this.setState({ googlestatus: "loading" });
      this.props.getGoogleLoginData(qs.stringify(postObject));
    }
  }

  responseFacebook = (response) => {
    if (
      response.name !== "" &&
      response.email !== "" &&
      response.name !== undefined &&
      response.email !== undefined
    ) {
      /* update gender field */
      var postGender = "";
      if (
        typeof response.gender !== "undefined" &&
        response.gender === "male"
      ) {
        postGender = "M";
      } else if (
        typeof response.gender !== "undefined" &&
        response.gender === "female"
      ) {
        postGender = "F";
      }

      var name = response.first_name;
      if (
        response.last_name !== "" &&
        typeof response.last_name !== undefined &&
        typeof response.last_name !== "undefined"
      ) {
        name += " " + response.last_name;
      }

      var postObject = {
        login_firstname: name,
        login_username: response.email,
        customer_fb_id: response.id,
        customer_gender: postGender,
      };
      //   this.setState({ fbstatus: "loading" });
      this.props.getFbLoginData(qs.stringify(postObject));
    }
  };

  render() {
    return (
      <>
        <div className="cp-header">
          <h3>Sign up</h3>
          <p>Enter your details to signup</p>
        </div>
        <div className="cp-form">
          <div className="cp-social">
            <div className="google-btn">
              <div id="signInDiv1">
                <GoogleLogin
                  clientId={googleAppId}
                  fileds="name,email,picture,first_name,last_name,birthday,gender"
                  callback={this.loginGoogle}
                  scope="public_profile,email,user_birthday"
                  cssclassName="fa fa-Google"
                  redirectUri={siteUrl}
                  icon="fa-google"
                  textButton="Sign in with Google"
                  /* onSuccess={(credentialResponse) => {
                      console.log(credentialResponse);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }} */
                />
              </div>
              <span>Sign up with Google</span>
            </div>

            <div className="fb-btn">
              <FacebookLogin
                appId={fbAppId}
                fields="name,email,picture,first_name,last_name,birthday,gender"
                callback={this.responseFacebook}
                scope="public_profile,email,user_birthday"
                cssclassName="fa fa-facebook"
                redirectUri={siteUrl}
                icon="fa-facebook"
                textButton="Sign up with Facebook"
              />
            </div>
          </div>
          <div className="or-break">Or sign up with</div>
          {/* <Signup
              fields={this.state.fieldscustomersignup}
              onChange={this.fieldChangeCustomerSignup}
              onValid={this.handleCustomerSignup}
              onInvalid={(e) => console.log(e, "Form invalid!")}
            /> */}
          <NewSignup onFormSubmit={this.handleFormSubmit} />
        </div>
        <div className="cp-form-footer">
          <p>
            By creating account you agree to our{" "}
            <Link to={"/terms-and-conditions"} target="_blank" className="link">
              Terms &amp; conditions,
            </Link>{" "}
            <Link
              to={"/cancellation-and-refund"}
              target="_blank"
              className="link"
            >
              Cancellation and Refund
            </Link>{" "}
            and{" "}
            <Link to={"/privacy-policy"} target="_blank" className="link">
              Privacy policy
            </Link>
          </p>
        </div>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    creatorlogindata: state.creatorlogin,
    fblogin: state.fblogin,
    googlelogin: state.googlelogin,
    forgetpassword: state.forgetpassword,
    creatorregistration: state.creatorregistration,
    customerlogindata: state.customerlogin,
    customerregistration: state.customerregistration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFbLoginData: (formPayload) => {
      dispatch({ type: GET_FBLOGINDATA, formPayload });
    },

    getGoogleLoginData: (formPayload) => {
      dispatch({ type: GET_GOOGLELOGINDATA, formPayload });
    },
    getForgetPassword: (formPayload, forgottype) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload, forgottype });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCustomerLoginData: (formPayload) => {
      dispatch({ type: GET_CUSTOMER_LOGINDATA, formPayload });
    },
    getCustomerRegistration: (formPayload) => {
      dispatch({ type: GET_CUSTOMER_REGISTRATION, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(SignupComponent);
