/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import axios from "axios";
import { GET_PRODUCTLIST, GET_TAGLIST } from "../../actions";
import {
  showAlert,
  stripslashes,
  showPriceValue,
} from "../Helpers/SettingHelper";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Button, Divider } from "@mui/material";
import FilterSheet from "./FilterSheet";
import { apiUrl, apiglobaltixMediaUrl } from "../Helpers/Config";
import {
  pplaceholder,
  barrow,
  sort,
  filter,
  searchlight,
  stario,
  ticketblue,
  dollarblue,
} from "../Helpers/Images";
import ActivitySelection from "../Customerpackage/CustomerPackageDetails/ActivitySelection/ActivitySelection";
import ModalPopup from "../../common/components/ModalPopup/ModalPopup";
import AlertPopup from "../../common/components/AlertPopup/AlertPopup";

class Index extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    const activitiesurl = queryParams.get("activities");
    var activities_url = "";
    if (
      activitiesurl !== "" &&
      activitiesurl !== null &&
      typeof activitiesurl !== undefined &&
      typeof activitiesurl !== "undefined"
    ) {
      var activitiessplit = activitiesurl.split("~");
      activities_url = activitiessplit[1];
    }

    var searchdata = "";
    var ttdOnly = false;
    var city = "";
    var ttdOnly = false;
    if (
      this.props.location.state.searchdata !== "" &&
      typeof this.props.location.state.searchdata !== undefined &&
      typeof this.props.location.state.searchdata !== "undefined"
    ) {
      searchdata = this.props.location.state.searchdata;
      city =
        searchdata.destinationCity !== "" &&
        searchdata.destinationCity !== undefined &&
        searchdata.destinationCity !== "undefined"
          ? searchdata.destinationCity.toLowerCase()
          : "";
      ttdOnly = this.props.location.state.searchdata.ttdOnly;
    } else {
      this.props.history.push("/");
    }
    var ttdCity = this.props.location.state?.ttdCity;
    var country =
      this.props.match.params.country !== "" &&
      this.props.match.params.country !== undefined &&
      this.props.match.params.country !== "undefined"
        ? this.props.match.params.country.toLowerCase()
        : "";

    var packageID =
      this.props.match.params.packageID !== "" &&
      this.props.match.params.packageID !== undefined &&
      this.props.match.params.packageID !== "undefined"
        ? this.props.match.params.packageID
        : "";
    var dayno =
      this.props.location.state.day !== "" &&
      this.props.location.state.day !== undefined &&
      this.props.location.state.day !== "undefined"
        ? this.props.location.state.day
        : "";

    var activitiyIndex =
      this.props.location.state.activitiyIndex !== "" &&
      this.props.location.state.activitiyIndex !== undefined &&
      this.props.location.state.activitiyIndex !== "undefined"
        ? (activitiyIndex = this.props.location.state.activitiyIndex)
        : "";

    var dayIndex =
      this.props.location.state.dayIndex !== "" &&
      this.props.location.state.dayIndex !== undefined &&
      this.props.location.state.dayIndex !== "undefined"
        ? this.props.location.state.dayIndex
        : "";

    var activities =
      this.props.location.state.activities !== "" &&
      typeof this.props.location.state.activities !== undefined &&
      typeof this.props.location.state.activities !== "undefined"
        ? this.props.location.state.activities
        : "";

    var selectedDay =
      this.props.location.state.selectedDay !== "" &&
      typeof this.props.location.state.selectedDay !== undefined &&
      typeof this.props.location.state.selectedDay !== "undefined"
        ? this.props.location.state.selectedDay
        : "";
    this.state = {
      loading: true,
      country: country,
      city: city,
      searchdata: searchdata,

      adultsQty: 1,
      childQty: 1,

      activitiyIndex: activitiyIndex,
      dayIndex: dayIndex,

      activities: activities,
      selectedDay: selectedDay,

      packageID: packageID,
      dayno: dayno,
      productlist: [],
      displayProducts: "",
      page: 1,
      pagenation: "",
      disablelast: false,
      taglist: [],
      displaytag: "",
      filterTag: [],
      selectedrating: "",
      startPrice: "",
      endPrice: "",
      enablesortby: false,
      pricesort: "",
      showFilter: false,
      productListStatus: "",
      selectedProduct: [],
      selectedProductID: [],
      selectedpackage: [],
      search_keyword: "",
      existProductList: [],
      initialPackageProduct: false,
      removeDayIndex: "",
      removeIndex: "",
      isMobile: window.innerWidth <= 480,
      showSearchComponent: false,
      showFareBreakdown: false,
      openFilterSheet: false,
      selectedTags: [],
      showsearchList: false,
      displaySearchCountry: "",
      displaySearchProducts: "",
      total_activities: searchdata.total_activities,
      ttdOnly: ttdOnly,

      start_date: searchdata.start_date,
      ttdCity: ttdCity,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
      globalSetting: [],
      selectedProd: null,
      viewActivity: false,
    };
    this.dropdownRef = React.createRef();
    this.outletRef = React.createRef();
    this.props.getTagList();
  }
  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
    this.selectCreatorProduct();
    this.calculatePrice();
    var currentThis = this;
    // $(function () {
    //   $("#rang").ionRangeSlider({
    //     min: 0,
    //     max: 10000,
    //     from: 1000,
    //     to: 9000,
    //     type: "double",
    //     grid_num: 10,
    //     onFinish: function (data) {
    //       currentThis.setState(
    //         { startPrice: data.from, endPrice: data.to },
    //         function () {
    //           currentThis.applyFilter();
    //         }
    //       );
    //     },
    //   });
    //   // $("#rangmobile").ionRangeSlider({
    //   //   min: 0,
    //   //   max: 10000,
    //   //   from: 1000,
    //   //   to: 9000,
    //   //   type: "double",
    //   //   grid_num: 10,
    //   //   onFinish: function (data) {
    //   //     currentThis.setState(
    //   //       { startPrice: data.from, endPrice: data.to },
    //   //       function () {
    //   //         currentThis.applyFilter();
    //   //       }
    //   //     );
    //   //   },
    //   // });
    // });

    if (this.state.isMobile) {
      var cityName = this.state.city || this.state.ttdCity;
      var Fcondition =
        "&startPrice=" +
        this.state.startPrice +
        "&endPrice=" +
        this.state.endPrice;
      var params =
        "page=" +
        this.state.page +
        "&country=" +
        this.state.country +
        "&city=" +
        cityName;
      params += "&tag_filter=" + Fcondition;
      if (
        this.props.location.state.selectedHotel !== "" &&
        typeof this.props.location.state.selectedHotel !== undefined &&
        typeof this.props.location.state.selectedHotel !== "undefined"
      ) {
        var selectedHotel = this.props.location.state.selectedHotel;
        params += `&latitude=${selectedHotel.latitude}&longitude=${selectedHotel.longitude}`;
      }
      this.props.getProductList(params);
    } else {
      var cityName = this.state.city || this.state.ttdCity;
      var params =
        "page=" +
        this.state.page +
        "&country=" +
        this.state.country +
        "&city=" +
        cityName;
      if (
        this.props.location.state.selectedHotel !== "" &&
        typeof this.props.location.state.selectedHotel !== undefined &&
        typeof this.props.location.state.selectedHotel !== "undefined"
      ) {
        var selectedHotel = this.props.location.state.selectedHotel;
        params += `&latitude=${selectedHotel.latitude}&longitude=${selectedHotel.longitude}`;
      }
      this.props.getProductList(
        `${params}&tag_filter=&startPrice=0&endPrice=1000`
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCurrency !== this.state.currentCurrency ||
      prevState.currencyRate !== this.state.currencyRate
    ) {
      this.displayProductList();
      this.displaySearch();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }
  componentWillReceiveProps(PropsData) {
    if (PropsData.productListStatus !== this.state.productListStatus) {
      this.setState(
        { productListStatus: PropsData.productListStatus },
        function () {
          /*  if (PropsData.productListStatus === "success") {
            this.setState({ loading: false });
          } */
        }
      );
    }
    if (PropsData.productlist !== this.state.productlist) {
      this.setState(
        {
          productlist: this.state.page === 1 ? PropsData.productlist : [...this.state.productlist, ...PropsData.productlist],
          currencyRate: PropsData.currencyRate,
        },
        function () {
          this.displayProductList();
        }
      );
    }
    if (PropsData.taglist !== this.state.taglist) {
      this.setState({ taglist: PropsData.taglist }, function () {
        this.displayTag();
      });
    }
    if (this.state.globalSetting !== PropsData.globalSetting) {
      var globalSetting = PropsData.globalSetting;
      this.setState({
        globalSetting: PropsData.globalSetting,
        currencyRate: globalSetting?.setting_currency || {},
      });
    }
  }

  handlePriceChange = (start, end) => {
    this.setState({ startPrice: start, endPrice: end, page: 1 }, function () {
      this.applyFilter();
    });
  };

  calculatePrice() {
    try {
      var adults =
        this.state.searchdata.adults !== "" &&
        typeof this.state.searchdata.adults !== undefined &&
        typeof this.state.searchdata.adults !== "undefined"
          ? this.state.searchdata.adults
          : 1;
      var child =
        this.state.searchdata.child !== "" &&
        typeof this.state.searchdata.child !== undefined &&
        typeof this.state.searchdata.child !== "undefined"
          ? this.state.searchdata.child
          : 0;

      var totalActvitiesPrice = 0;
      var adultPrice = 0;
      var childPrice = 0;
      if (this.state.activities.length > 0) {
        this.state.activities.map((item) => {
          if (item.activities.length > 0) {
            item.activities.map((item1) => {
              if (item1 !== "") {
                if (item1.ticket_type.length > 0) {
                  item1.ticket_type.map((item2) => {
                    var currentQty = 0;
                    if (item2.package_price_name === "ADULT") {
                      currentQty = adults;
                      adultPrice =
                        parseFloat(adultPrice) +
                        parseFloat(item2.package_pricenettPrice) *
                          parseInt(currentQty);
                    }
                    if (item2.package_price_name === "CHILD") {
                      currentQty = child;
                      childPrice =
                        parseFloat(childPrice) +
                        parseFloat(item2.package_pricenettPrice) *
                          parseInt(currentQty);
                    }
                    if (currentQty > 0) {
                      totalActvitiesPrice =
                        parseFloat(totalActvitiesPrice) +
                        parseFloat(item2.package_pricenettPrice) *
                          parseInt(currentQty);
                    }
                  });
                }
              }
            });
          }
        });
      }

      this.setState({
        totalActvitiesPrice: totalActvitiesPrice.toFixed(2),
        adultsQty: adults,
        childQty: child,
        adultPrice: adultPrice.toFixed(2),
        childPrice: childPrice.toFixed(2),
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    if (value !== "") {
      axios
        .get(
          apiUrl +
            "products/search?keywords=" +
            value +
            "&country=" +
            this.state.country,
          {
            headers: {
              Authorization: cookie.load("acccesstoken"),
            },
          }
        )
        .then((res) => {
          if (res.data.status === "ok") {
            if (
              res?.data?.result_set?.country.length > 0 ||
              res?.data?.result_set?.product_list.length > 0
            ) {
              this.displaySearch(res?.data?.result_set, value);
            } else {
              this.setState({
                displaySearchCountry: "",
                displaySearchProducts: "",
                showsearchList: false,
                search_keyword: value,
              });
            }
          }
        });
    } else {
      this.setState({
        displaySearchCountry: "",
        displaySearchProducts: "",
        search_keyword: "",
        showsearchList: false,
      });
    }
    this.setState({ [name]: value, [name + "_error"]: "" });
  }
  displaySearch(result, searchTxt) {
    var displaySearchCountry = "";
    var displaySearchProducts = "";
    if (result?.country?.length > 0) {
      displaySearchCountry = result?.country.map((item, index) => {
        var countryName = item.replace(" ", "-").toLowerCase();
        return (
          <li key={index}>
            <Link to={"/activities/" + countryName}>
              <figure>
                {" "}
                <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
              </figure>
              <div className="search-show">
                <p>{item}</p>
              </div>
            </Link>
          </li>
        );
      });
    }
    if (result?.product_list.length > 0) {
      displaySearchProducts = result?.product_list?.map((item, index) => {
        return (
          <li
            key={index}
            onClick={this.selectProduct.bind(this, item)}
            id={"pro_" + item.products_id}
            className={
              item.product_total_package !== null &&
              parseInt(item.product_total_package) === 0
                ? "not-available"
                : ""
            }
          >
            <figure>
              {" "}
              <img
                src={
                  item.product_thumbnail
                    ? item.product_thumbnail.includes("https")
                      ? item.product_thumbnail
                      : apiglobaltixMediaUrl + item.product_thumbnail
                    : pplaceholder
                }
                alt={item.products_name}
              />
            </figure>
            <div className="search-show active">
              <div className="search-show-inner">
                <div className="search-show-product">
                  <p>{item.products_name}</p>
                  <span>{item.country_name}</span>
                </div>
                <div className="search-price">
                  <em>From</em>
                  <strong>
                    {" "}
                    {showPriceValue(
                      item.product_starting_price,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </strong>
                </div>
              </div>{" "}
            </div>
          </li>
        );
      });
    }
    this.setState({
      displaySearchCountry: displaySearchCountry,
      displaySearchProducts: displaySearchProducts,
      showsearchList: true,
      search_keyword: searchTxt,
    });
  }

  selectCreatorProduct() {
    var selectedPro = this.props.location;
    var selectedProductID = [];
    var selectedpackage = [];
    if (
      selectedPro.state !== "" &&
      typeof selectedPro.state !== undefined &&
      typeof selectedPro.state !== "undefined"
    ) {
      selectedProductID = selectedPro.state.selectedProduct;
      selectedpackage = selectedPro.state.slectedPackage;
      this.setState(
        {
          selectedProductID: selectedProductID,
          selectedpackage: selectedpackage,
        },
        function () {
          this.displayProductList();
        }
      );
    } else {
      this.setState({ initialPackageProduct: true });
    }
  }

  showMessage(response) {
    this.setState({ addfavloading: "" });
    if (response.status === "ok") {
      var result = response?.result_set;
      // if (result.addremovetype === "A") {
      //   $("#pro_" + result.product_id + " .like").addClass("active");
      // } else {
      //   $("#pro_" + result.product_id + " .like").removeClass("active");
      // }
    } else {
      if (response.form_error) {
        // showAlert("Error", response.form_error);
        this.setState({
          openAlertPopup: true,
          popupTitle: "Error",
          alertPopupMsg: response.form_error,
        });
      } else {
        // showAlert("Error", response.message);
        this.setState({
          openAlertPopup: true,
          popupTitle: "Error",
          alertPopupMsg: response.message,
        });
      }
      // $.magnificPopup.open({
      //   items: {
      //     src: ".alert_popup",
      //   },
      //   type: "inline",
      // });
    }
  }

  displayProductList() {
    var productList = "";
    var pagenation = "";
    var disablelast = false;
    var totalproductdisplay = 0;
    if (this.state.productlist.length > 0) {
      productList = this.state.productlist.map((item, index) => {
        totalproductdisplay++;
        return (
          <li
            key={index}
            id={"pro_" + item.products_id}
            className={
              item.product_total_package !== null &&
              parseInt(item.product_total_package) === 0
                ? "not-available"
                : ""
            }
            onClick={this.selectProduct.bind(this, item)}
          >
            {item.product_total_package !== null &&
              parseInt(item.product_total_package) === 0 && (
                <span className="no-package">Not Available</span>
              )}
            <div className="product-img">
              <img
                src={
                  item.product_thumbnail
                    ? item.product_thumbnail.includes("https")
                      ? item.product_thumbnail
                      : apiglobaltixMediaUrl + item.product_thumbnail
                    : pplaceholder
                }
                alt={item.products_name}
              />
            </div>
            <div className="product-description">
              <div className="product-description-title">
                <h5>{item.products_name}</h5>
              </div>

              {item.product_category_name !== "" &&
                item.product_category_name !== null && (
                  <div className="product-description-tag">
                    <span>{item.product_category_name}</span>
                  </div>
                )}
              <div className="product-description-review">
                <div className="pdr-lhs">
                  {item.distance !== "" && (
                    <div className="distance">
                      Distance from stay <div>{item.distance}</div>
                    </div>
                  )}
                </div>
                <div className="pdr-rhs">
                  <span>From</span>
                  <strong>
                    {showPriceValue(
                      item.product_payable_amount,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </strong>
                </div>
              </div>
            </div>
          </li>
        );
      });
      var start = 1;
      if (this.state.page - 2 > 0) {
        start = this.state.page - 2;
      }
      var max = Math.ceil(this.props.totalRecord / 12);
      if (max > 1) {
        var end = "";
        if (max === parseInt(this.state.page) + 1) {
          disablelast = true;
        }

        if (parseInt(start) + 3 > max) {
          end = max;
        } else {
          end = parseInt(start) + 3;
        }

        var pageList = [];
        for (let index = start; index <= end; index++) {
          pageList.push(index);
        }
        pagenation = pageList.map((item, index) => {
          return (
            <li
              key={index}
              className={this.state.page === item ? "active" : ""}
            >
              <a href={void 0} onClick={this.loadMoreProduct.bind(this, item)}>
                {item}
              </a>
            </li>
          );
        });
      }
    }
    var initialPackageProduct = this.state.initialPackageProduct;
    this.setState(
      {
        displayProducts: productList,
        pagenation: pagenation,
        disablelast: disablelast,
        initialPackageProduct: false,
      },
      function () {
        if (initialPackageProduct === true) {
          this.loaddayPackageProduct();
        }
        this.setState({ loading: false }, function () {
          // setTimeout(function () {
          //   if (totalproductdisplay > 0) {
          //     $("#no-product").hide();
          //   } else {
          //     $("#no-product").show();
          //   }
          // }, 2000);
        });
      }
    );
  }

  loadRatingstar(points) {
    if (
      points === "" ||
      points === null ||
      typeof points === undefined ||
      typeof points === "undefined"
    ) {
      points = "0.00";
    }
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        } else {
          return (
            <i className="fa fa-star-o" aria-hidden="true" key={index}></i>
          );
        }
      });
    }
  }
  loadMoreProduct(page) {
    if (this.state.page !== page) {
      this.setState({ page: page }, function () {
        var tag_filter = "";

        if (this.state.filterTag.length > 0) {
          tag_filter = this.state.filterTag.join(",").replace("&", "!!");
        }
        var Fcondition = "";
        if (this.state.selectedrating !== "") {
          Fcondition += "&rating=" + this.state.selectedrating;
        }
        if (this.state.startPrice !== "" && this.state.endPrice !== "") {
          Fcondition +=
            "&startPrice=" +
            this.state.startPrice +
            "&endPrice=" +
            this.state.endPrice;
        }
        if (this.state.pricesort !== "") {
          Fcondition += "&sort_price=" + this.state.pricesort;
        }
        var cityName = this.state.city || this.state.ttdCity;
        var params =
          "page=" +
          page +
          "&country=" +
          this.state.country +
          "&city=" +
          cityName;
        params += "&tag_filter=" + tag_filter + Fcondition;
        if (
          this.props.location.state.selectedHotel !== "" &&
          typeof this.props.location.state.selectedHotel !== undefined &&
          typeof this.props.location.state.selectedHotel !== "undefined"
        ) {
          var selectedHotel = this.props.location.state.selectedHotel;
          params += `&latitude=${selectedHotel.latitude}&longitude=${selectedHotel.longitude}`;
        }
        this.props.getProductList(params);
      });
    }
  }
  displayTag() {
    var displaytag = "";
    if (this.state.taglist.length > 0) {
      displaytag = this.state.taglist.map((item, index) => {
        return (
          <li
            key={index}
            className={
              [...this.state.filterTag, ...this.state.selectedTags].indexOf(
                item
              ) >= 0
                ? "active"
                : ""
            }
          >
            <a
              href={void 0}
              onClick={
                this.state.isMobile
                  ? this.handleSelectedTags.bind(this, item)
                  : this.filterByCategory.bind(this, item)
              }
            >
              {item}
            </a>
          </li>
        );
      });
    }
    this.setState({ displaytag: displaytag });
  }

  handleSelectedTags(tagID) {
    var selectedTags = this.state.selectedTags;
    if (selectedTags.indexOf(tagID) < 0) {
      selectedTags.push(tagID);
    } else {
      const index = selectedTags.indexOf(tagID);
      selectedTags.splice(index, 1);
    }
    this.setState({ selectedTags: selectedTags }, function () {
      this.displayTag();
    });
  }

  applyTagFilter() {
    this.setState({ filterTag: this.state.selectedTags }, function () {
      this.applyFilter();
    });
  }

  filterByCategory(tagID) {
    var filterTag = this.state.filterTag;
    if (filterTag.indexOf(tagID) < 0) {
      filterTag.push(tagID);
    } else {
      const index = filterTag.indexOf(tagID);
      filterTag.splice(index, 1);
    }
    this.setState({ filterTag: filterTag }, function () {
      this.applyFilter();
    });
  }

  filterByRating(rating) {
    var selectedrating = "";
    if (
      this.state.selectedrating !== "" &&
      this.state.selectedrating === rating
    ) {
      selectedrating = "";
    } else {
      selectedrating = rating;
    }
    this.setState({ selectedrating: selectedrating }, function () {
      this.applyFilter();
    });
  }
  applyFilterMobile() {
    this.setState({ showFilter: false }, function () {
      this.applyFilter();
    });
  }
  applyFilter() {
    var tag_filter = "";
    if (this.state.filterTag.length > 0) {
      tag_filter = this.state.filterTag.join(",").replace("&", "!!");
    }
    var Fcondition = "";
    if (this.state.selectedrating !== "") {
      Fcondition += "&rating=" + this.state.selectedrating;
    }
    if (this.state.startPrice !== "" && this.state.endPrice !== "") {
      Fcondition +=
        "&startPrice=" +
        this.state.startPrice +
        "&endPrice=" +
        this.state.endPrice;
    }
    if (this.state.pricesort !== "") {
      Fcondition += "&sort_price=" + this.state.pricesort;
    }

    if (
      this.state.search_keyword !== "" &&
      this.state.search_keyword !== undefined
    ) {
      Fcondition += "&search_keyword=" + this.state.search_keyword;
    }
    var cityName = this.state.city || this.state.ttdCity;
    var params =
      "page=1&country=" +
      this.state.country +
      "&city=" +
      cityName +
      "&tag_filter=" +
      tag_filter +
      Fcondition;
    if (
      this.props.location.state.selectedHotel !== "" &&
      typeof this.props.location.state.selectedHotel !== undefined &&
      typeof this.props.location.state.selectedHotel !== "undefined"
    ) {
      var selectedHotel = this.props.location.state.selectedHotel;
      params += `&latitude=${selectedHotel.latitude}&longitude=${selectedHotel.longitude}`;
    }

    this.setState({ loading: true });
    if (!this.props.drawerOpened && this.outletRef.current) {
      // $("html, body").animate(
      //   {
      //     scrollTop: $(".outlet-listing-rhs").offset().top - 200,
      //   },
      //   500
      // );
      window.scrollTo({
        top: this.outletRef.current.offsetTop - 200,
        behavior: "smooth",
      });
    }
    this.displayTag();
    this.props.getProductList(params);
  }
  resetFilter(filterType) {
    if (filterType === "tag") {
      this.setState({ filterTag: [] }, function () {
        this.applyFilter();
      });
    } else if (filterType === "rating") {
      this.setState({ selectedrating: "" }, function () {
        this.applyFilter();
      });
    }
    this.setState({ showFilter: false });
  }
  clearAllFilters() {
    this.setState(
      {
        filterTag: [],
        startPrice: 0,
        endPrice: 0,
        selectedrating: "",
        selectedTags: [],
      },
      () => {
        this.applyFilter();
      }
    );
    this.setState({ showFilter: false });
  }

  openSortBy() {
    this.setState({ enablesortby: !this.state.enablesortby });
  }
  applyPriceSort(type) {
    this.setState({ pricesort: type }, function () {
      this.applyFilter();
    });
  }
  applyPriceSortMobile(pricesort) {
    var type = "LH";
    if (pricesort === "LH") {
      type = "HL";
    }
    if (pricesort === "HL") {
      type = "LH";
    }
    this.setState({ pricesort: type }, function () {
      this.applyFilter();
    });
  }
  showFilter() {
    this.setState({ showFilter: true });
  }

  closeFilter() {
    this.setState({ showFilter: !this.state.showFilter });
  }

  searchProduct() {
    this.applyFilter();
    this.setState({
      displaySearchCountry: "",
      displaySearchProducts: "",
      showsearchList: false,
    });
  }

  selectProduct(item) {
    var bookingdetails = this.props.location.state;
    bookingdetails["activitiyIndex"] = this.state.activitiyIndex;
    bookingdetails["dayIndex"] = this.state.dayIndex;
    bookingdetails["selectedDay"] = this.state.selectedDay;
    bookingdetails["searchdata"].total_activities = this.state.total_activities;
    // bookingdetails["searchdata"].destinationCountry = this.state.ttdCity;
    if (this.props.drawerOpened) {
      this.props.history.push({
        state: bookingdetails,
      });
      this.setState({ selectedProd: item, viewActivity: true });
    } else {
      this.props.history.push({
        pathname:
          "/search-package/" +
          this.props.match.params.packageSlug +
          "/all-activities/singapore/" +
          item.product_slug,
        state: bookingdetails,
      });
    }
  }
  // removeActivity(activityIndex, dayIndex) {
  //   try {
  //     if (!this.state.ttdOnly) {
  //       // $.magnificPopup.open({
  //       //   items: {
  //       //     src: "#activity_remove_popup",
  //       //   },
  //       //   type: "inline",
  //       // });
  //       this.setState({ ActivityRemovePopup: true });
  //       var total_activities = this.state.total_activities;
  //       // var currentThis = this;
  //       // $.magnificPopup.instance.content
  //       //   .find(".confirm-button")
  //       //   .on("click", () => {
  //       //     this.setState({
  //       //       removeDayIndex: dayIndex,
  //       //       removeIndex: activityIndex,
  //       //       total_activities: total_activities - 1,
  //       //     });
  //       //     $.magnificPopup.instance.close();
  //       //     currentThis.continueactivity();
  //       //   });
  //       this.setState(
  //         {
  //           removeDayIndex: dayIndex,
  //           removeIndex: activityIndex,
  //           total_activities: total_activities - 1,
  //           openAlertPopup: false,
  //         },
  //         () => this.continueactivity()
  //       );
  //     } else {
  //       if (this.state.total_activities > 2) {
  //         // $.magnificPopup.open({
  //         //   items: {
  //         //     src: "#activity_remove_popup",
  //         //   },
  //         //   type: "inline",
  //         // });
  //         this.setState({ ActivityRemovePopup: true });
  //         var total_activities = this.state.total_activities;
  //         // var currentThis = this;
  //         // $.magnificPopup.instance.content
  //         //   .find(".confirm-button")
  //         //   .on("click", () => {
  //         //     this.setState({
  //         //       removeDayIndex: dayIndex,
  //         //       removeIndex: activityIndex,
  //         //       total_activities: total_activities - 1,
  //         //     });
  //         //     $.magnificPopup.instance.close();
  //         //     currentThis.continueactivity();
  //         //   });
  //         this.setState(
  //           {
  //             removeDayIndex: dayIndex,
  //             removeIndex: activityIndex,
  //             total_activities: total_activities - 1,
  //             openAlertPopup: false,
  //           },
  //           () => this.continueactivity()
  //         );
  //       } else {
  //         // $.magnificPopup.open({
  //         //   items: {
  //         //     src: "#remove_alert_popup",
  //         //   },
  //         //   type: "inline",
  //         // });
  //         this.setState({
  //           openAlertPopup: true,
  //           popupTitle: "Alert",
  //           alertPopupMsg:
  //             "You can't remove this activity, atleast two required to continue this package",
  //         });
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  continueactivity() {
    try {
      var activityIndex = this.state.removeIndex;
      var dayIndex = this.state.removeDayIndex;
      if (this.state.activities.length > 0) {
        var newActivity = [];
        this.state.activities.map((item, index) => {
          if (index === dayIndex) {
            var updatedActivity = [];
            if (item.activities.length > 0) {
              item.activities.map((actItem, actIndex) => {
                if (actIndex !== activityIndex) {
                  updatedActivity.push(actItem);
                }
              });
            }
            newActivity.push({
              day: item.day,
              emptyactivity: item.emptyactivity,
              activities: updatedActivity,
            });
          } else {
            newActivity.push(item);
          }
        });
        this.setState({ activities: newActivity }, function () {
          var bookingdetails = this.props.location.state;
          bookingdetails["searchdata"].total_activities =
            this.state.total_activities;
          bookingdetails["activities"] = this.state.activities;
          // $.magnificPopup.close();
          this.props.history.push({
            pathname: "/search-package/" + this.props.match.params.packageSlug,
            state: bookingdetails,
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  goback() {
    var bookingdetails = this.props.location.state;
    this.props.history.push({
      pathname: "/search-package/" + this.props.match.params.packageSlug,
      state: bookingdetails,
    });
  }
  toggleAccordion(event) {
    const accordionHeader = event.currentTarget;
    accordionHeader.classList.toggle("active");
    const accordionContent = accordionHeader.nextElementSibling;
    accordionContent.style.display =
      accordionContent.style.display === "none" ? "block" : "none";
  }
  handleDocumentClick = (event) => {
    if (
      this.state.showsearchList &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ showsearchList: false });
    }
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };
  render() {
    return (
      <>
        {!this.props.drawerOpened && (
          <Header {...this.props} sateValChange={this.sateValChange} />
        )}
        {this.props.drawerOpened ? (
          <ActivitySelection
            props={this.props}
            state={this.state}
            filterByCategory={this.filterByCategory.bind(this)}
            handleCloseSheet={this.props.handleCloseSheet}
            handleTextChange={this.handleChange.bind(this)}
            handlePriceChange={this.handlePriceChange}
            selectProduct={this.selectProduct.bind(this)}
            applyPriceSort={this.applyPriceSort.bind(this)}
            clearAllFilters={this.clearAllFilters.bind(this)}
            setState={this.setState.bind(this)}
            displaySearch={this.displaySearch.bind(this)}
            loadMoreProduct={this.loadMoreProduct.bind(this)}
          />
        ) : (
          <section className="innersection">
            <div className="container">
              {!this.state.ttdOnly && (
                <>
                  {" "}
                  <div className="detail-top-header pb30">
                    <h1>Change activities for the tripsss</h1>
                  </div>
                  <div className="package-details pb40">
                    <div className="package-details-lhs things-change-pack">
                      <div className="hotel-search-result">
                        <h5 className="color-header mb15">
                          Currently added things to do for{" "}
                          <span>day {this.state.dayIndex}</span>
                        </h5>
                        {this.state.activities.length > 0 &&
                          this.state.activities[this.state.dayIndex - 1] !==
                            "" &&
                          typeof this.state.activities[
                            this.state.dayIndex - 1
                          ] !== undefined &&
                          typeof this.state.activities[
                            this.state.dayIndex - 1
                          ] !== "undefined" && (
                            <ul>
                              {this.state.activities[this.state.dayIndex - 1]
                                ?.activities?.length > 0 &&
                                this.state.activities[
                                  this.state.dayIndex - 1
                                ].activities.map((item, index) => {
                                  if (
                                    this.state.removeIndex !== index &&
                                    item !== ""
                                  ) {
                                    return (
                                      <li
                                        key={index}
                                        className={
                                          item.activity_availablity === "No"
                                            ? "activity-not-available"
                                            : ""
                                        }
                                      >
                                        <div className="best-flag">
                                          Things to do
                                        </div>
                                        <div className="hsr-lhs">
                                          <div className="hsr-lhs-img">
                                            {item.activity_availablity ===
                                              "No" && (
                                              <div className="na-ribon">
                                                Not available
                                              </div>
                                            )}
                                            <img
                                              src={
                                                item.product_thumbnail
                                                  ? item.product_thumbnail.includes(
                                                      "https"
                                                    )
                                                    ? item.product_thumbnail
                                                    : apiglobaltixMediaUrl +
                                                      item.product_thumbnail
                                                  : pplaceholder
                                              }
                                              alt="airvayslogo"
                                            />
                                          </div>
                                          <div className="hsr-lhs-desc">
                                            <h5>
                                              {stripslashes(item.products_name)}
                                            </h5>
                                            <div className="star-rating-review">
                                              <div className="star-rating">
                                                {this.loadRatingstar(
                                                  item.product_rating
                                                )}
                                                {item.product_rating}
                                              </div>

                                              {item.product_rating !== "" &&
                                                item.product_rating !==
                                                  null && (
                                                  <div className="sr-review">
                                                    <strong>
                                                      {item.product_rating}
                                                    </strong>
                                                    <span>
                                                      {
                                                        item.product_total_review
                                                      }{" "}
                                                      Reviews
                                                    </span>
                                                  </div>
                                                )}
                                            </div>
                                            <span>
                                              {item.products_addressLine !==
                                                "" &&
                                                item.products_addressLine !==
                                                  null &&
                                                item.products_addressLine}
                                            </span>
                                            <div className="product-description-tag">
                                              <span>
                                                {" "}
                                                {item.product_category_name}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        {/* {(this.state.activitiyIndex === index ||
                                          this.state.ttdOnly) && (
                                          <div className="hsr-rhs">
                                            <a
                                              href={void 0}
                                              onClick={this.removeActivity.bind(
                                                this,
                                                index,
                                                this.state.dayIndex - 1
                                              )}
                                              className="button ghost-button remove-btn"
                                            >
                                              Remove
                                            </a>
                                          </div>
                                        )} */}
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          )}
                      </div>
                    </div>
                    {!this.state.isMobile && (
                      <div className="package-details-rhs">
                        <h5 className="color-header mb15 vb-hidden">
                          Currently added things to do for<span> </span>
                        </h5>
                        <div className="detail-bottom-rhs-price rhs-white-box">
                          <h5>Price details</h5>

                          <ul>
                            {parseInt(this.state.adultsQty) > 0 && (
                              <li>
                                <span className="service-content">
                                  <strong>Adult</strong>
                                </span>
                                <span className="service-amount">
                                  <strong>
                                    {showPriceValue(
                                      this.state.adultPrice,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}{" "}
                                    x {this.state.adultsQty}
                                  </strong>
                                </span>
                              </li>
                            )}
                            {parseInt(this.state.childQty) > 0 && (
                              <li>
                                <span className="service-content">
                                  <strong>Child</strong>
                                </span>
                                <span className="service-amount">
                                  <strong>
                                    {showPriceValue(
                                      this.state.childPrice,
                                      this.state.currentCurrency,
                                      this.state.currencyRate
                                    )}{" "}
                                    x {this.state.childQty}
                                  </strong>
                                </span>
                              </li>
                            )}
                            <li>
                              <span className="service-content">Total</span>
                              <span className="service-amount">
                                {showPriceValue(
                                  this.state.totalActvitiesPrice,
                                  this.state.currentCurrency,
                                  this.state.currencyRate
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="booknow-btm two-btn-merge">
                          <a
                            href={void 0}
                            onClick={this.goback.bind(this)}
                            className="button ghost-button"
                          >
                            Back
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div className="flight-way-search choose-transport things-do-list-search ">
                <div className="flight-search rel">
                  <div className="choose-loc">
                    {this.state.isMobile ? (
                      <input
                        type="text"
                        placeholder="Enter your activities"
                        readOnly
                        onClick={() =>
                          this.setState({ showSearchComponent: true })
                        }
                      />
                    ) : (
                      <input
                        type="text"
                        placeholder="Enter your activities"
                        value={this.state.search_keyword}
                        name="search_keyword"
                        onChange={this.handleChange.bind(this)}
                      />
                    )}
                  </div>
                  <button
                    type="submit"
                    className="button fg-btn"
                    onClick={this.searchProduct.bind(this)}
                  >
                    <img src={searchlight} />
                  </button>
                </div>
                {!this.state.isMobile &&
                  this.state.showsearchList &&
                  this.state.displaySearchProducts.length > 0 && (
                    <div
                      ref={this.dropdownRef}
                      className={
                        this.state.displaySearchProducts.length > 0
                          ? "search-place active"
                          : "search-place"
                      }
                    >
                      <ul>
                        {/*  {this.state.displaySearchCountry} */}
                        {this.state.displaySearchProducts}
                      </ul>
                    </div>
                  )}
              </div>
              <div className="outlet-listing-inner">
                <div className="outlet-listing-lhs" ref={this.outletRef}>
                  <h3>Filters</h3>
                  {this.state.displaytag !== "" && (
                    <div className="filter-option cat-option">
                      <h5
                        className="accordion-header"
                        onClick={this.toggleAccordion}
                      >
                        <img src={ticketblue} alt="ticket" />
                        Category
                      </h5>
                      <ul>{this.state.displaytag}</ul>

                      <a
                        href={void 0}
                        onClick={this.resetFilter.bind(this, "tag")}
                        className="button ghost-button"
                      >
                        Reset
                      </a>
                    </div>
                  )}
                  <div className="filter-option pricerange-option">
                    <h5
                      className="accordion-header"
                      onClick={this.toggleAccordion}
                    >
                      <img src={dollarblue} alt="dollar" />
                      Price range
                    </h5>
                    <p>
                      {showPriceValue(
                        Math.ceil(this.state.startPrice),
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}{" "}
                      to{" "}
                      {showPriceValue(
                        Math.ceil(this.state.endPrice),
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}
                      +
                    </p>
                    <div>
                      <input
                        type="text"
                        id="rang"
                        name="rang"
                        data-min="0"
                        data-max="1000"
                        data-from="0"
                        data-to="1000"
                        data-type="double"
                        data-prefix="SGD$"
                        data-grid="false"
                        data-grid-num="10"
                      />
                    </div>
                  </div>
                  {/* <div className="filter-option rating-option no-margin-btm">
                  <h5
                    className="accordion-header"
                    onClick={this.toggleAccordion}
                  >
                    <img src={stario} alt="" />
                    Rating
                  </h5>
                  <ul>
                    <li
                      className={
                        this.state.selectedrating === 5 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 5)}
                      >
                        5 Excellent (28)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === 4 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 4)}
                      >
                        4 Good (48)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === 3 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 3)}
                      >
                        3 Okay (79)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === 2 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 2)}
                      >
                        2 Poor (79)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === 1 ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, 1)}
                      >
                        1 Terrible (79)
                      </a>
                    </li>
                  </ul>

                  <a
                    href={void 0}
                    onClick={this.resetFilter.bind(this, "rating")}
                    className="button ghost-button"
                  >
                    Reset
                  </a>
                </div> */}
                  <div className="clearall">
                    <a href={void 0} onClick={this.clearAllFilters.bind(this)}>
                      Clear all
                    </a>
                  </div>
                </div>

                <div
                  className="outlet-listing-rhs things-do-list"
                  ref={this.outletRef}
                >
                  <div className="mobile-filter">
                    <div className="filter-group">
                      <a
                        href={void 0}
                        className={
                          this.state.pricesort === "HL"
                            ? "sorting active"
                            : "sorting"
                        }
                        onClick={this.applyPriceSortMobile.bind(
                          this,
                          this.state.pricesort
                        )}
                      >
                        <img src={sort} alt="sort" /> Sort
                      </a>
                      <a
                        href={void 0}
                        className="fitering"
                        onClick={() => this.setState({ openFilterSheet: true })}
                      >
                        <img src={filter} alt="filter" /> Filters
                      </a>
                    </div>
                  </div>
                  <div className="showing-header">
                    <h4>{this.props.totalRecord} things to do</h4>
                    <a href={void 0} onClick={this.openSortBy.bind(this)}>
                      Sort by <img src={barrow} alt="arrow" />
                    </a>

                    <ul
                      className={
                        this.state.enablesortby == true
                          ? "sort-by active"
                          : "sort-by"
                      }
                    >
                      <li
                        className={
                          this.state.pricesort === "LH" ? "active" : ""
                        }
                      >
                        <a
                          href={void 0}
                          onClick={this.applyPriceSort.bind(this, "LH")}
                        >
                          Price Low to High
                        </a>
                      </li>
                      <li
                        className={
                          this.state.pricesort === "HL" ? "active" : ""
                        }
                      >
                        <a
                          href={void 0}
                          onClick={this.applyPriceSort.bind(this, "HL")}
                        >
                          Price High to Low
                        </a>
                      </li>
                    </ul>
                  </div>
                  <ul>
                    {/* <li style={{ display: "none" }} id="no-product">
                    <div className="search-no-found">
                      <h3>No results found</h3>
                      <p>Try Again</p>
                    </div>
                  </li> */}
                    {this.state.loading == true &&
                      Array(1, 2, 3).map((item, index) => {
                        return (
                          <li key={index}>
                            <ContentLoader
                              viewBox="0 0 500 280"
                              height={280}
                              width={500}
                              speed={3}
                              key={item}
                            >
                              <rect
                                x="3"
                                y="3"
                                rx="10"
                                ry="10"
                                width="300"
                                height="180"
                              />
                              <rect
                                x="6"
                                y="190"
                                rx="0"
                                ry="0"
                                width="292"
                                height="20"
                              />
                              <rect
                                x="4"
                                y="215"
                                rx="0"
                                ry="0"
                                width="239"
                                height="20"
                              />
                              <rect
                                x="4"
                                y="242"
                                rx="0"
                                ry="0"
                                width="274"
                                height="20"
                              />
                            </ContentLoader>
                          </li>
                        );
                      })}
                    {this.state.loading == false && this.state.displayProducts}
                  </ul>
                  {this.state.pagenation !== "" && (
                    <div className="bottom-pagenation">
                      <ul className="pagenation">
                        <li className={this.state.page === 1 ? "disabled" : ""}>
                          <a href={void 0}>&#60;</a>
                        </li>
                        {this.state.pagenation}
                        <li
                          className={
                            this.state.disablelast === true ? "disabled" : ""
                          }
                        >
                          <a href={void 0}> &#62;</a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
        <ModalPopup
          open={this.state.ActivityRemovePopup}
          handleClose={() => this.setState({ ActivityRemovePopup: false })}
          classNames="common-popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <h2 className="text-uppercase">Warning</h2>
                  <p>Are you sure want to remove </p>
                  <div
                    className="alt_btns"
                    style={{ gap: "10px", display: "inline-flex" }}
                  >
                    <a
                      href={void 0}
                      className="button ghost-button confirm-button"
                    >
                      Yes
                    </a>
                    <a
                      href={void 0}
                      className="button popup-modal-dismiss disbl_href_action"
                    >
                      No
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalPopup>
        {/* <div id="remove_alert_popup" className="mfp-hide common-popup">
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_body">
                  <h2 className="text-uppercase">Alert</h2>
                  <p>
                    You can't remove this activity, atleast two required to
                    continue this package{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <AlertPopup
          open={this.state.openAlertPopup}
          handleClose={() => this.setState({ openAlertPopup: false })}
          title={this.state.popupTitle}
          message={this.state.alertPopupMsg}
        />
        {!this.props.drawerOpened && <Footer {...this.props} />}
        {this.state.isMobile && (
          <div className="priceDetail-bottomSheetMain">
            <div className="fareDetails">
              <div className="line"></div>
              <div className="row">
                <div className="col-1">
                  <div className="title">
                    Total things to do <span>fare</span>
                  </div>
                  <div className="price">
                    {showPriceValue(
                      this.state.totalActvitiesPrice,
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                  </div>
                  <div
                    className="breakdown"
                    onClick={() => this.setState({ showFareBreakdown: true })}
                  >
                    View fare breakdown
                  </div>
                </div>
                <div className="col-2">
                  <Button
                    className="action"
                    onClick={this.continueactivity.bind(this)}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        <BottomSheet
          open={this.state.showFareBreakdown}
          onDismiss={() => this.setState({ showFareBreakdown: false })}
          className="priceBreakdown__bottomSheetMain"
        >
          <div className="container detail-bottom-rhs-price">
            <ul>
              {parseInt(this.state.adultsQty) > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Adult</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.adultPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}{" "}
                      x {this.state.adultsQty}
                    </strong>
                  </span>
                </li>
              )}
              {parseInt(this.state.childQty) > 0 && (
                <li>
                  <span className="service-content">
                    <strong>Child</strong>
                  </span>
                  <span className="service-amount">
                    <strong>
                      {showPriceValue(
                        this.state.childPrice,
                        this.state.currentCurrency,
                        this.state.currencyRate
                      )}{" "}
                      x {this.state.childQty}
                    </strong>
                  </span>
                </li>
              )}
            </ul>
            <Divider />
            <div className="details">
              <div className="title">
                Total <span>fare</span>
              </div>
              <div className="col-2">
                <div className="price">
                  {showPriceValue(
                    this.state.totalActvitiesPrice,
                    this.state.currentCurrency,
                    this.state.currencyRate
                  )}
                </div>
              </div>
            </div>
            <div className="booknow-btm two-btn-merge">
              <a
                href={void 0}
                onClick={this.goback.bind(this)}
                className="button ghost-button"
              >
                Back
              </a>

              <a
                href={void 0}
                className="button check_room_avail"
                onClick={this.continueactivity.bind(this)}
              >
                Continue
              </a>
            </div>
          </div>
        </BottomSheet>
        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openFilterSheet: false })}
        >
          <FilterSheet
            currentThis={this}
            state={this.state}
            closeFilterSheet={() => this.setState({ openFilterSheet: false })}
          />
        </BottomSheet>
        <BottomSheet
          open={this.state.showSearchComponent}
          onDismiss={() => this.setState({ showSearchComponent: null })}
          className="searchContainer__bottomSheetMain"
        >
          <div className="searchContainer__bottomSheetMain__container searchContainer__bottomSheetMain__container__height">
            <input
              type="text"
              placeholder="Enter your activities"
              value={this.state.search_keyword}
              name="search_keyword"
              onChange={this.handleChange.bind(this)}
            />
            {this.state.showsearchList &&
              this.state.displaySearchProducts.length > 0 && (
                <div
                  className={
                    this.state.displaySearchProducts.length > 0
                      ? "search-place active"
                      : "search-place"
                  }
                >
                  <ul>
                    {/*  {this.state.displaySearchCountry} */}
                    {this.state.displaySearchProducts}
                  </ul>
                </div>
              )}
          </div>
        </BottomSheet>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var productlistArr = Array();
  var totalRecord = 0;
  var totalPages = 0;
  var common = Array();
  var productListStatus = "";
  var currencyRate = {};

  if (Object.keys(state.productlist).length > 0) {
    if (state.productlist[0].status === "ok") {
      productlistArr = state.productlist[0]?.result_set;
      totalRecord = state.productlist[0]?.total_records;
      totalPages = state?.productlist[0]?.total_page;
      common = state.productlist[0]?.common;
      currencyRate = state.productlist[0].currency;
      productListStatus = "success";
    } else {
      productListStatus = "error";
    }
  }

  var taglistArr = Array();
  if (Object.keys(state.taglist).length > 0) {
    if (state.taglist[0].status === "ok") {
      taglistArr = state.taglist[0]?.result_set;
    }
  }
  var countryArr = Array();
  if (Object.keys(state.country).length > 0) {
    if (state.country[0].status === "ok") {
      countryArr = state.country[0]?.result_set;
    }
  }
  var globalSetting = [];
  if (Object.keys(state?.settings).length > 0) {
    if (state?.settings[0].status === "ok") {
      globalSetting = state?.settings[0]?.result_set;
    }
  }
  return {
    productlist: productlistArr,
    productListStatus: productListStatus,
    totalRecord: totalRecord,
    totalPages: totalPages,
    currencyRate: currencyRate,
    common: common,
    taglist: taglistArr,
    globalSetting: globalSetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (params) => {
      dispatch({ type: GET_PRODUCTLIST, params });
    },
    getTagList: () => {
      dispatch({ type: GET_TAGLIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Index);
