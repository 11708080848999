/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import cookie from "react-cookies";
import { GET_CUSTOMER_LOGINDATA, SET_CUSTOMER_LOGINDATA } from "../actions";
import { userapiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetCustomerLoginData = function* () {
  yield takeEvery(GET_CUSTOMER_LOGINDATA, workerGetCustomerLoginData);
};

function* workerGetCustomerLoginData({ formPayload }) {
  try {
    const result = yield call(getCustomerLoginData, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CUSTOMER_LOGINDATA, value: resultArr });
  } catch {
    console.log("login failed");
  }
}

function getCustomerLoginData(formPayload) {
  return Axios.post(userapiUrl + "customer/login", formPayload, {
    headers: {
      Authorization: cookie.load("acccesstoken"),
    },
  });
}
