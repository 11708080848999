/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { GET_WISHLIST, GET_FAVOURITE } from "../../actions";
import { apiUrl } from "../Helpers/Config";
import heart from "../../common/images/heart.svg";
import heartfill from "../../common/images/heart-fill.svg";
import actinfo1 from "../../common/images/act-info1.jpg";
import medal from "../../common/images/medal.svg";
import flight from "../../common/images/flight-takeoff.svg";
import car from "../../common/images/car-icon.svg";
import hotel from "../../common/images/hotel-icon.svg";
import { showPriceValue } from "../Helpers/SettingHelper";
import avholder from "../../common/images/placeholder.jpg";
import axios from "axios";
import ModalPopup from "../../common/components/ModalPopup/ModalPopup";
import AlertPopup from "../../common/components/AlertPopup/AlertPopup";
var qs = require("qs");
class Wishlist extends Component {
  constructor(props) {
    super(props);
    /*     console.log(this.props, "props"); */
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    this.state = {
      UserId: UserId,
      wishlist: [],
      oldProductList: [],
      displayWishList: "",
      page: 1,
      redirected: true,
      country_List: {},
      openAlertPopup: false,
      alertPopupMsg: "",
      popupTitle: "",
    };
    this.removeFromWishlist = this.removeFromWishlist.bind(this);
    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    }
    /*  var params = "page=1";
    if (UserId !== "") {
      params += "&customer_id=" + UserId + "&wishlist=1";
    } */
    var params = "&customer_id=" + UserId;
    this.props.getWishList(params);
  }
  componentDidMount() {
    this.loadCity();
  }
  componentWillReceiveProps(PropsData) {
    if (PropsData.wishlist !== this.state.wishlist) {
      var oldProductList = this.state.oldProductList;
      var wishlist = PropsData.wishlist;

      if (oldProductList.length > 0) {
        oldProductList = oldProductList.concat(PropsData.wishlist);
        wishlist = oldProductList;
      }
      this.setState(
        { wishlist: wishlist, oldProductList: wishlist },
        function () {
          this.displayWishList();
        }
      );
    }
    if (this.state.addfavloading === "loading") {
      if (PropsData.favourite !== undefined) {
        this.showMessage(PropsData.favourite[0]);
      }
    }
  }

  showMessage(response) {
    this.setState({ addfavloading: "" });
    if (response.status === "ok") {
      var params = "page=1";
      if (this.state.UserId !== "") {
        params += "&customer_id=" + this.state.UserId + "&wishlist=1";
      }
      this.setState({ oldProductList: [] }, function () {
        this.props.getWishList(params);
      });
    } else {
      if (response.form_error) {
        // showAlert("Error", response.form_error);
        this.setState({
          openAlertPopup: true,
          popupTitle: "Error",
          alertPopupMsg: response.form_error,
        });
      } else {
        // showAlert("Error", response.message);
        this.setState({
          openAlertPopup: true,
          popupTitle: "Error",
          alertPopupMsg: response.message,
        });
      }
      // $.magnificPopup.open({
      //   items: {
      //     src: ".alert_popup",
      //   },
      //   type: "inline",
      // });
    }
  }

  displayWishList() {
    var wishList = "";
    var totalWishListDisplay = 0;
    if (this.state.wishlist.length > 0) {
      wishList = this.state.wishlist.map((item, index) => {
        /* var categories =
          item.categories !== "" && item.categories !== null
            ? item.categories.split(",")
            : []; */
        totalWishListDisplay++;
        return (
          <li key={index}>
            <div className="product-list-parent">
              <div className="product-img">
                <a
                  className="like"
                  onClick={() => this.removeFromWishlist(item)}
                >
                  {/*  {console.log(item, "item")} */}
                  {this.state.wishlist.some(
                    (item_wish) =>
                      item_wish.creator_package_id === item.creator_package_id
                  ) ? (
                    <img src={heartfill} alt="Filled Heart" />
                  ) : (
                    <img src={heart} alt="Empty Heart" />
                  )}
                </a>
                <a
                  className="pt-img"
                  href={void 0}
                  onClick={this.viewPackage.bind(this, item)}
                >
                  <img
                    src={
                      item.creator_package_thumbnail !== "" &&
                      item.creator_package_thumbnail !== null
                        ? item.creator_package_thumbnail
                        : actinfo1
                    }
                    alt=""
                  />
                </a>
                <div className="ptd-day">
                  {" "}
                  {item.creator_package_total_days} Days
                </div>
                <div className="new-tags">
                  <div className="c-tags">
                    {item.creator_package_travelling}
                  </div>
                  <div className="e-tags">{item.category_title}</div>
                </div>
              </div>
              <div className="product-description">
                <div className="product-description-title">
                  <h5>
                    <a
                      href={void 0}
                      onClick={this.viewPackage.bind(this, item)}
                    >
                      {item.creator_package_name}
                    </a>{" "}
                  </h5>
                </div>
                <div className="product-created-content">
                  <div className="created-av">
                    <img
                      src={
                        item.creator_image !== "" && item.creator_image !== null
                          ? item.creator_image
                          : avholder
                      }
                      alt={item.creator_image}
                    />
                  </div>
                  <div className="created-av-title">
                    <h4>
                      <span>Created by</span>

                      {item.creator_handle_name}
                    </h4>
                    <div className="creator-badge">
                      <span>
                        <img src={medal} alt="Medal" />
                      </span>{" "}
                      <strong>
                        Level <em>01</em>
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="sites-content">
                  <ul>
                    <li className="sc1">
                      <figure>
                        <img src={flight} alt="flight" />
                      </figure>
                      <figcaption>Flight (add on)</figcaption>
                    </li>
                    <li className="sc4">
                      <figure>
                        <img src={car} alt="car" />
                      </figure>
                      <figcaption>Transfer (add on)</figcaption>
                    </li>
                    {item.stay.length > 0 && (
                      <li className="sc2">
                        <figure>
                          <img src={hotel} alt="hotel" />
                        </figure>
                        <figcaption>
                          {item.stay.length} hotel options
                        </figcaption>
                      </li>
                    )}

                    {item.total_activities > 0 && (
                      <li className="sc3">
                        <figure>
                          <img src={ticketact} alt="ticket" />
                        </figure>
                        <figcaption>
                          {item.total_activities} Things to do
                        </figcaption>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="product-description-review">
                  <div className="pdr-lhs">
                    {item.creator_package_rating !== "" &&
                      item.creator_package_rating !== null && (
                        <span>
                          <strong>{item.creator_package_rating}</strong> (
                          {item.creator_package_total_rating}) Reviews
                        </span>
                      )}
                    <div className="star-rating">
                      {this.loadRatingstar(item.creator_package_rating)}
                    </div>
                  </div>
                  <div className="pdr-rhs">
                    <em>Starts from</em>
                    <strong>
                      {showPriceValue(item.creator_package_price)}
                      <span> Per Adult</span>
                    </strong>{" "}
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      });
    }

    this.setState(
      {
        displayWishList: wishList,
      },
      function () {
        this.setState({ loading: false }, function () {
          setTimeout(function () {
            if (totalWishListDisplay > 0) {
              $("#no-product").hide();
            } else {
              $("#no-product").show();
            }
          }, 2000);
        });
      }
    );
  }
  loadRatingstar(points) {
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        }
      });
    }
  }

  removeFromWishlist = (item) => {
    if (this.state.UserId !== "") {
      const formData = new FormData();
      formData.append("customer_id", cookie.load("UserId"));
      formData.append("package_id", item.creator_package_id);
      axios
        .post(apiUrl + "package/addToPackageWishlist", formData, {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        })
        .then((response) => {
          if (response.data.status === "ok") {
            this.setState(
              (prevState) => ({
                wishlist: prevState.wishlist.filter(
                  (wishlistItem) =>
                    wishlistItem.creator_package_id !== item.creator_package_id
                ),
              }),
              () => {
                this.displayWishList();
              }
            );
          }
        })
        .catch((error) => {
          /*        console.error(error); */
        });
    } else {
      // $.magnificPopup.open({
      //   items: {
      //     src: "#customer-login-popup",
      //   },
      //   type: "inline",
      // });
    }
  };

  viewPackage(item) {
    console.log(item, "item");
    var selected_City = "";
    Object.values(this.state.country_List).forEach((country) => {
      var countryDetails = Object.values(country);
      if (countryDetails[0] === item.creator_package_country) {
        countryDetails[1].map((city) => {
          if (city.city_name === item.creator_package_city) {
            selected_City = city;
          }
        });
      }
    });
    console.log(selected_City, "selected city");
    var startDate = new Date();
    var endDate = new Date(startDate);
    new Date(
      endDate.setDate(
        parseInt(endDate.getDate()) +
          (parseInt(item.creator_package_total_days) + 1)
      )
    );
    var searchdata = {
      adults: 1,
      airportLat: selected_City.airport_latitude,
      airportLon: selected_City.airport_longitude,
      checkinTime: item.hotel_check_in_time,
      child: "",
      destinationCity: selected_City.city_name,
      destinationCountry: selected_City.country_name,
      destinationLat: selected_City.airport_latitude,
      destinationLon: selected_City.airport_longitude,
      destinationWebbedsCity: selected_City.webbeds_city_code,
      destination_airport_address: selected_City.airport_address,
      destination_airport_code: selected_City.airport_code,
      destination_airport_id: selected_City.airport_id,
      destination_airport_lat: selected_City.airport_latitude,
      destination_airport_lon: selected_City.airport_longitude,
      end_date: endDate,
      images: item.creator_package_gallery,
      infant: "",
      location_destination: selected_City.airport_address,
      location_source:
        "Kempegowda International Airport Bengaluru (BLR), KIAL Rd, Devanahalli, Bengaluru, Karnataka 560300, India",
      room_count: 1,
      search_days: "2-5",
      sourceCity: "",
      sourceCountry: "India",
      sourceLat: "12.994112",
      sourceLon: "80.1708668",
      source_airport_address: "BLR",
      source_airport_code: "BLR",
      source_airport_id: "2800",
      source_airport_lat: "12.994112",
      source_airport_lon: "80.1708668",
      start_date: startDate,
      thumbnail: item.creator_package_thumbnail,
      transfer_trip_type: "Return",
      trip_type: "Return",
      total_activities: item.total_activities,
    };
    var postObject = {
      creator_id: item.creator_id,
      package_id: item.creator_package_id,
    };
    console.log(searchdata, "search data");
    axios
      .post(apiUrl + "package/packageClick", qs.stringify(postObject), {
        headers: {
          Authorization: cookie.load("acccesstoken"),
        },
      })
      .then((res) => {
        if (res.data.status === "ok") {
          var packagedetails = {
            searchdata: searchdata,
            hotel_check_in_time: item.hotel_check_in_time,
            creator_package_total_days: item.creator_package_total_days,
          };
          console.log(packagedetails, "packagedetails");
          this.props.history.push({
            pathname: "/search-package/" + item.creator_package_slug,
            state: packagedetails,
          });
        } else {
          console.log("ClickApi response", res.data);
        }
      });
  }
  loadCity = async () => {
    try {
      await axios
        .get(apiUrl + "settings/getCityList", {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        })
        .then((res) => {
          if (res.data.status === "ok") {
            this.setState({
              country_List: res.data.result_set,
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    this.setState({ [field]: value });
  };

  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <div className="bread-crumbs-other bread-crumbs">
          <div className="container">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>wishlishts</li>
            </ul>
          </div>
        </div>
        <section className="innersection">
          <div className="container">
            <div className="admin-profile">
              <Sidebar pageName="wishlist" />
              <div className="outlet-listing-rhs wishlisth-rsh">
                <div className="wishlisth-header">
                  <h5>
                    Your <span>Wishlist !</span>
                  </h5>
                </div>

                <ul className="wish-my-row">{this.state.displayWishList}</ul>

                {/* {this.props.total_page > 0 &&
                  this.props.total_page != this.state.page && (
                    <div className="wishlish-buttondiv">
                      <button
                        type="Explore More"
                        onClick={this.loadMoreProduct.bind(
                          this,
                          parseInt(this.state.page) + 1
                        )}
                        className="button"
                      >
                        Explore more
                      </button>
                    </div>
                  )} */}
              </div>
            </div>
          </div>
        </section>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var wishlistArr = Array();
  if (Object.keys(state.wishlist).length > 0) {
    if (state.wishlist[0].status === "ok") {
      wishlistArr = state.wishlist[0].packages;
    }
  }
  return {
    wishlist: wishlistArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWishList: (params) => {
      dispatch({ type: GET_WISHLIST, params });
    },
    getFavouritelist: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Wishlist);
