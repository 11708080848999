/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import ContentLoader from "react-content-loader";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { apiUrl } from "../Helpers/Config";
import {
  stripslashes,
  showPriceValue,
  loadRatingNumber,
  showAlert,
} from "../Helpers/SettingHelper";
import {
  heart,
  heartfill,
  bpro,
  bcal,
  searchlight,
  wifiicon,
  parkicon,
  baricon,
  discounticon,
  stickclose,
  dollarblue,
  stario,
  amenities,
  closeicon,
  staricon,
  halfstar,
} from "../Helpers/Images";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import StayFilterSheet from "./StayFilterSheet";
var qs = require("qs");
var base64 = require("base-64");
class Staylist extends Component {
  constructor(props) {
    super(props);

    var country =
      this.props.match.params.country !== ""
        ? this.props.match.params.country
        : "";
    var city =
      this.props.match.params.city !== "" ? this.props.match.params.city : "";

    var searchdata =
      this.props.location.state.searchdata !== "" &&
      this.props.location.state.searchdata !== undefined &&
      this.props.location.state.searchdata !== "undefined"
        ? this.props.location.state.searchdata
        : "";
    this.state = {
      country: country,
      city: city,
      searchdata: searchdata,
      searchResult: [],
      displayHotel: "",
      amenities_List: [],
      checkboxStates: {},
      loadingHotel: true,
      initialPackageStay: true,
      selectedHotelList: [],
      amenitiesFilter: [],
      displayTotalHotel: 0,
      showAmenitiesPopup: false,
      startPrice: "",
      endPrice: "",
      checkboxStates: {},
      selectedrating: "",
      filteredHotels: [],
      pricelist: [],
      minPrice: "",
      searchInput: "",
      initialHotels: [],
      suggestions: [],
      isMobile: window.innerWidth <= 480,
      showFiltersOption: false,
      showSearchComponent: false,
      isSuggestionsOpen: false,
      currentCurrency: cookie.load("currentCurrency") || "SGD",
      currencyRate: this.props.location.state?.currencyRate || {},
    };
    this.suggestionsRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
    if (this.state.searchResult.length > 0) {
      this.displayHotelList();
    } else {
      this.loadHotel();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  loadHotel() {
    console.log(this.state.searchdata, "searchdatasearchdata");
    var searchdatas = this.state.searchdata;
    var todayDate = new Date(searchdatas.start_date);
    var search_days = this.state.searchdata.search_days.split("-");

    console.log(todayDate, "todayDatetodayDate");
    var startDate =
      todayDate.getFullYear() +
      "-" +
      this.pad(parseInt(todayDate.getMonth() + 1)) +
      "-" +
      this.pad(todayDate.getDate());
    if (searchdatas.end_date !== "") {
      var NextDate = new Date(searchdatas.end_date);
    } else {
      var end_date = new Date(searchdatas.start_date);
      console.log(search_days[1], end_date.getDate(), " search_days[1]");
      var addedDay = end_date.setDate(
        parseInt(end_date.getDate()) + parseInt(search_days[1] - 1)
      );
      var NextDate = new Date(addedDay);
    }
    console.log(NextDate, "NextDate");
    var endDate =
      NextDate.getFullYear() +
      "-" +
      this.pad(parseInt(NextDate.getMonth() + 1)) +
      "-" +
      this.pad(NextDate.getDate());
    var passengers = [];

    passengers.push({ adult: searchdatas.adults, child: [searchdatas.child] });
    var postObject = {
      from_date: startDate,
      to_date: endDate,
      country: this.state.country,
      city: this.state.city,
      passenger_data: JSON.stringify(passengers),
      city_code: this.state.searchdata.destinationWebbedsCity,
    };

    axios
      .post(
        apiUrl + "hotelbooking/searchFilterHotel",
        qs.stringify(postObject),
        {
          headers: {
            Authorization: cookie.load("acccesstoken"),
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({ loadingHotel: false });
          if (
            res.data.result &&
            res.data.result.hotelInfo &&
            res.data.result.hotelInfo.length > 0
          ) {
            const pricelist = res.data.result.hotelInfo?.map(
              (item) => item.room_charge
            );
            const minValue = Math.min(...pricelist);
            const maxValue = Math.max(...pricelist);
            this.setState(
              {
                searchResult: res.data.result.hotelInfo,
                initialHotels: res.data.result.hotelInfo,
                pricelist: pricelist,
                startPrice: minValue,
                endPrice: maxValue,
              },
              function () {
                var currentThis = this;
                console.log(currentThis, "current this");
                // $(function () {
                //   $("#rang, #rangmobile").ionRangeSlider({
                //     min: currentThis.state.startPrice,
                //     max: currentThis.state.endPrice,
                //     from: currentThis.state.startPrice,
                //     to: currentThis.state.endPrice,
                //     type: "double",
                //     grid_num: 10,
                //     onFinish: function (data) {
                //       currentThis.setState({ loadingHotel: true });
                //       currentThis.setState(
                //         { startPrice: data.from, endPrice: data.to },
                //         function () {
                //           this.displayHotelList();
                //         }
                //       );
                //       // setTimeout(() => {
                //       //   currentThis.setState({ loadingHotel: false });
                //       // }, 2000);
                //     },
                //   });
                // });
                var amenitiesList = [];
                if (this.state.searchResult.length > 0) {
                  this.state.searchResult.map((item) => {
                    if (
                      item.hotel_amenities !== "" &&
                      item.hotel_amenities !== null
                    ) {
                      item.hotel_amenities.split("#@#").map((amini) => {
                        amenitiesList.push(amini);
                      });
                    }
                  });
                }
                console.log(this.state.searchResult, "searchresult");
                var amenities_List = [...new Set(amenitiesList)];

                this.setState({ amenities_List: amenities_List }, function () {
                  var checkboxStates = {};
                  amenities_List.forEach((amenity) => {
                    checkboxStates[amenity] = false;
                  });
                  // currentThis.setState({ loadingHotel: false });

                  this.setState({ checkboxStates }, function () {
                    this.displayHotelList();
                  });
                });
              }
            );
          } else {
            showAlert("Error", "Something went wrong, please try again later");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            console.error("Error fetching data:", error);
          }
        } else {
          this.setState({
            loadingHotel: false,
          });
        }
      })
      .catch((error) => {
        showAlert("Error", "Something went wrong, please try again later");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        console.error("Error fetching data:", error);
      });
  }
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  displayHotelList() {
    var displayHotel = "";
    var displayTotalHotel = 0;
    if (this.state.searchResult.length > 0) {
      var displayHotel = this.state.searchResult.map((item, index) => {
        var checkFilter = this.checkFilter(item);
        var hotel_amenities = item.hotel_amenities
          ? item.hotel_amenities.split("#@#")
          : [];
        if (checkFilter === true) {
          displayTotalHotel++;
          return (
            <li
              key={index}
              onClick={
                this.state.isMobile && this.viewHotel.bind(this, item.hotel_id)
              }
            >
              <div className="hsr-lhs">
                <div className="hsr-lhs-img">
                  {/*  <div className="offer-card">10% Off</div> */}
                  <img
                    src={
                      item.thumbnail_image !== "" &&
                      item.thumbnail_image !== null
                        ? item.thumbnail_image
                        : "https://fizota-ui-marketplace.s3.ap-southeast-1.amazonaws.com/no+hotel+image+found.jpg"
                    }
                    alt={item.thumbnail_image}
                  />
                </div>
                <div className="hsr-lhs-desc">
                  <div className="star-rating-review">
                    <div className="star-rating">
                      {this.loadRatingstar(item.rating)}
                    </div>
                    <div className="sr-review">
                      <strong> {loadRatingNumber(item.rating)}</strong>
                    </div>
                  </div>
                  <h4>
                    <a
                      href={void 0}
                      onClick={this.viewHotel.bind(this, item.hotel_id)}
                    >
                      {stripslashes(item.hotel_name)}
                    </a>
                  </h4>
                  <span>{stripslashes(item.address)}</span>
                  <ul>
                    {hotel_amenities
                      .map((amenity) => {
                        let icon = null;
                        let icon_limit = 0;
                        switch (amenity) {
                          case "High Speed Internet":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break;
                          case "Car Parking - Onsite Paid":
                          case "Car Parking - Onsite Free":
                            icon = <img src={parkicon} alt="wifi" />;
                            break;
                          case "Bar":
                            icon = <img src={baricon} alt="wifi" />;
                            break;
                          /* case "Business Centre":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break;
                          case "Elevators":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break;
                          case "Restaurant":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break;
                          case "Room Service - 24 Hours":
                            icon = <img src={wifiicon} alt="wifi" />;
                            break; */

                          default:
                            break;
                        }

                        return icon;
                      })
                      .filter((icon, index) => index < 8)
                      .map((icon, index) => icon && <li>{icon}</li>)}
                  </ul>
                  {/* <div className="free-box">
                    Free Cancellation till check-in
                  </div> */}
                </div>
                {/*  <a href={void 0} className="like">
                  {" "}
                  <img src={heart} alt="" /> <img src={heartfill} alt="" />
                </a> */}
              </div>
              <div className="hsr-rhs">
                {/* <figure>
                  <img src={discounticon} alt="" /> 30% offer
                </figure> */}
                <p>
                  {showPriceValue(
                    Math.round(item.room_charge),
                    this.state.currentCurrency,
                    this.state.currencyRate
                  )}{" "}
                  <span className="tl-span">total</span>
                </p>
                {!this.state.isMobile && (
                  <a
                    href={void 0}
                    onClick={this.viewHotel.bind(this, item.hotel_id)}
                    className="button"
                  >
                    View
                  </a>
                )}
              </div>
            </li>
          );
        }
      });
    }
    var initialPackageStay = this.state.initialPackageStay;
    this.setState(
      {
        displayHotel: displayHotel,
        // loadingHotel: false,
        initialPackageStay: false,
        displayTotalHotel: displayTotalHotel,
      },
      function () {
        if (initialPackageStay === true) {
          this.displayHotelList();
        }
      }
    );
  }

  showAmenitiesPopup = () => {
    this.setState({ showAmenitiesPopup: true });
  };

  checkFilter(item) {
    var amintiFli = true;
    if (this.state.amenitiesFilter.length > 0) {
      if (Object.values(this.state.checkboxStates).every((x) => x === false)) {
        return true;
      }
      const selectedAmenities = Object.keys(this.state.checkboxStates).filter(
        (key) => this.state.checkboxStates[key]
      );
      var amenitiesList = item.hotel_amenities.split("#@#");
      if (
        selectedAmenities.some((amenity) => amenitiesList.includes(amenity))
      ) {
        amintiFli = false;
      }
    }
    var hotelPrice = true;
    if (
      Math.round(this.state.startPrice) > 0 &&
      Math.round(item.room_charge) < Math.round(this.state.startPrice)
    ) {
      hotelPrice = false;
    }
    if (
      Math.round(this.state.endPrice) > 0 &&
      Math.round(item.room_charge) > Math.round(this.state.endPrice)
    ) {
      hotelPrice = false;
    }
    var rating = true;
    if (this.state.selectedrating.length > 0) {
      if (this.state.selectedrating.indexOf(item.rating) >= 0) {
        rating = true;
      } else {
        rating = false;
      }
    }
    setTimeout(() => {
      this.setState({ loadingHotel: false });
    }, 2000);
    if (amintiFli && hotelPrice && rating === true) {
      return true;
    } else {
      return false;
    }
  }
  filterByRating(rating) {
    this.setState({
      loadingHotel: true,
    });
    var selectedrating = "";
    if (
      this.state.selectedrating !== "" &&
      this.state.selectedrating === rating
    ) {
      selectedrating = "";
    } else {
      selectedrating = rating;
    }

    this.setState({ selectedrating: selectedrating }, function () {
      this.displayHotelList();
    });
    setTimeout(() => {
      this.setState({ loadingHotel: false });
    }, 2000);
  }
  resetFilter(filterType) {
    this.setState({
      loadingHotel: true,
    });

    if (filterType === "rating") {
      this.setState({ selectedrating: "" }, () => {
        this.displayHotelList();
      });
    } else if (filterType === "price") {
      this.setState({ startPrice: 0, endPrice: 0 }, () => {
        this.displayHotelList();
      });
    } else if (filterType === "amenities") {
      this.setState({ amenitiesFilter: [], checkboxStates: {} }, () => {
        this.displayHotelList();
      });
    }
    setTimeout(() => {
      this.setState({ loadingHotel: false });
    }, 2000);
  }
  clearAllFilters() {
    if (this.state.searchResult.length || this.state.initialHotels.length > 0) {
      const minPrice = Math.min(
        ...this.state.searchResult.map((item) => item.room_charge)
      );
      const maxPrice = Math.max(
        ...this.state.searchResult.map((item) => item.room_charge)
      );
      this.setState(
        {
          loadingHotel: true,
          selectedrating: "",
          startPrice: minPrice,
          endPrice: maxPrice,
          amenitiesFilter: [],
          checkboxStates: {},
        },
        () => {
          this.displayHotelList();
          // const sliderElement = $("#rang");
          // sliderElement.data("ionRangeSlider").update({
          //   from: minPrice,
          //   to: maxPrice,
          // });
        }
      );
    }
  }

  /*   goToStay(detailsLink) {
      this.props.history.push({
        pathname: detailsLink,
        state: { searchResult: this.state.searchResult },
      });
    } */
  viewHotel(hotel_id) {
    var hotel_ID = base64.encode(hotel_id);
    var packagedetails = this.props.location.state;
    this.props.history.push({
      pathname:
        "/search-package/" +
        this.props.match.params.packageSlug +
        "/stay/" +
        hotel_ID,
      state: packagedetails,
    });
  }

  /* loadRatingstar(points, type = "") {
    if (points !== "" && points !== null) {
      if (type === "hotel") {
        if (points === "Economy*") {
          points = "1.0";
        } else if (points === "Budget **") {
          points = "2.0";
        } else if (points === "Standard *") {
          points = "3.0";
        } else if (points === "Superior **") {
          points = "4.0";
        } else if (points === "Luxury ***") {
          points = "5.0";
        } else if (points === "Serviced Apartment") {
          points = "0.0";
        } else if (points === "Unrated") {
          points = "0.0";
        }
      }

      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                key={index}
                aria-hidden="true"
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" key={index} aria-hidden="true"></i>
            );
          }
        }
      });
    }
  } */
  loadRatingstar(points) {
    var ratingNumber = loadRatingNumber(points);
    var numStars = Math.floor(ratingNumber);
    var hasHalfStar = ratingNumber % 1 !== 0;
    var starImg = <img src={staricon} alt="star" />;
    var halfStarImg = <img src={halfstar} alt="halfstar" />;
    var fullStars = Array.from({ length: numStars }, () => starImg);
    // console.log(fullStars, "stars");
    var halfStar = hasHalfStar ? halfStarImg : null;
    return (
      <div>
        {fullStars}
        {halfStar}
      </div>
    );
  }

  handleApplyFilter(type, list) {
    if (type === "amenities") {
      const newCheckboxStates = { ...this.state.checkboxStates };
      for (const key in newCheckboxStates) {
        if (list?.includes(key)) {
          newCheckboxStates[key] = true;
        } else {
          newCheckboxStates[key] = false;
        }
      }
      this.setState(
        { amenitiesFilter: list, checkboxStates: newCheckboxStates },
        function () {
          this.displayHotelList();
        }
      );
    }
  }

  handleChange(type, event) {
    this.setState({ loadingHotel: true });
    // console.log(event.target.value, "event.target.value");
    if (type === "amenities") {
      const value = event.target.value;
      const checked = event.target.checked;
      const newCheckboxStates = { ...this.state.checkboxStates };
      newCheckboxStates[value] = checked;
      var amenitiesFilter = this.state.amenitiesFilter;
      if (amenitiesFilter.indexOf(event.target.value) >= 0) {
        amenitiesFilter.splice(event.target.value, 1);
      } else {
        amenitiesFilter.push(event.target.value);
      }
      setTimeout(() => {
        this.setState({ loadingHotel: true });
      }, 2000);
      this.setState(
        { amenitiesFilter: amenitiesFilter, checkboxStates: newCheckboxStates },
        function () {
          this.displayHotelList();
        }
      );
    }
    // console.log(amenitiesFilter, "temprorary filter");
  }
  handleSearchChange = (event) => {
    const searchInput = event.target.value;
    const { initialHotels } = this.state;
    const filteredSuggestions = initialHotels.filter((item) => {
      const hotelName = item.hotel_name.toLowerCase();
      const address = item.address.toLowerCase();
      const thumbnailImage = item.thumbnail_image;
      const room_charge = item.room_charge;
      const inputValue = searchInput.toLowerCase();
      return hotelName.includes(inputValue) || address.includes(inputValue);
    });
    this.setState(
      {
        searchInput,
        suggestions: filteredSuggestions,
        searchResult: this.state.initialHotels,
        isSuggestionsOpen: true,
      },
      this.displayHotelList
    );
  };
  handleSearchClick = (suggestion) => {
    var { searchInput } = this.state;
    if (searchInput !== "") {
      var filteredHotels = this.state.searchResult.filter((hotel) => {
        return (
          hotel.hotel_name.toLowerCase().includes(searchInput.toLowerCase()) ||
          hotel.address.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
      this.setState(
        { searchResult: filteredHotels, searchInput: suggestion.hotel_name },
        () => {
          this.displayHotelList();
        }
      );
    } else {
      this.setState(
        { searchResult: this.state.initialHotels, searchInput },
        () => {
          console.log(this.state.searchResult, "search Result");
          this.displayHotelList();
        }
      );
    }
    this.setState(
      {
        suggestions: [],
      },
      () => {
        console.log(this.state.searchResult, "search Result");
        this.displayHotelList();
      }
    );
  };

  toggleAccordion(event) {
    const accordionHeader = event.currentTarget;
    accordionHeader.classList.toggle("active");

    const accordionContent = accordionHeader.nextElementSibling;
    accordionContent.style.display =
      accordionContent.style.display === "none" ? "block" : "none";
  }
  handleDocumentClick = (event) => {
    if (
      this.state.isSuggestionsOpen &&
      this.suggestionsRef.current &&
      !this.suggestionsRef.current.contains(event.target)
    ) {
      this.setState({ isSuggestionsOpen: false });
    }
  };
  handleInputFocus = () => {
    this.setState({ isSuggestionsOpen: true });
  };
  sateValChange = (field, value) => {
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
    if (field === "currentCurrency") {
      this.setState({ currentCurrency: value });
    }
  };

  render() {
    return (
      <>
        <Header {...this.props} sateValChange={this.sateValChange} />
        <section className="innersection">
          <div className="container">
            <div className="color-header cheader-with-text">
              <h3>
                Choose <span>stay</span>
              </h3>
            </div>
            <div className="sticky-options">
              <div className="sticky-options-lhs">
                {this.state.selectedHotelList.length > 0 && (
                  <ul>
                    {this.state.selectedHotelList.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="sop-img">
                            <img
                              src={
                                item.thumbnail_image !== "" &&
                                item.thumbnail_image !== null
                                  ? item.thumbnail_image
                                  : "https://fizota-ui-marketplace.s3.ap-southeast-1.amazonaws.com/no+hotel+image+found.jpg"
                              }
                              alt={item.thumbnail_image}
                            />
                          </div>
                          <div className="sop-text">
                            <h5>
                              <a href={void 0}>{item.hotel_name}</a>
                            </h5>
                          </div>
                          <div className="remove-sticky">
                            {" "}
                            <img src={stickclose} />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            <div className="flight-way-search choose-stay ">
              <div className="flight-search rel">
                <div className="choose-loc">
                  {this.state.isMobile ? (
                    <input
                      type="text"
                      placeholder="Enter your hotelname or address of hotel"
                      readOnly
                      onClick={() =>
                        this.setState({ showSearchComponent: true })
                      }
                    />
                  ) : (
                    <input
                      type="text"
                      placeholder="Enter your hotelname or address of hotel"
                      value={this.state.searchInput}
                      onChange={this.handleSearchChange}
                      onFocus={this.handleInputFocus}
                    />
                  )}
                  {this.state.isSuggestionsOpen &&
                    this.state.suggestions.length > 0 &&
                    !this.state.isMobile &&
                    this.state.searchInput !== "" && (
                      <div ref={this.suggestionsRef}>
                        <ul className="suggestion-list active">
                          {this.state.suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => this.handleSearchClick(suggestion)}
                            >
                              <figure>
                                <img
                                  src={suggestion.thumbnail_image}
                                  alt="stay"
                                />{" "}
                              </figure>
                              <div className="search-show">
                                <div className="search-show-inner">
                                  <div className="search-show-product">
                                    <p>{suggestion.hotel_name}</p>
                                    <span>{suggestion.address} </span>
                                  </div>
                                  <div className="search-price">
                                    <strong>
                                 
                                 {showPriceValue(suggestion.room_charge,this.state.currentCurrency,
                                                                              this.state.currencyRate
                                                                            )}
                                                                              

                                                                              
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                </div>
                {/* <div className="fs-stay">
                  <input type="text" placeholder="Check-In" />
                  <img src={bcal} />
                </div>
                <div className="fs-stay">
                  <input type="text" placeholder="Check-out" />
                  <img src={bcal} />
                </div>
                <div className="fs-whom">
                  <input type="text" placeholder="Members" />
                  <img src={bpro} />
                </div> */}
                <button
                  type="button"
                  className="button fg-btn"
                  onClick={this.handleSearchClick}
                >
                  <img src={searchlight} />
                </button>
              </div>
            </div>

            {this.state.isMobile && (
              <div className="filters-main">
                <div className="col">
                  <div className="options">
                    <div
                      className={`button ${
                        this.state.amenitiesFilter?.length
                          ? "check_room_avail"
                          : "ghost-button"
                      }`}
                      onClick={() =>
                        this.setState({ showFiltersOption: "amenities" })
                      }
                    >
                      Amenities
                    </div>
                    <div
                      className={`button ghost-button`}
                      onClick={() =>
                        this.setState({ showFiltersOption: "pricerange" })
                      }
                    >
                      Price range
                    </div>
                    <div
                      className={`button ${
                        this.state.selectedrating !== ""
                          ? "check_room_avail"
                          : "ghost-button"
                      }`}
                      onClick={() =>
                        this.setState({ showFiltersOption: "rating" })
                      }
                    >
                      Rating
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="outlet-listing-inner">
              <div className="outlet-listing-lhs">
                <h4>Filters</h4>
                {this.state.amenities_List.length > 0 && (
                  <div className="filter-option flight-option">
                    <h5
                      className="accordion-header"
                      onClick={this.toggleAccordion}
                    >
                      <img src={amenities} alt="amenities" />
                      Amenities
                    </h5>
                    <ul>
                      {this.state.amenities_List
                        .slice(0, 5)
                        .map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                className="tag_filter"
                                value={item}
                                checked={this.state.checkboxStates[item]}
                                onChange={this.handleChange.bind(
                                  this,
                                  "amenities"
                                )}
                              />
                              <label>{item}</label>
                            </li>
                          );
                        })}
                      {this.state.amenities_List.length > 10 && (
                        <li>
                          <div className="clearall">
                            <a href={void 0} onClick={this.showAmenitiesPopup}>
                              See More
                            </a>
                          </div>
                        </li>
                      )}
                    </ul>
                    <a
                      href={void 0}
                      onClick={this.resetFilter.bind(this, "amenities")}
                      className="button ghost-button"
                    >
                      Reset
                    </a>
                  </div>
                )}

                <div className="filter-option pricerange-option">
                  <h5
                    className="accordion-header"
                    onClick={this.toggleAccordion}
                  >
                    <img src={dollarblue} alt="dollar" />
                    Price range
                  </h5>
                  <p>
                    {showPriceValue(
                      Math.ceil(this.state.startPrice),
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}{" "}
                    to{" "}
                    {showPriceValue(
                      Math.ceil(this.state.endPrice),
                      this.state.currentCurrency,
                      this.state.currencyRate
                    )}
                    +
                  </p>
                  <div>
                    <input
                      type="text"
                      id="rang"
                      name="rang"
                      value=""
                      data-min={Math.round(this.state.startPrice)}
                      data-max={Math.round(this.state.endPrice)}
                      data-from={this.state.startPrice}
                      data-to={this.state.endPrice}
                      data-type="double"
                      data-prefix="SGD$"
                      data-grid="false"
                      data-grid-num="10"
                    />
                    <a
                      href={void 0}
                      onClick={this.resetFilter.bind(this, "price")}
                      className="button ghost-button"
                    >
                      Reset
                    </a>
                  </div>
                </div>
                <div className="filter-option rating-option no-margin-btm">
                  <h5
                    className="accordion-header"
                    onClick={this.toggleAccordion}
                  >
                    <img src={stario} alt="star" />
                    Rating
                  </h5>
                  <ul>
                    <li
                      className={
                        this.state.selectedrating === "Luxury *****"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, "Luxury *****")}
                      >
                        5 Excellent (28)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === "Superior ****"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(
                          this,
                          "Superior ****"
                        )}
                      >
                        4 Good (48)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === "Standard ***"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, "Standard ***")}
                      >
                        3 Okay (79)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === "Budget **"
                          ? "active"
                          : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, "Budget **")}
                      >
                        2 Poor (79)
                      </a>
                    </li>
                    <li
                      className={
                        this.state.selectedrating === "Economy*" ? "active" : ""
                      }
                    >
                      <a
                        href={void 0}
                        onClick={this.filterByRating.bind(this, "Economy*")}
                      >
                        1 Terrible (79)
                      </a>
                    </li>
                  </ul>

                  <a
                    href={void 0}
                    onClick={this.resetFilter.bind(this, "rating")}
                    className="button ghost-button"
                  >
                    Reset
                  </a>
                </div>

                <div className="clearall">
                  <a href={void 0} onClick={this.clearAllFilters.bind(this)}>
                    Clear all
                  </a>
                </div>
              </div>
              {this.state.showAmenitiesPopup && (
                <div className="popup-ame">
                  <div className="popup-inner-ame">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <h5>Amenities</h5>
                      <a
                        href={void 0}
                        onClick={() =>
                          this.setState({ showAmenitiesPopup: false })
                        }
                      >
                        <img src={closeicon} alt="close" />
                      </a>
                    </div>
                    <ul>
                      {this.state.amenities_List.map((item, index) => {
                        return (
                          <li key={index}>
                            <input
                              type="checkbox"
                              className="tag_filter"
                              value={item}
                              checked={this.state.checkboxStates[item]}
                              onChange={this.handleChange.bind(
                                this,
                                "amenities"
                              )}
                            />
                            <label>{item}</label>
                          </li>
                        );
                      })}
                    </ul>
                    <div>
                      <button
                        onClick={() =>
                          this.setState({ showAmenitiesPopup: false })
                        }
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
              <div className="outlet-listing-rhs flight-search-parent">
                <div className="search-number">
                  <span>Search results</span>
                  {this.state.loadingHotel === false && (
                    <span>
                      {this.state.displayTotalHotel} of{" "}
                      {this.state.searchResult.length} hotels
                    </span>
                  )}
                </div>
                <div className="hotel-search-result">
                  <ul>
                    {this.state.loadingHotel === true ? (
                      Array(1, 2, 3).map((item) => {
                        return (
                          <li key={item}>
                            <ContentLoader
                              height={140}
                              speed={1}
                              viewBox="0 0 380 70"
                            >
                              <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="70"
                                height="70"
                              />
                              <rect
                                x="80"
                                y="17"
                                rx="4"
                                ry="4"
                                width="300"
                                height="13"
                              />
                              <rect
                                x="80"
                                y="40"
                                rx="3"
                                ry="3"
                                width="250"
                                height="10"
                              />
                            </ContentLoader>
                          </li>
                        );
                      })
                    ) : this.state.displayHotel.length > 0 ? (
                      this.state.displayHotel
                    ) : (
                      <p>No hotels found</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer {...this.props} />
        <BottomSheet
          open={this.state.showFiltersOption}
          onDismiss={() => this.setState({ showFiltersOption: null })}
          className="filters-main__bottomSheetMain"
        >
          <StayFilterSheet
            currentThis={this}
            filterByRating={(val) => this.filterByRating(val)}
            handleApplyFilter={(type, list) =>
              this.handleApplyFilter(type, list)
            }
            resetFilter={(type) => this.resetFilter(type)}
            amenities_List={this.state.amenities_List}
            amenitiesFilter={this.state.amenitiesFilter}
            showFiltersOption={this.state.showFiltersOption}
            closeFiltersOption={() =>
              this.setState({ showFiltersOption: null })
            }
            state={this.state}
          />
        </BottomSheet>
        <BottomSheet
          open={this.state.showSearchComponent}
          onDismiss={() => this.setState({ showSearchComponent: null })}
          className="searchContainer__bottomSheetMain"
        >
          <div className="searchContainer__bottomSheetMain__container searchContainer__bottomSheetMain__container__height">
            <input
              type="text"
              placeholder="Enter your hotelname or address of hotel"
              value={this.state.searchInput}
              onChange={this.handleSearchChange}
            />
            {this.state.suggestions.length > 0 &&
              this.state.searchInput !== "" && (
                <ul className="suggestion-list active">
                  {this.state.suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      onClick={() => this.handleSearchClick(suggestion)}
                    >
                      <figure>
                        <img src={suggestion.thumbnail_image} alt="stay" />{" "}
                      </figure>
                      <div className="search-show">
                        <div className="search-show-inner">
                          <div className="search-show-product">
                            <p>{suggestion.hotel_name}</p>
                            <span>{suggestion.address} </span>
                          </div>
                          <div className="search-price">
                            <strong>{showPriceValue(
                    Math.round(suggestion.room_charge),
                    this.state.currentCurrency,
                    this.state.currencyRate
                  )} </strong>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
          </div>
        </BottomSheet>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Staylist);
